import type { RouteRecordRaw } from 'vue-router';
import { adminEnumRoutes } from './admin.enum.routes';

const announcementsRoute: Array<RouteRecordRaw> = [
  {
    path: adminEnumRoutes.announcements.path,
    children: [
      {
        path: '',
        name: adminEnumRoutes.announcements.name,
        meta: adminEnumRoutes.announcements.meta,
        component: () =>
          import('@/modules/admin/pages/announcement/AnnouncementsPage.vue'),
      },

      {
        path: adminEnumRoutes.announcementDetails.path,
        name: adminEnumRoutes.announcementDetails.name,
        meta: adminEnumRoutes.announcementDetails.meta,
        component: () =>
          import('@/modules/admin/pages/announcement/AnnouncementDetails.vue'),
      },
      {
        path: adminEnumRoutes.createAnnouncement.path,
        name: adminEnumRoutes.createAnnouncement.name,
        meta: adminEnumRoutes.createAnnouncement.meta,
        component: () =>
          import('@/modules/admin/pages/announcement/CreateAnnouncement.vue'),
      },
    ],
  },
];
export default announcementsRoute;
