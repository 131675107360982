import type { i18n } from 'i18next';
import { configureStores } from '@/plugins/i18n/config.i18n.ts';
import {
  getInitialLanguage,
  initializeI18n,
} from '@/plugins/i18n/init.i18n.ts';

const i18nInstance = async (): Promise<i18n> => {
  try {
    const initialLanguage = getInitialLanguage();
    const i18nInstance = await initializeI18n(initialLanguage);
    await configureStores(i18nInstance);
    return i18nInstance;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export default i18nInstance;
