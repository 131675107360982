export const socialGroupsApiHandler = {
  members: {
    getAllSocialGroupMembers: {
      method: "GET",
      path: (payload: { groupId: string | number }) => `org-social-groups-management/${payload.groupId}/members`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
    },
    editSocialGroupMembers: {
      method: "PATCH",
      path: (payload: { groupId: string | number }) => `org-social-groups-management/${payload.groupId}/members`
    }
  },
  createCoGroup: {
    method: "POST",
    path: () => `org-social-groups-management`
  },
  updateCoGroup: {
    method: "PATCH",
    path: (payload: { groupId: string | number }) => `org-social-groups-management/${payload.groupId}`
  },
  getAllCoGroups: {
    method: "GET",
    path: () => `org-social-groups-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
  },
  getSingleCoGroup: {
    method: "GET",
    path: (payload: { groupId: string | number }) => `org-social-groups-management/${payload.groupId}`
  },
  deleteCoGroup: {
    method: "DELETE",
    path: (payload: { groupId: string | number }) => `org-social-groups-management/${payload.groupId}`
  }
};