import { type IRoute } from '@/helpers/interfaces/auth/routes.enum.interface.ts';

export const coachEnumRoutes: IRoute = {
  root: {
    path: '/coach',
    name: 'Coach',
    meta: {
      title: 'coach.coachTabTitle',
      requiresAuth: true,
      isCoach: true,
    },
  },
};
