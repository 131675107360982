import type { RouteRecordRaw } from 'vue-router';
import { profileEnumRoutes } from '@/routes/profile/profile.enum.routes';

const profileRoutes: RouteRecordRaw[] = [
  {
    path: profileEnumRoutes.root.path,
    name: profileEnumRoutes.root.name,
    component: () => import('@/layouts/profile/BaseProfileLayout.vue'),
    redirect: profileEnumRoutes.profileIndex.path,
    meta: profileEnumRoutes.root.meta,
    children: [
      {
        path: profileEnumRoutes.profileIndex.path,
        name: profileEnumRoutes.profileIndex.name,
        component: () => import('@/modules/profile/pages/ProfilePage.vue'),
        meta: {
          title: profileEnumRoutes.profileIndex.meta.title,
        },
      },
      {
        path: profileEnumRoutes.notification.path,
        name: profileEnumRoutes.notification.name,
        component: () => import('@/modules/profile/pages/NotificationPage.vue'),
        meta: {
          title: profileEnumRoutes.notification.meta.title,
        },
      },
      {
        path: profileEnumRoutes.zoomPolicy.path,
        name: profileEnumRoutes.zoomPolicy.name,
        component: () => import('@/components/base/zoom/ZoomPolicy.vue'),
        meta: {
          meta: profileEnumRoutes.zoomPolicy.meta,
        },
      },
    ],
  },
];
export default profileRoutes;
