export const articlesApiHandler = {
  getArticles: {
    method: "GET",
    path: () => `articles`,
    params: {} as {'search'?: string | number, 'tags'?: (string | number)[], 'skip'?: string | number, 'take'?: string | number}
  },
  getSingleArticle: {
    method: "GET",
    path: (payload: { articleId: string | number }) => `articles/${payload.articleId}`
  }
};