export const supportApiHandler = {
  contact: {
    contactOrganization: {
      method: "POST",
      path: (payload: { organizationId: string | number }) => `contact/organization/${payload.organizationId}`
    }
  },
  teamManagement: {
    addSupportTeamMember: {
      method: "POST",
      path: () => `org-support-team-management`
    },
    getSupportTeamMembers: {
      method: "GET",
      path: () => `org-support-team-management`
    },
    getSingleSupportTeamMember: {
      method: "GET",
      path: (payload: { supportTeamMemberId: string | number }) => `org-support-team-management/${payload.supportTeamMemberId}`
    },
    deleteSupportTeamMember: {
      method: "DELETE",
      path: (payload: { supportTeamMemberId: string | number }) => `org-support-team-management/${payload.supportTeamMemberId}`
    },
    updateSupportTeamMember: {
      method: "PATCH",
      path: (payload: { supportTeamMemberId: string | number }) => `org-support-team-management/${payload.supportTeamMemberId}`
    }
  }
};