import type { RouteRecordRaw } from 'vue-router';
import { adminEnumRoutes } from './admin.enum.routes';

const eventsRoutes: Array<RouteRecordRaw> = [
  {
    path: adminEnumRoutes.events.path,
    children: [
      {
        path: '',
        name: adminEnumRoutes.events.name,
        meta: adminEnumRoutes.events.meta,
        component: () => import('@/modules/admin/pages/events/EventsPage.vue'),
      },
      {
        path: adminEnumRoutes.eventView.path,
        name: adminEnumRoutes.eventView.name,
        meta: adminEnumRoutes.eventView.meta,
        component: () =>
          import('@/modules/admin/pages/events/BaseEventLayout.vue'),
        children: [
          {
            path: adminEnumRoutes.eventDetails.path,
            name: adminEnumRoutes.eventDetails.name,
            meta: adminEnumRoutes.eventDetails.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventDetail.vue'),
          },
          {
            path: adminEnumRoutes.createEvent.path,
            name: adminEnumRoutes.createEvent.name,
            meta: adminEnumRoutes.createEvent.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventDetail.vue'),
          },
          {
            path: adminEnumRoutes.eventHosts.path,
            name: adminEnumRoutes.eventHosts.name,
            meta: adminEnumRoutes.eventHosts.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventHosts.vue'),
          },
          {
            path: adminEnumRoutes.eventGuests.path,
            name: adminEnumRoutes.eventGuests.name,
            meta: adminEnumRoutes.eventGuests.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventGuests.vue'),
          },
          {
            path: adminEnumRoutes.eventEnrollees.path,
            name: adminEnumRoutes.eventEnrollees.name,
            meta: adminEnumRoutes.eventEnrollees.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventEnrollees.vue'),
          },
          {
            path: adminEnumRoutes.eventAttendance.path,
            name: adminEnumRoutes.eventAttendance.name,
            meta: adminEnumRoutes.eventAttendance.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventAttendance.vue'),
          },
          {
            path: adminEnumRoutes.eventAttendanceItem.path,
            name: adminEnumRoutes.eventAttendanceItem.name,
            meta: adminEnumRoutes.eventAttendanceItem.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventAttendanceItem.vue'),
          },
          {
            path: adminEnumRoutes.eventQrCode.path,
            name: adminEnumRoutes.eventQrCode.name,
            meta: adminEnumRoutes.eventQrCode.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventQrCode.vue'),
          },
          {
            path: adminEnumRoutes.eventTicket.path,
            name: adminEnumRoutes.eventTicket.name,
            meta: adminEnumRoutes.eventTicket.meta,
            component: () =>
              import('@/modules/admin/pages/events/EventTicket.vue'),
          },
        ],
      },
    ],
  },
];
export default eventsRoutes;
