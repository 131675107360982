import { useEnvComposable, useMetaComposable } from '@/composables';
import {
  EnumOrganizationMemberPermissions,
  EnumOrganizationPermissions,
} from '@/helpers/enums/permission.enum';
// import { authEnumRoutes } from '@/routes/auth/auth.enum.routes';
import coachRoutes from '@/routes/coach/coach.route';
import { useAuthStore } from '@/stores/auth/auth.pinia';
import { usePermissionStore } from '@/stores/shared/permission.pinia';
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordName,
  RouteRecordRaw,
} from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import adminRoutes from './admin/admin.route.ts';
import { authEnumRoutes } from './auth/auth.enum.routes';
import authRoutes from './auth/auth.route.ts';
import instructorRoutes from './instructor/instructor.route.ts';
import profileRoutes from './profile/profile.route.ts';
import sharedRoutes from './shared/shared.routes.ts';
import superAdminRoute from './super-admin/super-admin.route.ts';
import { pinia } from '@/plugins';
declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
    requiresAuth?: boolean;
    memberPermissions?: Array<EnumOrganizationMemberPermissions>;
    shouldHaveMarketPlaceEnabled?: boolean;
    shouldHaveRegistrationApproval?: boolean;
    shouldHaveSelfRegistration?: boolean;
    shouldHaveCertificateConfig?: boolean;
    organizationPermissions?: Array<EnumOrganizationPermissions>;
    requiresLogout?: boolean;
    isInstructor?: boolean;
    isCoach?: boolean;
    isAdministrator?: boolean;
    icon?: string;
    allowHeader?: boolean;
    // Add other properties as needed...
  }
}

const routes: RouteRecordRaw[] = [
  ...authRoutes,
  ...adminRoutes,
  ...profileRoutes,
  ...instructorRoutes,
  ...coachRoutes,
  ...superAdminRoute,
  ...sharedRoutes,
].flat(3);

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_API_BASE_URL),
  routes: routes,
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.query?.super_token) {
      await useAuthStore(pinia).loginSSO(`${to.query.super_token}`);
      next({
        ...to,
        query: {
          ...to.query,
          super_token: undefined,
          lang: undefined,
        },
      });
    }
    let name:
      | { name: RouteRecordName | undefined }
      | null
      | undefined
      | boolean = null;

    if (!usePermissionStore().systemPermissions.length)
      await useAuthStore().initializeAuth(true);

    if (from.name != authEnumRoutes.twoFactorVerification.name) {
      name = await usePermissionStore().checkAuthentication(to, from, true);
    }
    if (name) next(name);
    else next();
  }
);

router.afterEach((to) => {
  const { useTitle } = useMetaComposable();
  const { projectName } = useEnvComposable();
  const title: string = (to?.meta.title as string) || projectName;
  useTitle(title, false);
});

export default router;
