export enum AuthErrorsEnum {
  USER_IS_DISABLED = 'auth-001',
  USER_NOT_IN_ORGANIZATION = 'auth-002',
  INVALID_CREDENTIALS = 'auth-003',
  TOO_MANY_REQUESTS = 'auth-004',
  USER_SHOULD_RESET_PASSWORD = 'auth-005',
  INVALID_ACCESS_TOKEN = 'auth-006',
  INVALID_REFRESH_TOKEN = 'auth-007',
  INVALID_TWO_FACTOR_TOKEN = 'auth-008',
  REQUIRES_APPROVAL = 'auth-009',
  NOT_VERIFIED = 'auth-010',
}
