export const tagsApiHandler = {
  getTags: {
    method: "GET",
    path: () => `org-tags-management`
  },
  addTag: {
    method: "POST",
    path: () => `org-tags-management`
  },
  deleteTag: {
    method: "DELETE",
    path: (payload: { tagId: string | number }) => `org-tags-management/${payload.tagId}`
  }
};