export const specializationApiHandler = {
  courses: {
    getSpecializationCourses: {
      method: "GET",
      path: (payload: { specializationId: string | number }) => `org-specializations-management/${payload.specializationId}/courses`
    },
    updateSpecializationCourses: {
      method: "PATCH",
      path: (payload: { specializationId: string | number }) => `org-specializations-management/${payload.specializationId}/courses`
    },
    updateSpecializationCoursesOrder: {
      method: "PATCH",
      path: (payload: { specializationId: string | number }) => `org-specializations-management/${payload.specializationId}/courses/order`
    }
  },
  createOrgSpecialization: {
    method: "POST",
    path: () => `org-specializations-management`
  },
  getAllOrgSpecializations: {
    method: "GET",
    path: () => `org-specializations-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number}
  },
  getSingleOrgSpecialization: {
    method: "GET",
    path: (payload: { specializationId: string | number }) => `org-specializations-management/${payload.specializationId}`
  },
  updateOrgSpecialization: {
    method: "PATCH",
    path: (payload: { specializationId: string | number }) => `org-specializations-management/${payload.specializationId}`
  },
  deleteOrgSpecialization: {
    method: "DELETE",
    path: (payload: { specializationId: string | number }) => `org-specializations-management/${payload.specializationId}`
  }
};