export const coachingApiHandler = {
  coaches: {
    getCoaches: {
      method: "GET",
      path: () => `coaches`,
      params: {} as {'specialization'?: (string | number)[], 'languages'?: (string | number)[], 'take'?: string | number, 'skip'?: string | number, 'sort'?: string | number, 'maxPrice'?: string | number, 'minPrice'?: string | number}
    },
    getCoachById: {
      method: "GET",
      path: (payload: { coachId: string | number }) => `coaches/${payload.coachId}`
    },
    getCoachesFilterOptions: {
      method: "GET",
      path: () => `coaches/filter-options`
    }
  },
  coachProfile: {
    coachPosition: {
      addCoachPosition: {
        method: "POST",
        path: () => `coach-position`
      },
      getCoachPositions: {
        method: "GET",
        path: () => `coach-position`
      },
      updateCoachPosition: {
        method: "PATCH",
        path: (payload: { positionId: string | number }) => `coach-position/${payload.positionId}`
      },
      deleteCoachPosition: {
        method: "DELETE",
        path: (payload: { positionId: string | number }) => `coach-position/${payload.positionId}`
      }
    },
    coachEducation: {
      addCoachEducation: {
        method: "POST",
        path: () => `coach-education`
      },
      getCoachEducation: {
        method: "GET",
        path: () => `coach-education`
      },
      updateCoachEducation: {
        method: "PATCH",
        path: (payload: { educationId: string | number }) => `coach-education/${payload.educationId}`
      },
      deleteCoachEducation: {
        method: "DELETE",
        path: (payload: { educationId: string | number }) => `coach-education/${payload.educationId}`
      }
    },
    coachLanguage: {
      addCoachLanguage: {
        method: "POST",
        path: () => `coach-language`
      },
      updateCoachLanguage: {
        method: "PATCH",
        path: (payload: { languageId: string | number }) => `coach-language/${payload.languageId}`
      },
      getCoachLanguages: {
        method: "GET",
        path: () => `coach-language`
      },
      deleteCoachLanguage: {
        method: "DELETE",
        path: (payload: { languageId: string | number }) => `coach-language/${payload.languageId}`
      }
    },
    coachExperience: {
      addCoachExperience: {
        method: "POST",
        path: () => `coach-experience/`
      },
      updateCoachExperience: {
        method: "PATCH",
        path: (payload: { experienceId: string | number }) => `coach-experience/${payload.experienceId}`
      },
      getCoachExperience: {
        method: "GET",
        path: () => `coach-experience/`
      },
      deleteCoachExperience: {
        method: "DELETE",
        path: (payload: { experienceId: string | number }) => `coach-experience/${payload.experienceId}`
      }
    },
    coachCertifcation: {
      addCoachCertificate: {
        method: "POST",
        path: () => `coach-certification`
      },
      updateCoachCertificate: {
        method: "PATCH",
        path: (payload: { certificateId: string | number }) => `coach-certification/${payload.certificateId}`
      },
      getCoachCertifications: {
        method: "GET",
        path: () => `coach-certification`
      },
      deleteCoachCertificate: {
        method: "DELETE",
        path: (payload: { certificateId: string | number }) => `coach-certification/${payload.certificateId}`
      }
    },
    coachPositions: {
      getCoachPositions: {
        method: "GET",
        path: () => `coach-positions`
      },
      addCoachPositions: {
        method: "POST",
        path: () => `coach-positions`
      }
    },
    updateCoachDetails: {
      method: "PATCH",
      path: () => `coach-details`
    }
  },
  bookingSystem: {
    providers: {
      slots: {
        getProviderSlots: {
          method: "GET",
          path: (payload: { providerId: string | number }) => `provider-available-slots/${payload.providerId}`,
          params: {} as {'from'?: string | number, 'isFreeSession'?: string | number, 'to'?: string | number}
        },
        bookProviderSlot: {
          method: "POST",
          path: (payload: { providerId: string | number }) => `providers/${payload.providerId}`
        }
      },
      settings: {
        getProviderSettings: {
          method: "GET",
          path: () => `provider-settings`
        },
        updateProviderSettings: {
          method: "PATCH",
          path: () => `provider-settings`
        }
      },
      disabledSlots: {
        getProviderDisabledSlots: {
          method: "GET",
          path: () => `provider-disabled-slots`,
          params: {} as {'from'?: string | number, 'to'?: string | number, 'skip'?: string | number, 'take'?: string | number}
        },
        addProviderDisabledSlot: {
          method: "POST",
          path: () => `provider-disabled-slots`
        },
        editProviderDisabledSlot: {
          method: "PATCH",
          path: (payload: { disabledSlotId: string | number }) => `provider-disabled-slots/${payload.disabledSlotId}`
        },
        deleteProviderDisabledSlot: {
          method: "DELETE",
          path: (payload: { disabledSlotId: string | number }) => `provider-disabled-slots/${payload.disabledSlotId}`
        }
      },
      businessAvailability: {
        "bulkCreate/UpdateAvailability": {
          method: "POST",
          path: () => `provider-availability/bulk`
        },
        getAvailability: {
          method: "GET",
          path: () => `provider-availability`
        }
      },
      cancelBookingSession: {
        method: "POST",
        path: (payload: { bookingSessionId: string | number }) => `booking-sessions/${payload.bookingSessionId}/cancel`
      },
      updateBookingSession: {
        method: "PATCH",
        path: (payload: { bookingSessionId: string | number }) => `booking-sessions/${payload.bookingSessionId}`
      }
    },
    getBookedSessionsForProvider: {
      method: "GET",
      path: () => `booking-sessions/provider`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'isFreeSession'?: string | number, 'fromDate'?: string | number, 'toDate'?: string | number}
    },
    getBookedSessionsForUser: {
      method: "GET",
      path: () => `booking-sessions/client`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'isFreeSession'?: string | number, 'fromDate'?: string | number, 'toDate'?: string | number}
    }
  }
};