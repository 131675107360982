import { createVuetify } from 'vuetify';
import { md3 } from 'vuetify/blueprints';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/styles';
import { ThemeConfigVuetify } from '@/plugins/vuetify/theme-config.vuetify.ts';
import { DefaultsVuetifyComponents } from '@/plugins/vuetify/defaults.vuetify.ts';
import { VuetifyLocale } from '@/plugins/vuetify/locale.vuetify.ts';

const vuetify = createVuetify({
  theme: ThemeConfigVuetify,
  blueprint: md3,
  components,
  directives,
  locale: VuetifyLocale,
  aliases: {
    VBtnPrimary: components.VBtn,
    VBtnText: components.VBtn,
    VBtnPlain: components.VBtn,
  },
  defaults: DefaultsVuetifyComponents,
});

/*
 * This is a workaround for the issue of not detecting changes in the files and hot reload
 * `install`: This is a method that will be called when your plugin is installed. In the context of a Vue 3 plugin,
 *  the install method is expected to be present and will be called with the Vue app instance as its first argument.
 */
export default vuetify;
