export const connectivityHubApiHandler = {
  organizations: {
    getAllOrganizations: {
      method: "GET",
      path: () => `admin-dashboard/connectivity-hub/organizations`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    getOrganization: {
      method: "GET",
      path: (payload: { organizationId: string | number }) => `admin-dashboard/connectivity-hub/organizations/${payload.organizationId}`
    }
  },
  courses: {
    getAllCourses: {
      method: "GET",
      path: () => `admin-dashboard/connectivity-hub/courses`,
      params: {} as {'organizationId'?: string | number, 'search'?: string | number, 'skip'?: string | number, 'take'?: string | number, 'importedByOrgId'?: string | number}
    },
    getCourse: {
      method: "GET",
      path: (payload: { courseId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}`
    },
    getCourseOrganizations: {
      method: "GET",
      path: (payload: { courseId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}/organizations`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    "create/UpdateCourseTargetOrganizations": {
      method: "POST",
      path: (payload: { courseId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}/organizations`
    },
    deleteCourseTargetOrganizations: {
      method: "DELETE",
      path: (payload: { courseId: string | number , organizationId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}/organizations/${payload.organizationId}`
    },
    getCourseTargetOrganization: {
      method: "GET",
      path: (payload: { courseId: string | number , organizationId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}/organizations/${payload.organizationId}`
    },
    updateCourseCertificateTemplate: {
      method: "PUT",
      path: (payload: { courseId: string | number , organizationId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}/organizations/${payload.organizationId}/certificate-template`
    },
    updateCoursePassingCriteria: {
      method: "PUT",
      path: (payload: { courseId: string | number , organizationId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}/organizations/${payload.organizationId}/passing-criteria`
    },
    getCoursePassingCriteria: {
      method: "GET",
      path: (payload: { courseId: string | number , organizationId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}/organizations/${payload.organizationId}/passing-criteria`
    },
    getCourseTargetOrganizationCertificateTemplate: {
      method: "GET",
      path: (payload: { courseId: string | number , organizationId: string | number }) => `admin-dashboard/connectivity-hub/courses/${payload.courseId}/organizations/${payload.organizationId}/certificate-template`
    }
  }
};