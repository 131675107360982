export enum LocaleEnum {
  ENGLISH = 'en',
  ARABIC = 'ar',
  LOCALE_STORAGE_KEY = 'i18Key',
}

export const LanguagesEnum = [
  {
    name: 'English',
    value: LocaleEnum.ENGLISH,
  },
  {
    name: 'Arabic',
    value: LocaleEnum.ARABIC,
  },
];

export const LocaleStorageKey = LocaleEnum.LOCALE_STORAGE_KEY;
