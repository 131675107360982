import { LocaleStorageEnum } from '@/helpers/enums/locale-storage.enum';
import { pinia } from '@/plugins';
import { useLocaleStore } from '@/stores/shared/locale.pinia.ts';
import type { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';

export const setHeaders = (config: InternalAxiosRequestConfig) => {
  /*
   * add the language header to the request
   */
  const localStore = useLocaleStore(pinia);

  config = config ?? {};
  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders;
  }
  config.headers['language'] = `${localStore.locale}`;
  config.headers['Organization'] =
    localStorage.getItem(LocaleStorageEnum.ORGANIZATION_ID) ?? '1';

  /*
   * add the access token to the request
   */
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
};
