import {
  EnumOrganizationMemberPermissions,
  EnumOrganizationPermissions,
  EnumPermissionContextIds,
  EnumPermissionContexts,
} from '@/helpers/enums/permission.enum';
import type {
  AllOrgPermission,
  Categories,
  CategoriesPermission,
  Permission,
  PermissionObject,
  SinglePermissionSet,
} from '@/helpers/interfaces/admin/permission.interface';
import { axiosInstance, pinia } from '@/plugins';
import { authEnumRoutes } from '@/routes/auth/auth.enum.routes';
import { sharedEnumRoutes } from '@/routes/shared/shared.routes.enum';
import { ApiHandler as adminApi } from '@/submodules/adminApi/apiHandler';
import { ApiHandler } from '@/submodules/instructorApi/apiHandler';
import { defineStore, storeToRefs } from 'pinia';
import type { RouteLocationNormalized, RouteMeta } from 'vue-router';
import { useConfigurationStore } from '../admin/configuration.pinia';
import { useAuthStore } from '../auth/auth.pinia';
import { useToasterStore } from './toaster.pinia';

export const usePermissionStore = defineStore('permission', {
  state: () => ({
    systemPermissions: [] as Permission[],
    userPermissions: [] as Permission[],
    organzationPermissions: {} as AllOrgPermission,
    categoriesPermission: {} as CategoriesPermission,
    singlePermissionSet: {} as SinglePermissionSet,
    permissionsObject: [] as Categories[],
    permissionRecord: {} as Record<string, boolean>,
    systemPermissionsObject: undefined as PermissionObject | undefined,
    userPermissionsObject: undefined as PermissionObject | undefined,
  }),

  actions: {
    async getAllOrgPermissions(
      params: typeof adminApi.permissionSets.getAllOrgPermissionSets.params
    ) {
      const { path, method } = adminApi.permissionSets.getAllOrgPermissionSets;
      try {
        const { data, status } = await axiosInstance(path(), {
          method,
          params: {
            ...params,
            'contextSlugs[]': EnumPermissionContexts.OrganizationMember,
          },
        });
        this.organzationPermissions = data;
        return status;
      } catch (error) {
        return error;
      }
    },

    async getPermissionCategories(): Promise<number> {
      try {
        const { path, method } =
          adminApi.permissionSets.permissionsSystemCopy.permissionCategories
            .getAllPermissionCategories;
        const { data, status } = await axiosInstance(path(), {
          method,
        });
        this.categoriesPermission = data;
        return status;
      } catch (e) {
        return 500;
      }
    },
    async getSinglePermissionSet(orgPermissionSetId: number): Promise<number> {
      try {
        const { path, method } =
          adminApi.permissionSets.getSingleOrgPermissionSet;
        const { data, status } = await axiosInstance(
          path({
            orgPermissionSetId: orgPermissionSetId,
          }),
          {
            method,
          }
        );
        this.singlePermissionSet = data;
        return status;
      } catch (e) {
        return 500;
      }
    },
    async updateOrgPermissionSet(
      permission: {
        entityId: number;
        addOrRemove: string;
      }[],
      orgPermissionSetId: number
    ) {
      try {
        const { path, method } = adminApi.permissionSets.updateOrgPermissionSet;

        const { status } = await axiosInstance(
          path({
            orgPermissionSetId: orgPermissionSetId,
          }),
          {
            method,
            data: {
              permissions: permission,
            },
          }
        );
        return status;
      } catch (e) {
        return 500;
      }
    },
    async changeOrgPermissionSetName(
      name: string,
      orgPermissionSetId: number
    ): Promise<number> {
      try {
        const { path, method } = adminApi.permissionSets.updateOrgPermissionSet;
        const { status } = await axiosInstance(
          path({ orgPermissionSetId: orgPermissionSetId }),
          {
            method,
            data: {
              name: name,
            },
          }
        );
        await this.getAllOrgPermissions({ skip: 0, take: 100 });
        return status;
      } catch (e) {
        return 500;
      }
    },
    async createOrgPermissionSet(name: string, permissionContextSlug: string) {
      try {
        const { path, method } = adminApi.permissionSets.createOrgPermissionSet;
        const { data } = await axiosInstance(path(), {
          method,
          data: {
            name: name,
            permissionContextSlug: permissionContextSlug,
          },
        });
        //@todo if any organization have more than 40 role we will do skip and take //by suhaib
        await this.getAllOrgPermissions({
          skip: 0,
          take: Number.MAX_SAFE_INTEGER,
        });
        useToasterStore().created();

        return data;
      } catch (e) {
        useToasterStore().error();
        return 500;
      }
    },
    async deleteOrgPermissionSet(orgPermissionSetId: number): Promise<number> {
      try {
        const { path, method } = adminApi.permissionSets.deleteOrgPermissionSet;
        const { status } = await axiosInstance(
          path({ orgPermissionSetId: orgPermissionSetId }),
          {
            method,
          }
        );
        //@todo if any organization have more than 40 role we will do skip and take //by suhaib
        await this.getAllOrgPermissions({
          skip: 0,
          take: Number.MAX_SAFE_INTEGER,
        });
        return status;
      } catch (e) {
        return 500;
      }
    },
    setPermissionObject() {
      const permissionByCategory = this.systemPermissions.reduce(
        (
          acc: {
            [key: string]: Permission[];
          },
          permission: Permission
        ) => {
          if (permission.categoryId) {
            if (acc[permission.categoryId]) {
              acc[permission.categoryId].push(permission);
            } else {
              acc[permission.categoryId] = [permission];
            }
          }
          return acc;
        },
        {}
      );
      this.permissionsObject = this.categoriesPermission?.data?.map(
        (parentCategory: Categories) => {
          return {
            ...parentCategory,
            children: parentCategory.children.map((category) => {
              return {
                ...category,
                permissions: permissionByCategory[category.id],
              };
            }),
          };
        }
      );
    },

    async initializePermissions() {
      if (
        localStorage.getItem('access_token') &&
        localStorage.getItem('refresh_token')
      ) {
        await Promise.all([
          this.getSystemPermissions(),
          this.getUserPermissions(),
        ]);
        this.systemPermissionsObject = this.convertArrayToObject(
          this.systemPermissions
        );
        this.userPermissionsObject = this.convertArrayToObject(
          this.userPermissions
        );
      }
    },
    async getSystemPermissions() {
      try {
        const { path, method } =
          ApiHandler.misc.permissionsSystem.permissions.getAllPermissions;
        const { data } = await axiosInstance(path(), {
          method,
          params: {
            take: Number.MAX_SAFE_INTEGER,
          },
        });

        this.systemPermissions = data.data;
      } catch (error) {
        return error;
      }
    },
    async getUserPermissions() {
      try {
        const { path, method } = ApiHandler.auth.getUserPermissions;
        const { data } = await axiosInstance(path(), {
          method,
          params: {
            take: Number.MAX_SAFE_INTEGER,
          },
        });
        this.userPermissions = data.data;
      } catch (error) {
        return error;
      }
    },
    checkConfigKeys(meta: RouteMeta | undefined) {
      const configStore = useConfigurationStore();
      if (meta) {
        if (
          meta.shouldHaveMarketPlaceEnabled &&
          !configStore.marketPlaceEnabled
        )
          return false;
        if (
          meta.shouldHaveCertificateConfig &&
          !configStore.certificatesEnabled
        )
          return false;
        if (
          meta.shouldHaveRegistrationApproval &&
          configStore.registrationRequiresApproval?.strategy !== 'manual'
        )
          return false;

        if (
          meta.shouldHaveSelfRegistration &&
          !configStore.selfRegistrationEnabled
        )
          return false;
        return true;
      }
    },
    async checkAuthentication(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      beforeRoute: boolean = false
    ) {
      const meta = to.meta;
      const requiresAuth = (meta?.requiresAuth as boolean) ?? false;
      const requiresLogout = (meta?.requiresLogout as boolean) ?? false;
      const memberPermissions = meta?.memberPermissions ?? [];
      const shouldHaveMarketPlaceEnabled = meta.shouldHaveMarketPlaceEnabled;
      const shouldHaveRegistrationApproval =
        meta.shouldHaveRegistrationApproval;
      const { marketPlaceEnabled } = storeToRefs(useConfigurationStore(pinia));

      if (!localStorage.getItem('access_token') && requiresAuth) {
        return beforeRoute ? { name: authEnumRoutes.login.name } : false;
      } else if (requiresLogout && useAuthStore().isLogin) {
        if (from.name) {
          return { name: from.name };
        } else {
          return await useAuthStore().redirectAfterLogin();
        }
      } else if (localStorage.getItem('access_token')) {
        const { isAdministrator, isCoach, isInstructor } =
          useAuthStore().portals || {};
        if (
          (meta.isInstructor && isInstructor == false) ||
          (meta.isCoach && isCoach == false) ||
          (meta.isAdministrator && isAdministrator == false)
        ) {
          useToasterStore().addNotificationWithKey({
            text: 'auth.noAccessToThisPortalError',
            type: 'error',
            location: 'bottom center',
          });
          return await useAuthStore().redirectAfterLogin();
        } else if (
          memberPermissions?.length > 0 ||
          shouldHaveMarketPlaceEnabled ||
          shouldHaveRegistrationApproval
        ) {
          const havePermission = this.checkPermission(memberPermissions);

          if (
            havePermission &&
            shouldHaveMarketPlaceEnabled &&
            !marketPlaceEnabled.value
          ) {
            return await useAuthStore().redirectAfterLogin();
          } else if (!havePermission) {
            if (from?.name) {
              return { name: from.name };
            } else {
              useToasterStore().addNotificationWithKey({
                text: 'auth.noAccessToThisPortalError',
                type: 'error',
                location: 'bottom center',
              });
              return { name: sharedEnumRoutes.notAuth.name };
            }
          }
        } else if (to.name == sharedEnumRoutes.root.name) {
          return await useAuthStore().redirectAfterLogin();
        } else {
          return null;
        }
      }
    },
    getBypassPermissionName(
      permissionContextId: EnumPermissionContextIds | undefined
    ) {
      switch (permissionContextId) {
        case EnumPermissionContextIds.OrganizationMember:
          return 'organization_member_full_access';
        case EnumPermissionContextIds.User:
          return 'user_everything';
        case EnumPermissionContextIds.Global:
          return 'global_everything';
        case EnumPermissionContextIds.Organization:
          return 'organization_everything';
        default:
          return 'never_gonna_give_you_up';
      }
    },

    convertArrayToObject(permissionsArray: Permission[]) {
      return permissionsArray.reduce((acc, permission) => {
        acc[permission.slug as string] = permission;
        return acc;
      }, {} as PermissionObject);
    },

    checkPermission(
      permissions: Array<
        EnumOrganizationMemberPermissions | EnumOrganizationPermissions
      >
    ) {
      const checkAllPermission = (
        permissions: Array<
          EnumOrganizationMemberPermissions | EnumOrganizationPermissions
        >
      ): boolean => {
        return permissions.some(
          (
            permissionSlug:
              | EnumOrganizationMemberPermissions
              | EnumOrganizationPermissions
          ) => {
            if (!this.userPermissionsObject || !this.systemPermissionsObject)
              return false;

            const baypassPermission = this.getBypassPermissionName(
              this.systemPermissionsObject[permissionSlug]?.contextId
            );

            if (this.userPermissionsObject[baypassPermission]) return true;

            if (permissionSlug in this.permissionRecord) {
              return this.permissionRecord[permissionSlug];
            }

            const hasPermissionInUser =
              permissionSlug in this.userPermissionsObject; // can be changed to  userPermissionsObject[permissionSlug];
            const hasPermissionInSystem =
              permissionSlug in this.systemPermissionsObject; // can be changed to  userPermissionsObject[permissionSlug];

            const hasPermission = hasPermissionInUser && hasPermissionInSystem;

            const userPermission = this.userPermissionsObject[permissionSlug];

            if (
              hasPermission &&
              userPermission.prerequisites &&
              userPermission.prerequisites.length > 0
            ) {
              const allPrerequisitesGranted =
                userPermission.prerequisites.every((prerequisite) => {
                  return checkAllPermission([prerequisite.prerequisite.slug]);
                });

              if (!allPrerequisitesGranted) {
                this.permissionRecord[permissionSlug] = false;
                return false;
              }
            } else {
              this.permissionRecord[permissionSlug] = hasPermission;
            }
            return hasPermission;
          }
        );
      };

      return checkAllPermission(permissions);
    },
  },
});
