import type { RouteRecordRaw } from 'vue-router';
import { coachEnumRoutes } from './coach.enum.routes';

const coachRoutes: Array<RouteRecordRaw> = [
  {
    path: coachEnumRoutes.root.path,
    name: coachEnumRoutes.root.name,
    component: () => import('@/layouts/coach/BaseCoachLayout.vue'),
    meta: coachEnumRoutes.root.meta,
  },
];
export default coachRoutes;
