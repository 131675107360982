export const scheduleApiHandler = {
  "student:AllActivitiesSchedule:Assignments,Exams,CoachingSessions,Events": {
    method: "GET",
    path: (payload: { userId: string | number }) => `users/${payload.userId}/calendar`,
    params: {} as {'startTime'?: string | number, 'endingTime'?: string | number, 'completedFlag'?: string | number, 'undatedFlag'?: string | number}
  },
  "instructor:AllClassesSchedule": {
    method: "GET",
    path: (payload: { userId: string | number }) => `users/${payload.userId}/calendar/instructor`,
    params: {} as {'startTime'?: string | number, 'endingTime'?: string | number}
  },
  "coach:AllBookingsSchedule": {
    method: "GET",
    path: (payload: { userId: string | number }) => `users/${payload.userId}/calendar/bookings`,
    params: {} as {'startTime'?: string | number, 'endingTime'?: string | number}
  }
};