export const checklistsApiHandler = {
  assignees: {
    getCoChecklistAssignees: {
      method: "GET",
      path: (payload: { checklistId: string | number }) => `org-checklists-management/${payload.checklistId}/assignees`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'membersOnly'?: string | number, 'search'?: string | number}
    },
    updateCoChecklistAssignees: {
      method: "PATCH",
      path: (payload: { checklistId: string | number }) => `org-checklists-management/${payload.checklistId}/assignees`
    }
  },
  checklistItems: {
    getChecklistItems: {
      method: "GET",
      path: (payload: { checklistId: string | number }) => `org-checklists-management/${payload.checklistId}/items`,
      params: {} as {'search'?: string | number}
    },
    createChecklistItem: {
      method: "POST",
      path: (payload: { checklistId: string | number }) => `org-checklists-management/${payload.checklistId}/items`
    },
    updateChecklistItem: {
      method: "PATCH",
      path: (payload: { checklistId: string | number , checklistItemId: string | number }) => `org-checklists-management/${payload.checklistId}/items/${payload.checklistItemId}`
    },
    deleteChecklistItem: {
      method: "DELETE",
      path: (payload: { checklistId: string | number , checklistItemId: string | number }) => `org-checklists-management/${payload.checklistId}/items/${payload.checklistItemId}`
    },
    getChecklistItem: {
      method: "GET",
      path: (payload: { checklistId: string | number , checklistItemId: string | number }) => `org-checklists-management/${payload.checklistId}/items/${payload.checklistItemId}`
    }
  },
  createCoChecklist: {
    method: "POST",
    path: () => `org-checklists-management`
  },
  updateCoChecklist: {
    method: "PATCH",
    path: (payload: { checklistId: string | number }) => `org-checklists-management/${payload.checklistId}`
  },
  getCoChecklists: {
    method: "GET",
    path: () => `org-checklists-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
  },
  getCoChecklistDetails: {
    method: "GET",
    path: (payload: { checklistId: string | number }) => `org-checklists-management/${payload.checklistId}`
  },
  deleteCoChecklist: {
    method: "DELETE",
    path: (payload: { checklistId: string | number }) => `org-checklists-management/${payload.checklistId}`
  },
  getFields: {
    method: "GET",
    path: () => `org-checklists-management/fields`
  },
  getCoChecklistStudentsProgress: {
    method: "GET",
    path: (payload: { checklistId: string | number }) => `org-checklists-management/${payload.checklistId}/progress`
  }
};