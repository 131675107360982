export const socialEventsApiHandler = {
  hosts: {
    getSocialEventHosts: {
      method: "GET",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/hosts`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    updateSocialEventHosts: {
      method: "PATCH",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/hosts`
    }
  },
  guests: {
    getSocialEventGuests: {
      method: "GET",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/guests`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    addSocialEventGuest: {
      method: "POST",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/guests`
    },
    updateSocialEventGuest: {
      method: "PATCH",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/guests`
    },
    deleteSocialEventGuest: {
      method: "DELETE",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/guests`
    }
  },
  attendance: {
    getSocialEventAttendance: {
      method: "GET",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/attendees`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    getSocialEventCheckoutQR: {
      method: "POST",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/attendance-token`
    }
  },
  actualAttendance: {
    attendanceItems: {
      method: "GET",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/attendance-items`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    },
    getAttendanceItem: {
      method: "GET",
      path: (payload: { eventId: string | number , attendanceItemId: string | number }) => `org-social-events-management/${payload.eventId}/attendance-items/${payload.attendanceItemId}`
    },
    getAttendanceReport: {
      method: "GET",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/attendance-items/report`
    },
    addAttendanceItem: {
      method: "POST",
      path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}/attendance-items`
    },
    deleteAttendanceItem: {
      method: "DELETE",
      path: (payload: { eventId: string | number , attendanceItemId: string | number }) => `org-social-events-management/${payload.eventId}/attendance-items/${payload.attendanceItemId}`
    },
    updateAttendance: {
      method: "PUT",
      path: (payload: { eventId: string | number , attendanceItemId: string | number }) => `org-social-events-management/${payload.eventId}/attendance-items/${payload.attendanceItemId}`
    }
  },
  createSocialEvent: {
    method: "POST",
    path: () => `org-social-events-management`
  },
  updateSocialEvent: {
    method: "PATCH",
    path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}`
  },
  getAllSocialEvents: {
    method: "GET",
    path: () => `org-social-events-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number, 'startTime'?: string | number, 'endingTime'?: string | number, 'groupId'?: string | number, 'orderBy[startTime]'?: string | number, orderByThisDoesntWorkCheckPostman?: (string | number)[]}
  },
  getSingleSocialEvent: {
    method: "GET",
    path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}`
  },
  deleteSocialEvent: {
    method: "DELETE",
    path: (payload: { eventId: string | number }) => `org-social-events-management/${payload.eventId}`
  }
};