export enum ConfigKeysEnum {
  TWO_FACTOR_ENABLED = 'two-factor-enabled',
  REGISTRATION_REQUIRES_APPROVAL = 'registration-requires-approval',
  MARKETPLACE_ENABLED = 'marketplace-enabled',
  SELF_ENROLLMENT_ENABLED = 'self-enrollment-enabled',
  ALLOW_ANONYMOUS_COURSE_VIEW = 'allow-anonymous-course-view',
  ALLOW_ANONYMOUS_EVENT_VIEW = 'allow-anonymous-event-view',
  CERTIFICATES_ENABLED = 'certificates-enabled',
  PASSING_CRITERIA_CONFIGURATION_ENABLED_FOR_ADMINS = 'passing-criteria-configuration-enabled-for-admins',
  PASSING_CRITERIA_CONFIGURATION_ENABLED_FOR_INSTRUCTORS = 'passing-criteria-configuration-enabled-for-instructors',
  SELF_REGISTRATION_ENABLED = 'self-registration-enabled',
}
export enum EnumOrganizationTerminology {
  AUTH_IMAGE = 'auth_image',
  BLENDED = 'blended',
  COURSE = 'course',
  DASHBOARD = 'dashboard',
  INSTRUCTOR = 'instructor',
  MODULE = 'module',
  STUDENT = 'student',
  LOGIN_ORGANIZATION_LOGO = 'login_organization_logo',
}
