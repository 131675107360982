import { instructorEnumRoutes } from '@/routes/instructor/instructor.enum.routes';
import type { RouteRecordRaw } from 'vue-router';

const instructorRoutes: Array<RouteRecordRaw> = [
  {
    path: instructorEnumRoutes.root.path,
    name: instructorEnumRoutes.root.name,
    meta: instructorEnumRoutes.root.meta,
    redirect: instructorEnumRoutes.instructorAllCourses.path,
    component: () => import('@/layouts/instructor/BaseInstructorLayout.vue'),
    children: [
      {
        name: instructorEnumRoutes.instructorAllCourses.name,
        path: instructorEnumRoutes.instructorAllCourses.path,
        meta: instructorEnumRoutes.instructorAllCourses.meta,
        component: () =>
          import(
            '@/modules/instructor/pages/Layout/InstructorAllCoursesPage.vue'
          ),
      },
      {
        name: instructorEnumRoutes.InstructorStudentView.name,
        path: instructorEnumRoutes.InstructorStudentView.path,
        meta: instructorEnumRoutes.InstructorStudentView.meta,
        component: () =>
          import('@/modules/instructor/pages/course/StudentViewPage.vue'),
      },
      {
        name: instructorEnumRoutes.baseCourseLayoutInstructor.name,
        path: instructorEnumRoutes.baseCourseLayoutInstructor.path,
        meta: instructorEnumRoutes.baseCourseLayoutInstructor.meta,
        redirect: { name: instructorEnumRoutes.InstructorCourseContent.name },
        component: () =>
          import(
            '@/modules/instructor/pages/Layout/BaseCourseLayoutInstructor.vue'
          ),
        children: [
          {
            name: instructorEnumRoutes.InstructorCourseOverview.name,
            path: instructorEnumRoutes.InstructorCourseOverview.path,
            meta: instructorEnumRoutes.InstructorCourseOverview.meta,
            component: () =>
              import('@/modules/instructor/pages/course/OverviewPage.vue'),
          },
          {
            name: instructorEnumRoutes.InstructorSyllabus.name,
            path: instructorEnumRoutes.InstructorSyllabus.path,
            meta: instructorEnumRoutes.InstructorSyllabus.meta,
            component: () =>
              import('@/modules/instructor/pages/course/SyllabusPage.vue'),
          },
          {
            name: instructorEnumRoutes.InstructorCourseContent.name,
            path: instructorEnumRoutes.InstructorCourseContent.path,
            meta: instructorEnumRoutes.InstructorCourseContent.meta,
            component: () =>
              import('@/modules/instructor/pages/course/ContentPage.vue'),
          },
          {
            name: instructorEnumRoutes.InstructorOnlineLectures.name,
            path: instructorEnumRoutes.InstructorOnlineLectures.path,
            meta: instructorEnumRoutes.InstructorOnlineLectures.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/course/OnlineLecturesPage.vue'
              ),
          },
          {
            name: instructorEnumRoutes.createOnlineLecture.name,
            path: instructorEnumRoutes.createOnlineLecture.path,
            meta: instructorEnumRoutes.createOnlineLecture.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/online-lectures/CreateOnlineLectures.vue'
              ),
          },
          {
            name: instructorEnumRoutes.editOnlineLecture.name,
            path: instructorEnumRoutes.editOnlineLecture.path,
            meta: instructorEnumRoutes.editOnlineLecture.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/online-lectures/EditOnlineLectures.vue'
              ),
          },
          {
            name: instructorEnumRoutes.InstructorOnCampusLectures.name,
            path: instructorEnumRoutes.InstructorOnCampusLectures.path,
            meta: instructorEnumRoutes.InstructorOnCampusLectures.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/course/OnCampusLecturesPage.vue'
              ),
          },
          {
            name: instructorEnumRoutes.createOnCampusLecture.name,
            path: instructorEnumRoutes.createOnCampusLecture.path,
            meta: instructorEnumRoutes.createOnCampusLecture.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/on-campus-lectures/CreateOnCampusLectures.vue'
              ),
          },
          {
            name: instructorEnumRoutes.editOnCampusLecture.name,
            path: instructorEnumRoutes.editOnCampusLecture.path,
            meta: instructorEnumRoutes.editOnCampusLecture.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/on-campus-lectures/EditOnCampusLectures.vue'
              ),
          },
          /** >>>>>>>>>>>>>>> Poll And Survey <<<<<<<<<<<<<<< **/
          //>>>>>>> All Polls <<<<<<<\
          {
            name: instructorEnumRoutes.InstructorPolls.name,
            path: instructorEnumRoutes.InstructorPolls.path,
            meta: instructorEnumRoutes.InstructorPolls.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/course/PollsAndSurveysPage.vue'
              ),
          },
          //>>>>>>> Single Poll <<<<<<<\
          {
            name: instructorEnumRoutes.pollView.name,
            path: instructorEnumRoutes.pollView.path,
            meta: instructorEnumRoutes.pollView.meta,
            component: () =>
              import('@/modules/instructor/pages/Layout/BasePollLayout.vue'),
            children: [
              {
                name: instructorEnumRoutes.pollDetails.name,
                path: instructorEnumRoutes.pollDetails.path,
                meta: instructorEnumRoutes.pollDetails.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.createPoll.name,
                path: instructorEnumRoutes.createPoll.path,
                meta: instructorEnumRoutes.createPoll.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.pollQuestions.name,
                path: instructorEnumRoutes.pollQuestions.path,
                meta: instructorEnumRoutes.pollQuestions.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollQuestions.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.pollConfigurations.name,
                path: instructorEnumRoutes.pollConfigurations.path,
                meta: instructorEnumRoutes.pollConfigurations.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollConfigurations.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.pollSubmissions.name,
                path: instructorEnumRoutes.pollSubmissions.path,
                meta: instructorEnumRoutes.pollSubmissions.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollSubmissions.vue'
                  ),
              },
            ],
          },
          /** >>>>>>>>>>>>>>> All Survey <<<<<<<<<<<<<<< **/
          {
            name: instructorEnumRoutes.InstructorSurveys.name,
            path: instructorEnumRoutes.InstructorSurveys.path,
            meta: instructorEnumRoutes.InstructorSurveys.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/course/PollsAndSurveysPage.vue'
              ),
          },
          /** >>>>>>>>>>>>>>> Single Survey <<<<<<<<<<<<<<< **/
          {
            name: instructorEnumRoutes.surveyView.name,
            path: instructorEnumRoutes.surveyView.path,
            meta: instructorEnumRoutes.surveyView.meta,
            component: () =>
              import('@/modules/instructor/pages/Layout/BasePollLayout.vue'),
            children: [
              {
                name: instructorEnumRoutes.surveyDetails.name,
                path: instructorEnumRoutes.surveyDetails.path,
                meta: instructorEnumRoutes.surveyDetails.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.createSurvey.name,
                path: instructorEnumRoutes.createSurvey.path,
                meta: instructorEnumRoutes.createSurvey.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.surveyQuestions.name,
                path: instructorEnumRoutes.surveyQuestions.path,
                meta: instructorEnumRoutes.surveyQuestions.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollQuestions.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.surveyConfigurations.name,
                path: instructorEnumRoutes.surveyConfigurations.path,
                meta: instructorEnumRoutes.surveyConfigurations.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollConfigurations.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.surveySubmissions.name,
                path: instructorEnumRoutes.surveySubmissions.path,
                meta: instructorEnumRoutes.surveySubmissions.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/pollAndSurvey/PollSubmissions.vue'
                  ),
              },
            ],
          },
          {
            name: instructorEnumRoutes.InstructorQuizzes.name,
            path: instructorEnumRoutes.InstructorQuizzes.path,
            meta: instructorEnumRoutes.InstructorQuizzes.meta,
            component: () =>
              import('@/modules/instructor/pages/course/QuizzesPage.vue'),
          },

          {
            name: instructorEnumRoutes.quizView.name,
            path: instructorEnumRoutes.quizView.path,
            meta: instructorEnumRoutes.quizView.meta,
            component: () =>
              import('@/modules/instructor/pages/Layout/BaseQuizLayout.vue'),
            children: [
              {
                name: instructorEnumRoutes.quizDetails.name,
                path: instructorEnumRoutes.quizDetails.path,
                meta: instructorEnumRoutes.quizDetails.meta,
                component: () =>
                  import('@/modules/instructor/pages/quiz/QuizDetails.vue'),
              },
              {
                name: instructorEnumRoutes.createQuizzes.name,
                path: instructorEnumRoutes.createQuizzes.path,
                meta: instructorEnumRoutes.createQuizzes.meta,
                component: () =>
                  import('@/modules/instructor/pages/quiz/QuizDetails.vue'),
              },
              {
                name: instructorEnumRoutes.quizQuestions.name,
                path: instructorEnumRoutes.quizQuestions.path,
                meta: instructorEnumRoutes.quizQuestions.meta,
                component: () =>
                  import('@/modules/instructor/pages/quiz/QuizQuestions.vue'),
              },
              {
                name: instructorEnumRoutes.quizConfigurations.name,
                path: instructorEnumRoutes.quizConfigurations.path,
                meta: instructorEnumRoutes.quizConfigurations.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/quiz/QuizConfigurations.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.quizSubmissions.name,
                path: instructorEnumRoutes.quizSubmissions.path,
                meta: instructorEnumRoutes.quizSubmissions.meta,
                component: () =>
                  import('@/modules/instructor/pages/quiz/QuizSubmissions.vue'),
              },
            ],
          },

          {
            name: instructorEnumRoutes.InstructorAssignments.name,
            path: instructorEnumRoutes.InstructorAssignments.path,
            meta: instructorEnumRoutes.InstructorAssignments.meta,
            component: () =>
              import('@/modules/instructor/pages/course/AssignmentsPage.vue'),
          },
          {
            name: instructorEnumRoutes.assignmentView.name,
            path: instructorEnumRoutes.assignmentView.path,
            meta: instructorEnumRoutes.assignmentView.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/Layout/BaseAssignmentLayout.vue'
              ),
            children: [
              {
                name: instructorEnumRoutes.createAssignment.name,
                path: instructorEnumRoutes.createAssignment.path,
                meta: instructorEnumRoutes.createAssignment.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/assignments/AssignmentDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.assignmentDetails.name,
                path: instructorEnumRoutes.assignmentDetails.path,
                meta: instructorEnumRoutes.assignmentDetails.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/assignments/AssignmentDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.assignmentConfiguration.name,
                path: instructorEnumRoutes.assignmentConfiguration.path,
                meta: instructorEnumRoutes.assignmentConfiguration.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/assignments/AssignmentConfiguration.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.assignmentSubmissions.name,
                path: instructorEnumRoutes.assignmentSubmissions.path,
                meta: instructorEnumRoutes.assignmentSubmissions.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/assignments/AssignmentSubmissions.vue'
                  ),
              },
            ],
          },
          {
            name: instructorEnumRoutes.InstructorDiscussionsBoards.name,
            path: instructorEnumRoutes.InstructorDiscussionsBoards.path,
            meta: instructorEnumRoutes.InstructorDiscussionsBoards.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/course/DiscussionsBoardsPage.vue'
              ),
          },

          /** >>>>>>>>>>>>>>> Discussion Board <<<<<<<<<<<<<<< **/
          {
            name: instructorEnumRoutes.discussionBoardView.name,
            path: instructorEnumRoutes.discussionBoardView.path,
            meta: instructorEnumRoutes.discussionBoardView.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/Layout/BaseDiscussionBoardLayout.vue'
              ),
            children: [
              {
                name: instructorEnumRoutes.createDiscussionBoard.name,
                path: instructorEnumRoutes.createDiscussionBoard.path,
                meta: instructorEnumRoutes.createDiscussionBoard.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/discussion-board/DiscussionBoardDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.discussionBoardDetails.name,
                path: instructorEnumRoutes.discussionBoardDetails.path,
                meta: instructorEnumRoutes.discussionBoardDetails.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/discussion-board/DiscussionBoardDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.discussionBoardConfiguration.name,
                path: instructorEnumRoutes.discussionBoardConfiguration.path,
                meta: instructorEnumRoutes.discussionBoardConfiguration.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/discussion-board/DiscussionBoardConfiguration.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.discussionBoardComments.name,
                path: instructorEnumRoutes.discussionBoardComments.path,
                meta: instructorEnumRoutes.discussionBoardComments.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/discussion-board/DiscussionBoardComments.vue'
                  ),
              },
            ],
          },
          {
            name: instructorEnumRoutes.InstructorGrades.name,
            path: instructorEnumRoutes.InstructorGrades.path,
            meta: instructorEnumRoutes.InstructorGrades.meta,
            component: () =>
              import('@/modules/instructor/pages/course/GradesPage.vue'),
          },
          {
            name: instructorEnumRoutes.createGradeItem.name,
            path: instructorEnumRoutes.createGradeItem.path,
            meta: instructorEnumRoutes.createGradeItem.meta,
            component: () =>
              import('@/modules/instructor/pages/grade/CreateGradeItem.vue'),
          },
          {
            name: instructorEnumRoutes.editGradeItem.name,
            path: instructorEnumRoutes.editGradeItem.path,
            meta: instructorEnumRoutes.editGradeItem.meta,
            component: () =>
              import('@/modules/instructor/pages/grade/EditGradeItem.vue'),
          },
          {
            name: instructorEnumRoutes.viewSubmissionGrades.name,
            path: instructorEnumRoutes.viewSubmissionGrades.path,
            meta: instructorEnumRoutes.viewSubmissionGrades.meta,
            component: () =>
              import('@/modules/instructor/pages/grade/SubmissionsGrades.vue'),
          },
          {
            path: instructorEnumRoutes.viewStudentSubmission.path,
            name: instructorEnumRoutes.viewStudentSubmission.name,
            meta: instructorEnumRoutes.viewStudentSubmission.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/grade/ViewStudentSubmission.vue'
              ),
          },
          {
            path: instructorEnumRoutes.viewGroupSubmission.path,
            name: instructorEnumRoutes.viewGroupSubmission.name,
            meta: instructorEnumRoutes.viewGroupSubmission.meta,
            component: () =>
              import(
                '@/modules/instructor/pages/grade/ViewGroupSubmission.vue'
              ),
          },
          {
            name: instructorEnumRoutes.InstructorAttendance.name,
            path: instructorEnumRoutes.InstructorAttendance.path,
            meta: instructorEnumRoutes.InstructorAttendance.meta,
            component: () =>
              import('@/modules/instructor/pages/course/AttendancePage.vue'),
          },

          {
            name: instructorEnumRoutes.attendanceView.name,
            path: instructorEnumRoutes.attendanceView.path,
            meta: instructorEnumRoutes.attendanceView.meta,
            component: () =>
              import(
                '@/modules/instructor/components/attendance/BaseAttendanceLayout.vue'
              ),
            children: [
              {
                name: instructorEnumRoutes.createAttendance.name,
                path: instructorEnumRoutes.createAttendance.path,
                meta: instructorEnumRoutes.createAttendance.meta,
                component: () =>
                  import(
                    '@/modules/instructor/components/attendance/AttendanceDetails.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.editAttendance.name,
                path: instructorEnumRoutes.editAttendance.path,
                meta: instructorEnumRoutes.editAttendance.meta,
                component: () =>
                  import(
                    '@/modules/instructor/components/attendance/AttendanceDetails.vue'
                  ),
              },
            ],
          },

          {
            name: instructorEnumRoutes.InstructorAnnouncements.name,
            path: instructorEnumRoutes.InstructorAnnouncements.path,
            meta: instructorEnumRoutes.InstructorAnnouncements.meta,
            component: () =>
              import('@/modules/instructor/pages/course/AnnouncementsPage.vue'),
          },

          {
            name: instructorEnumRoutes.createAnnouncements.name,
            path: instructorEnumRoutes.createAnnouncements.path,
            meta: instructorEnumRoutes.createAnnouncements.meta,
            component: () =>
              import(
                '@/modules/instructor/components/announcements/CreateAnnouncements.vue'
              ),
          },
          {
            name: instructorEnumRoutes.editAnnouncements.name,
            path: instructorEnumRoutes.editAnnouncements.path,
            meta: instructorEnumRoutes.editAnnouncements.meta,
            component: () =>
              import(
                '@/modules/instructor/components/announcements/EditAnnouncements.vue'
              ),
          },

          {
            name: instructorEnumRoutes.InstructorRubrics.name,
            path: instructorEnumRoutes.InstructorRubrics.path,
            meta: instructorEnumRoutes.InstructorRubrics.meta,
            component: () =>
              import('@/modules/instructor/pages/course/RubricsPage.vue'),
          },

          {
            name: instructorEnumRoutes.createRubric.name,
            path: instructorEnumRoutes.createRubric.path,
            meta: instructorEnumRoutes.createRubric.meta,
            component: () =>
              import('@/modules/instructor/pages/rubric/CreateRubric.vue'),
          },
          {
            name: instructorEnumRoutes.editRubric.name,
            path: instructorEnumRoutes.editRubric.path,
            meta: instructorEnumRoutes.editRubric.meta,
            component: () =>
              import('@/modules/instructor/pages/rubric/EditRubric.vue'),
          },
          {
            name: instructorEnumRoutes.InstructorReports.name,
            path: instructorEnumRoutes.InstructorReports.path,
            meta: instructorEnumRoutes.InstructorReports.meta,
            redirect: { name: instructorEnumRoutes.gradesReport.name },
            component: () =>
              import('@/modules/instructor/pages/course/ReportsPage.vue'),
            children: [
              {
                name: instructorEnumRoutes.gradesReport.name,
                path: instructorEnumRoutes.gradesReport.path,
                meta: instructorEnumRoutes.gradesReport.meta,
                component: () =>
                  import('@/modules/instructor/pages/reports/GradesReport.vue'),
              },
              {
                name: instructorEnumRoutes.attendanceReport.name,
                path: instructorEnumRoutes.attendanceReport.path,
                meta: instructorEnumRoutes.attendanceReport.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/reports/AttendanceReport.vue'
                  ),
              },
              {
                name: instructorEnumRoutes.certificateReport.name,
                path: instructorEnumRoutes.certificateReport.path,
                meta: instructorEnumRoutes.certificateReport.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/reports/CertificateReport.vue'
                  ),
              },
            ],
          },
          {
            name: instructorEnumRoutes.InstructorGroups.name,
            path: instructorEnumRoutes.InstructorGroups.path,
            meta: instructorEnumRoutes.InstructorGroups.meta,
            component: () =>
              import('@/modules/instructor/pages/course/GroupsPage.vue'),
          },
          {
            name: instructorEnumRoutes.InstructorStudents.name,
            path: instructorEnumRoutes.InstructorStudents.path,
            meta: instructorEnumRoutes.InstructorStudents.meta,
            component: () =>
              import('@/modules/instructor/pages/course/StudentsPage.vue'),
          },
          {
            name: instructorEnumRoutes.InstructorDropBox.name,
            path: instructorEnumRoutes.InstructorDropBox.path,
            meta: instructorEnumRoutes.InstructorDropBox.meta,
            component: () =>
              import('@/modules/instructor/pages/course/DropBoxPage.vue'),
          },

          {
            name: instructorEnumRoutes.createSubsection.name,
            path: instructorEnumRoutes.createSubsection.path,
            meta: instructorEnumRoutes.createSubsection.meta,
            component: () =>
              import(
                '@/modules/instructor/components/course-content/AddSubsection.vue'
              ),
          },
          {
            name: instructorEnumRoutes.editSubsection.name,
            path: instructorEnumRoutes.editSubsection.path,
            meta: instructorEnumRoutes.editSubsection.meta,
            component: () =>
              import(
                '@/modules/instructor/components/course-content/subsection/EditSubSection.vue'
              ),
          },
          {
            name: instructorEnumRoutes.scormView.name,
            path: instructorEnumRoutes.scormView.path,
            meta: instructorEnumRoutes.scormView.meta,
            component: () =>
              import('@/modules/instructor/pages/scorm/ScormBaseLayout.vue'),
            children: [
              {
                name: instructorEnumRoutes.createScorm.name,
                path: instructorEnumRoutes.createScorm.path,
                meta: instructorEnumRoutes.createScorm.meta,
                component: () =>
                  import('@/modules/instructor/pages/scorm/ScormDetails.vue'),
              },
              {
                name: instructorEnumRoutes.scormDetails.name,
                path: instructorEnumRoutes.scormDetails.path,
                meta: instructorEnumRoutes.scormDetails.meta,
                component: () =>
                  import('@/modules/instructor/pages/scorm/ScormDetails.vue'),
              },
              {
                name: instructorEnumRoutes.scormUserDetails.name,
                path: instructorEnumRoutes.scormUserDetails.path,
                meta: instructorEnumRoutes.scormUserDetails.meta,
                component: () =>
                  import(
                    '@/modules/instructor/pages/scorm/ScormUserProgress.vue'
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
export default instructorRoutes;
