export const profileEnumRoutes = {
  root: {
    path: '/profile',
    name: 'Profile',
    meta: {
      title: 'profile.profileTabTitle',
      requiresAuth: true,
    },
  },
  profileIndex: {
    path: '/profile/index',
    name: 'ProfileIndex',
    meta: {
      title: 'profile.moduleTabTitle',
    },
  },
  changePassword: {
    path: '/profile/change-password',
    name: 'ChangePassword',
  },
  zoomPolicy: {
    name: 'Zoom Policy',
    path: '/zoom-policy',
    meta: {
      title: 'profile.notificationTitle',
      name: `${import.meta.env.VITE_DEFAULT_TITLE} | Zoom Policy`,
    },
  },
  //>>>>>>> Notification <<<<<<<\
  notification: {
    path: '/profile/notification',
    name: 'Notification',
    meta: {
      title: 'profile.notificationTitle',
    },
  },
};
