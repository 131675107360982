import { defineStore } from 'pinia';
import { vuetify } from '@/plugins';
import { ref } from 'vue';
import { OrganizationFontFamily } from '@/submodules/generated_types/types/organization_font_family.ts';
import { FontFamily } from '@/submodules/generated_types/types/font_family.ts';

export const useVuetifyStore = defineStore('vuetify', () => {
  const fontFamilies = ref<OrganizationFontFamily[]>([]);
  // function to change theme variables
  const changeThemeMainColors = (primary: string, secondary: string) => {
    if (!primary || !secondary) return;
    vuetify.theme.themes.value.mainTheme.colors.primary = primary;
    vuetify.theme.themes.value.mainTheme.colors.secondary = secondary;
  };

  const changeThemeMainCardRadius = (radius: number) => {
    // change the Vuetify theme card radius v-card
    vuetify.theme.themes.value.mainTheme.variables['card-border-radius'] =
      `${radius}px`;
  };

  const changeRootRadius = (radius: number) => {
    vuetify.theme.themes.value.mainTheme.variables['root-border-radius'] =
      `${radius}`;
  };

  const prepareGoogleFontLink = (font: FontFamily) => {
    /*
     * check if the font is google font or not
     */
    if (!font.isGoogleFont) {
      return;
    }
    /*
     *example of the google font link
     *<link rel="preconnect" href="https://fonts.googleapis.com">
     *<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
     * <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
     */

    // add the google font link to the head tag
    const head = document.querySelector('head');
    // check if the head tag is exist
    if (!head) {
      return;
    }
    // check if the google font link is already exist
    if (!document.querySelector('link[href*="fonts.googleapis.com"]')) {
      const link = document.createElement('link');
      link.rel = 'preconnect';
      link.href = 'https://fonts.googleapis.com';
      head?.appendChild(link);
    }

    // check if the google font link is already exist
    if (!document.querySelector('link[href*="fonts.gstatic.com"]')) {
      const link = document.createElement('link');
      link.rel = 'preconnect';
      link.href = 'https://fonts.gstatic.com';
      link.crossOrigin = 'true';
      head?.appendChild(link);
    }

    // check if the google font link is already exist
    const link = `https://fonts.googleapis.com/css2?family=${font.name.replace(
      ' ',
      '+'
    )}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap`;
    if (!document.querySelector(`link[href*="${link}"]`)) {
      const link = document.createElement('link');
      link.href = `https://fonts.googleapis.com/css2?family=${font.name.replace(
        ' ',
        '+'
      )}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap`;
      link.rel = 'stylesheet';
      head?.appendChild(link);
    }
  };

  const changeFontFamily = (fonts: OrganizationFontFamily[]) => {
    // change the .ar, .en font-family and should be important to override the Vuetify font-family
    fontFamilies.value = fonts;
    const head = document.querySelector('head');
    const style = document.createElement('style');
    let customFontStyleDeclaration = ``;
    let arabicFont: FontFamily = {
      id: 0,
      name: 'Roboto',
      isGoogleFont: true,
      createdAt: new Date(),
      updatedAt: new Date(),
      styles: [],
      organizations: [],
      organizationFontFamilies: [],
    };
    let englishFont: FontFamily = {
      id: 0,
      name: 'Roboto',
      isGoogleFont: true,
      createdAt: new Date(),
      updatedAt: new Date(),
      styles: [],
      organizations: [],
      organizationFontFamilies: [],
    };
    if (head && fonts?.length > 0) {
      fontFamilies.value.forEach((font: OrganizationFontFamily) => {
        // check if isGoogleFont is true
        if (font.fontFamily.isGoogleFont) {
          // add the google font link to the head tag
          prepareGoogleFontLink(font.fontFamily);
        } else if (
          !font.fontFamily.isGoogleFont &&
          font.fontFamily.styles.length > 0
        ) {
          // handle the custom font
          font.fontFamily.styles.forEach((style) => {
            const weight = () => {
              switch (style?.weight) {
                case 100:
                case 200:
                case 300:
                case 400:
                case 500:
                  return 'normal';
                case 600:
                  return 'medium';
                case 700:
                case 800:
                case 900:
                  return 'bold';
                default:
                  return 'normal';
              }
            };
            customFontStyleDeclaration += `
            @font-face {
              font-family: "${font.fontFamily.name}";
              src: url("${style?.media?.url}");
              font-weight: ${weight()};
                font-style: swap;
            }
            `;
          });
        }
        if (font.language == 'ar') {
          arabicFont = font.fontFamily;
        } else if (font.language == 'en') {
          englishFont = font.fontFamily;
        }
      });
      style.innerHTML = ` 
              ${customFontStyleDeclaration}       
            .ar {
                font-family: ${
                  '"' + (arabicFont as FontFamily).name + '"'
                } ,"Roboto", "sans-serif" !important;
            }
            .en {
                font-family: ${
                  '"' + (englishFont as FontFamily).name + '"'
                } ,"Roboto", "sans-serif" !important;
            }
            `;
      // append the new style tag
      head.appendChild(style);
    }
  };

  const changeFavoriteIcon = (icon: string) => {
    const iconTag = document.querySelector('link[rel="icon"]');
    if (iconTag) {
      iconTag.setAttribute('href', icon);
    }
  };

  return {
    changeThemeMainColors,
    changeThemeMainCardRadius,
    changeRootRadius,
    changeFontFamily,
    changeFavoriteIcon,
  };
});
