import type { RouteRecordRaw } from 'vue-router';
import { adminEnumRoutes } from './admin.enum.routes';

const userAdminRoute: Array<RouteRecordRaw> = [
  {
    path: adminEnumRoutes.users.path,
    children: [
      {
        path: '',
        name: adminEnumRoutes.users.name,
        meta: adminEnumRoutes.users.meta,
        component: () => import('@/modules/admin/pages/users/UsersPage.vue'),
      },
      {
        path: adminEnumRoutes.userView.path,
        name: adminEnumRoutes.userView.name,
        meta: adminEnumRoutes.userView.meta,
        component: () =>
          import('@/modules/admin/pages/users/BaseUserLayout.vue'),
        children: [
          {
            path: adminEnumRoutes.userDetails.path,
            name: adminEnumRoutes.userDetails.name,
            meta: adminEnumRoutes.userDetails.meta,
            component: () =>
              import('@/modules/admin/pages/users/UserDetails.vue'),
          },
          {
            path: adminEnumRoutes.createUser.path,
            name: adminEnumRoutes.createUser.name,
            meta: adminEnumRoutes.createUser.meta,
            component: () =>
              import('@/modules/admin/pages/users/UserDetails.vue'),
          },
          {
            path: adminEnumRoutes.userCourses.path,
            name: adminEnumRoutes.userCourses.name,
            meta: adminEnumRoutes.userCourses.meta,
            component: () =>
              import('@/modules/admin/pages/users/UserCourses.vue'),
          },
          {
            path: adminEnumRoutes.userExternalCourses.path,
            name: adminEnumRoutes.userExternalCourses.name,
            meta: adminEnumRoutes.userExternalCourses.meta,
            component: () =>
              import('@/modules/admin/pages/users/UserExternalCourses.vue'),
          },
          {
            path: adminEnumRoutes.userChecklist.path,
            name: adminEnumRoutes.userChecklist.name,
            meta: adminEnumRoutes.userChecklist.meta,
            component: () =>
              import('@/modules/admin/pages/users/UserChecklist.vue'),
          },
        ],
      },
    ],
  },
];
export default userAdminRoute;
