import { pinia } from '@/plugins';
import { useConfigurationStore } from '@/stores/admin/configuration.pinia';
import i18next from 'i18next';
import { storeToRefs } from 'pinia';
const {
  studentText,
  studentsText,
  instructorText,
  coursesText,
  moduleText,
  courseText,
  dashboardText,
  modulesText,
} = storeToRefs(useConfigurationStore(pinia));

type IUseMetaComposable = {
  useTitle: (title: string, dynamic?: boolean) => void;
};

/*
 * -----------------------------------------------------------------------
 * useMetaComposable
 * -----------------------------------------------------------------------
 * @Description:
 * Include all the meta tags and other meta related functions.
 *
 * -----------------------------------------------------------------------
 * @Usage:
 * import { useMetaComposable } from '@/composables';
 * const title = 'Home';
 * const { useTitle } = useMetaComposable();
 * useTitle(title);
 * -----------------------------------------------------------------------
 * @Returns:
 * useTitle - Changes the title of the page in the browser tab and in the history.
 * -----------------------------------------------------------------------
 */
const useMetaComposable = (): IUseMetaComposable => {
  const { t } = i18next;

  /*
   * -----------------------------------------------------------------------
   * useTitle
   * -----------------------------------------------------------------------
   * @Description:
   * Changes the title of the page in the browser tab and in the history.
   * -----------------------------------------------------------------------
   * @Params:
   * title - The title to be displayed.
   * dynamic - If true, the title will not be translated.
   * -----------------------------------------------------------------------
   * @Usage:
   * import { useMetaComposable } from '@/composables';
   * const title = 'Home';
   * const { useTitle } = useMetaComposable();
   * useTitle(title);
   * -----------------------------------------------------------------------
   * @Returns:
   * void
   * -----------------------------------------------------------------------
   */
  const useTitle = (title: string, dynamic: boolean = false) => {
    if (!dynamic) {
      document.title = t(title, {
        studentText: studentText.value,
        instructorText: instructorText.value,
        dashboardText: dashboardText.value,
        courseText: courseText.value,
        coursesText: coursesText.value,
        moduleText: moduleText.value,
        modulesText: modulesText.value,
        studentsText: studentsText.value,
      });
      return;
    }
    document.title = title;
  };
  return {
    useTitle,
  };
};

export default useMetaComposable;
