export const formFieldsApiHandler = {
  getFormFieldTypes: {
    method: "GET",
    path: () => `admin-dashboard/form-fields/types`
  },
  getAllFormFields: {
    method: "GET",
    path: () => `admin-dashboard/form-fields`,
    params: {} as {'skip'?: string | number, 'take'?: string | number}
  },
  createFormField: {
    method: "POST",
    path: () => `admin-dashboard/form-fields`
  }
};