import type { RouteRecordRaw } from 'vue-router';
import { adminEnumRoutes } from './admin.enum.routes';

const socialPlatformRoute: Array<RouteRecordRaw> = [
  {
    path: adminEnumRoutes.socialPlatform.path,
    children: [
      {
        path: '',
        name: adminEnumRoutes.socialPlatform.name,
        meta: adminEnumRoutes.socialPlatform.meta,
        component: () =>
          import(
            '@/modules/admin/pages/social-platform/SocialPlatformPage.vue'
          ),
      },
      {
        path: adminEnumRoutes.viewSocialPlatform.path,
        name: adminEnumRoutes.viewSocialPlatform.name,
        meta: adminEnumRoutes.viewSocialPlatform.meta,
        component: () =>
          import(
            '@/modules/admin/pages/social-platform/BaseSocialPlatform.vue'
          ),
        children: [
          {
            path: adminEnumRoutes.socialPlatformDetails.path,
            name: adminEnumRoutes.socialPlatformDetails.name,
            meta: adminEnumRoutes.socialPlatformDetails.meta,
            component: () =>
              import(
                '@/modules/admin/pages/social-platform/SocialPlatformDetails.vue'
              ),
          },
          {
            path: adminEnumRoutes.createSocialPlatform.path,
            name: adminEnumRoutes.createSocialPlatform.name,
            meta: adminEnumRoutes.createSocialPlatform.meta,
            component: () =>
              import(
                '@/modules/admin/pages/social-platform/SocialPlatformDetails.vue'
              ),
          },
          {
            path: adminEnumRoutes.viewSocialPlatformMembers.path,
            name: adminEnumRoutes.viewSocialPlatformMembers.name,
            meta: adminEnumRoutes.viewSocialPlatformMembers.meta,
            component: () =>
              import(
                '@/modules/admin/pages/social-platform/SoicalPlatformMembers.vue'
              ),
          },
        ],
      },
    ],
  },
];
export default socialPlatformRoute;
