export const learningGroupsApiHandler = {
  groupStudents: {
    getCoLearningGroupStudents: {
      method: "GET",
      path: (payload: { learningGroupId: string | number }) => `org-learning-groups-management/${payload.learningGroupId}/students`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'membersOnly'?: string | number, 'search'?: string | number, 'isVerified'?: string | number, 'requiresApproval'?: string | number}
    },
    updateCoLearningGroupStudents: {
      method: "PATCH",
      path: (payload: { learningGroupId: string | number }) => `org-learning-groups-management/${payload.learningGroupId}/students`
    }
  },
  groupCourses: {
    getCoLearningGroupCourses: {
      method: "GET",
      path: (payload: { learningGroupId: string | number }) => `org-learning-groups-management/${payload.learningGroupId}/courses`,
      params: {} as {'skip'?: string | number, 'take'?: string | number}
    }
  },
  createCoLearningGroup: {
    method: "POST",
    path: () => `org-learning-groups-management`
  },
  updateCoLearningGroup: {
    method: "PATCH",
    path: (payload: { learningGroupId: string | number }) => `org-learning-groups-management/${payload.learningGroupId}`
  },
  deleteCoLearningGroup: {
    method: "DELETE",
    path: (payload: { learningGroupId: string | number }) => `org-learning-groups-management/${payload.learningGroupId}`
  },
  getCoLearningGroups: {
    method: "GET",
    path: () => `org-learning-groups-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
  },
  getCoLearningGroupDetails: {
    method: "GET",
    path: (payload: { learningGroupId: string | number }) => `org-learning-groups-management/${payload.learningGroupId}`
  }
};