import { type IRoute } from '@/helpers/interfaces/auth/routes.enum.interface.ts';

export const sharedEnumRoutes: IRoute = {
  root: {
    path: '/',
    name: 'Root',
    meta: {
      requiresAuth: true,
    },
  },
  notAuth: {
    path: '/not-authorize',
    name: 'Not Authorized Page',
    meta: {
      title: 'auth.notAuthorizedTitle',
    },
  },
  notFound: {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
  },
};
