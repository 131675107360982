export const configurationsApiHandler = {
  fontFamilies: {
    getFontFamilies: {
      method: "GET",
      path: () => `admin-dashboard/font-families`,
      params: {} as {'organizationId'?: string | number}
    },
    createFontFamily: {
      method: "POST",
      path: () => `admin-dashboard/font-families`
    },
    getFontFamily: {
      method: "GET",
      path: (payload: { fontFamilyId: string | number }) => `admin-dashboard/font-families/${payload.fontFamilyId}`
    },
    deleteFontFamily: {
      method: "DELETE",
      path: (payload: { fontFamilyId: string | number }) => `admin-dashboard/font-families/${payload.fontFamilyId}`
    },
    putFontFamily: {
      method: "PUT",
      path: (payload: { fontFamilyId: string | number }) => `admin-dashboard/font-families/${payload.fontFamilyId}`
    }
  },
  terminology: {
    categories: {
      getAllTerminologyCategories: {
        method: "GET",
        path: () => `admin-dashboard/terminology/categories`,
        params: {} as {'skip'?: string | number, 'take'?: string | number}
      },
      createTerminologyCategory: {
        method: "POST",
        path: () => `admin-dashboard/terminology/categories`
      },
      updateTerminologyCategory: {
        method: "PATCH",
        path: (payload: { terminologyCategoryId: string | number }) => `admin-dashboard/terminology/categories/${payload.terminologyCategoryId}`
      },
      deleteTerminologyCategory: {
        method: "DELETE",
        path: (payload: { terminologyCategoryId: string | number }) => `admin-dashboard/terminology/categories/${payload.terminologyCategoryId}`
      }
    },
    getAllTerms: {
      method: "GET",
      path: () => `admin-dashboard/terminology`,
      params: {} as {'skip'?: string | number, 'take'?: string | number, 'termKeyCategoryId'?: string | number}
    },
    createTermKey: {
      method: "POST",
      path: () => `admin-dashboard/terminology`
    },
    updateTermKey: {
      method: "PATCH",
      path: (payload: { terminologyId: string | number }) => `admin-dashboard/terminology/${payload.terminologyId}`
    },
    deleteTermKey: {
      method: "DELETE",
      path: (payload: { terminologyId: string | number }) => `admin-dashboard/terminology/${payload.terminologyId}`
    },
    getTermKey: {
      method: "GET",
      path: (payload: { terminologyId: string | number }) => `admin-dashboard/terminology/${payload.terminologyId}`
    }
  },
  getColorKeys: {
    method: "GET",
    path: () => `admin-dashboard/colors`
  },
  getConfigurationKeys: {
    method: "GET",
    path: () => `admin-dashboard/configurations`,
    params: {} as {'skip'?: string | number, 'take'?: string | number}
  }
};