/*
 * Vuetify Documentation: https://vuetifyjs.com/en
 * first you have to make sure that your change not effecting the whole application.
 * if is not a general case you can override the component in the component itself.
 * be aware that your change will affect all the components that this component is used in.
 * if you want to override the component in the component itself you can use the following syntax:
 * <v-text-field :density="dense" />
 * <v-text-field :density="dense" :variant="variant" />
 * <v-text-field :density="dense" :variant="variant" :color="color" />
 * <v-text-field :density="dense" :variant="variant" :color="color" :bgColor="bgColor" />
 */

export const DefaultsVuetifyComponents = {
  VBtn: {
    rounded: 'xs',
  },
  VTextField: {
    density: 'compact',
    variant: 'outlined',
    color: 'filedBorder',
    bgColor: 'white',
    baseColor: 'gray',
  },
  VSelect: {
    density: 'compact',
    variant: 'outlined',
    color: 'filedBorder',
    baseColor: 'gray',
    bgColor: 'white',
  },
  VCombobox: {
    density: 'compact',
    variant: 'outlined',
    color: 'filedBorder',
    baseColor: 'gray',
    bgColor: 'white',
  },
  VAutocomplete: {
    density: 'compact',
    variant: 'outlined',
    color: 'filedBorder',
    baseColor: 'gray',
    bgColor: 'white',
  },
  VTextarea: {
    density: 'compact',
    variant: 'outlined',
    color: 'filedBorder',
    baseColor: 'gray',
    bgColor: 'white',
  },
  VFileInput: {
    density: 'compact',
    variant: 'outlined',
    color: 'filedBorder',
    baseColor: 'gray',
    bgColor: 'white',
  },
  VBtnPrimary: {
    class: 'text-capitalize  ',
    height: '35px',
    color: 'primary',
  },
  VBtnText: {
    variant: 'text',
    class: 'text-capitalize text-subtitle-2',
    color: 'primary',
    rounded: 0,
    height: '35px',
  },
  VLabel: {
    /*
     * bold labels is the default.
     */
    class: 'text-main font-weight-bold opacity-10',
  },
  VTable: {
    hover: true,
    class: 'rounded-lg text-outline text-subtitle-2 table-container',
  },
  VTabs: {
    alignTabs: 'start',
    sliderColor: 'primary',
  },
  VTab: {
    class: 'text-capitalize text-gray',
  },
  VSwitch: {
    centerAffix: true,
    density: 'compact',
    color: 'primary',
    hideDetails: true,
  },
  VBtnPlain: {
    variant: 'text',
    class: 'text-capitalize text-subtitle-2',
    color: 'primary',
    height: '35px',
  },
  VCheckbox: {
    density: 'compact',
    color: 'primary',
  },
  VRadioGroup: {
    color: 'primary',
  },
  VForm: {
    /*
     * The validation prop allows you to control when the component
     */
    validateOn: 'submit lazy',
  },
};
