export const certificateTamplateApiHandler = {
  updateCertificateTemplate: {
    method: "PUT",
    path: () => `organizations-dashboard/certificate-template`
  },
  getCertificateTemplate: {
    method: "GET",
    path: () => `organizations-dashboard/certificate-template`
  },
  migrateFromRemoteDefault: {
    method: "POST",
    path: () => `migration/certificate-templates`,
    params: {} as {'all'?: string | number}
  }
};