export const approvalsApiHandler = {
  getCourseApprovalRequests: {
    method: "GET",
    path: () => `org-approval-requests`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'courseIds'?: (string | number)[], 'statuses'?: (string | number)[]}
  },
  approveCourseRequest: {
    method: "PATCH",
    path: (payload: { approvalId: string | number }) => `org-approval-requests/${payload.approvalId}/approve`
  },
  rejectCourseRequest: {
    method: "PATCH",
    path: (payload: { approvalId: string | number }) => `org-approval-requests/${payload.approvalId}/reject`
  },
  getCourseCertificates: {
    method: "GET",
    path: (payload: { courseId: string | number }) => `org-courses-management/${payload.courseId}/certificates`
  }
};