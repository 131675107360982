export const permissionSetsApiHandler = {
  permissionsSystemCopy: {
    permissionContexts: {
      getAllPermissionContexts: {
        method: "GET",
        path: () => `permission-contexts`
      }
    },
    permissionCategories: {
      getAllPermissionCategories: {
        method: "GET",
        path: () => `permission-categories`
      }
    },
    permissionSets: {
      getAllPermissionSets: {
        method: "GET",
        path: () => `permission-sets`,
        params: {} as {'skip'?: string | number, 'take'?: string | number, 'contexts'?: (string | number)[], 'organizationIds'?: (string | number)[]}
      },
      getSinglePermissionSet: {
        method: "GET",
        path: (payload: { permissionSetId: string | number }) => `permission-sets/${payload.permissionSetId}`
      },
      updatePermissionSet: {
        method: "PATCH",
        path: (payload: { permissionSetId: string | number }) => `permission-sets/${payload.permissionSetId}`
      }
    },
    permissions: {
      getAllPermissions: {
        method: "GET",
        path: () => `permissions`,
        params: {} as {'skip'?: string | number, 'take'?: string | number, 'contexts'?: (string | number)[], 'categories'?: (string | number)[], 'contextSlugs'?: (string | number)[]}
      }
    },
    testingPermissions: {
      newRequest: {
        method: "GET",
        path: (payload: { slug: string | number }) => `testing-permissions/${payload.slug}`
      }
    }
  },
  getAllOrgPermissionSets: {
    method: "GET",
    path: () => `org-permissions-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'contextSlugs'?: (string | number)[]}
  },
  createOrgPermissionSet: {
    method: "POST",
    path: () => `org-permissions-management`
  },
  getSingleOrgPermissionSet: {
    method: "GET",
    path: (payload: { orgPermissionSetId: string | number }) => `org-permissions-management/${payload.orgPermissionSetId}`
  },
  updateOrgPermissionSet: {
    method: "PATCH",
    path: (payload: { orgPermissionSetId: string | number }) => `org-permissions-management/${payload.orgPermissionSetId}`
  },
  deleteOrgPermissionSet: {
    method: "DELETE",
    path: (payload: { orgPermissionSetId: string | number }) => `org-permissions-management/${payload.orgPermissionSetId}`
  }
};