import type { RouteRecordRaw } from 'vue-router';
import { superAdminEnumRoutes } from './super-admin.enum.routes';

const superAdminRoutes: Array<RouteRecordRaw> = [
  {
    path: superAdminEnumRoutes.root.path,
    name: superAdminEnumRoutes.root.name,
    component: () => import('@/layouts/super-admin/BaseSuperAdminLayout.vue'),
    meta: superAdminEnumRoutes.root.meta,
    redirect: superAdminEnumRoutes.organizations.path,
    children: [
      {
        path: superAdminEnumRoutes.organizations.path,
        name: superAdminEnumRoutes.organizations.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/OrganizationsList.vue`
          ),
        meta: superAdminEnumRoutes.organizations.meta,
      },
      {
        path: superAdminEnumRoutes.createOrganization.path,
        name: superAdminEnumRoutes.createOrganization.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/CreateNewOrganizations.vue`
          ),
        meta: superAdminEnumRoutes.createOrganization.meta,
      },

      /* -------------- Form Fields ---------------- */ {
        path: superAdminEnumRoutes.formFields.path,
        name: superAdminEnumRoutes.formFields.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/form-fields/SuperAdminFormFieldsList.vue`
          ),
        meta: superAdminEnumRoutes.formFields.meta,
      },
      {
        path: superAdminEnumRoutes.createFormField.path,
        name: superAdminEnumRoutes.createFormField.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/form-fields/SuperAdminCreateFormField.vue`
          ),
      },

      /* -------------- External Courses ---------------- */
      {
        path: superAdminEnumRoutes.externalCourseLayout.path,
        name: superAdminEnumRoutes.externalCourseLayout.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/external-courses/SuperAdminExternalCoursesLayout.vue`
          ),
        meta: superAdminEnumRoutes.externalCourseLayout.meta,
        children: [
          {
            path: '',
            name: superAdminEnumRoutes.allExternalCourses.name,
            component: () =>
              import(
                `@/modules/super-admin/pages/external-courses/SuperAdminExternalCourses.vue`
              ),
            meta: superAdminEnumRoutes.allExternalCourses.meta,
          },
          {
            path: superAdminEnumRoutes.pendingEnrollmentExternalCourses.path,
            name: superAdminEnumRoutes.pendingEnrollmentExternalCourses.name,
            component: () =>
              import(
                `@/modules/super-admin/pages/external-courses/SuperAdminPendingEnrollment.vue`
              ),
            meta: superAdminEnumRoutes.pendingEnrollmentExternalCourses.meta,
          },
        ],
      },
      {
        path: superAdminEnumRoutes.singleExternalCourseLayout.path,
        name: superAdminEnumRoutes.singleExternalCourseLayout.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/external-courses/courses/SingleExternalCourseLayout.vue`
          ),
        meta: superAdminEnumRoutes.singleExternalCourseLayout.meta,
        children: [
          {
            path: '',
            name: superAdminEnumRoutes.singleExternalCourseOverview.name,
            component: () =>
              import(
                `@/modules/super-admin/pages/external-courses/courses/SingleExternalCourseOverview.vue`
              ),
            meta: superAdminEnumRoutes.singleExternalCourseOverview.meta,
            children: [
              {
                path: '',
                name: superAdminEnumRoutes.singleExternalCourseDetails.name,
                component: () =>
                  import(
                    `@/modules/super-admin/pages/external-courses/courses/SingleExternalCourseDetail.vue`
                  ),
                meta: superAdminEnumRoutes.singleExternalCourseDetails.meta,
              },
              {
                path: superAdminEnumRoutes.singleExternalCourseOrganizations
                  .path,
                name: superAdminEnumRoutes.singleExternalCourseOrganizations
                  .name,
                component: () =>
                  import(
                    `@/modules/super-admin/pages/external-courses/courses/SingleExternalCourseOrganizations.vue`
                  ),
                meta: superAdminEnumRoutes.singleExternalCourseOrganizations
                  .meta,
              },
              {
                path: superAdminEnumRoutes.singleExternalCourseEnrollee.path,
                name: superAdminEnumRoutes.singleExternalCourseEnrollee.name,
                component: () =>
                  import(
                    `@/modules/super-admin/pages/external-courses/courses/singleExternalCourseEnrollee.vue`
                  ),
                meta: superAdminEnumRoutes.singleExternalCourseEnrollee.meta,
              },
            ],
          },

          {
            path: superAdminEnumRoutes.createOrganizationInExternalCourse.path,
            name: superAdminEnumRoutes.createOrganizationInExternalCourse.name,
            component: () =>
              import(
                `@/modules/super-admin/pages/external-courses/courses/CreateOrEditOrganizationInExternalCourse.vue`
              ),
            meta: superAdminEnumRoutes.createOrganizationInExternalCourse.meta,
          },
          {
            path: superAdminEnumRoutes.editOrganizationInExternalCourse.path,
            name: superAdminEnumRoutes.editOrganizationInExternalCourse.name,
            component: () =>
              import(
                `@/modules/super-admin/pages/external-courses/courses/CreateOrEditOrganizationInExternalCourse.vue`
              ),
            meta: superAdminEnumRoutes.editOrganizationInExternalCourse.meta,
          },
        ],
      },

      /* -------------- Courses ---------------- */
      {
        path: superAdminEnumRoutes.courses.path,
        name: superAdminEnumRoutes.courses.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/courses/SuperAdminCoursesList.vue`
          ),
        meta: superAdminEnumRoutes.courses.meta,
      },
      {
        path: superAdminEnumRoutes.course.path,
        name: superAdminEnumRoutes.course.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/courses/SuperAdminCourseOrganizationList.vue`
          ),
        meta: superAdminEnumRoutes.course.meta,
      },
      {
        name: superAdminEnumRoutes.addCourseToOrganization.name,
        path: superAdminEnumRoutes.addCourseToOrganization.path,
        component: () =>
          import(
            `@/modules/super-admin/pages/courses/CoursesCreateEditConnectivityHubTargetOrganizations.vue`
          ),
        meta: superAdminEnumRoutes.addCourseToOrganization.meta,
      },
      {
        name: superAdminEnumRoutes.editCourseOrganization.name,
        path: superAdminEnumRoutes.editCourseOrganization.path,
        component: () =>
          import(
            `@/modules/super-admin/pages/courses/CoursesCreateEditConnectivityHubTargetOrganizations.vue`
          ),
        meta: superAdminEnumRoutes.editCourseOrganization.meta,
      },

      /* -------------- Organization Fonts Families ---------------- */ {
        path: superAdminEnumRoutes.fontFamilies.path,
        name: superAdminEnumRoutes.fontFamilies.name,
        component: () =>
          import(`@/modules/super-admin/pages/fonts/FontFamiliesList.vue`),
        meta: superAdminEnumRoutes.fontFamilies.meta,
      },
      {
        path: superAdminEnumRoutes.createFontFamily.path,
        name: superAdminEnumRoutes.createFontFamily.name,
        component: () =>
          import(`@/modules/super-admin/pages/fonts/FontFamiliesCreate.vue`),
        meta: superAdminEnumRoutes.createFontFamily.meta,
      },
      {
        path: superAdminEnumRoutes.editFontFamily.path,
        name: superAdminEnumRoutes.editFontFamily.name,
        component: () =>
          import(`@/modules/super-admin/pages/fonts/FontFamiliesCreate.vue`),
        meta: superAdminEnumRoutes.editFontFamily.meta,
      },
      {
        path: superAdminEnumRoutes.fontFamily.path,
        name: superAdminEnumRoutes.fontFamily.name,
        component: () =>
          import(`@/modules/super-admin/pages/fonts/FontFamiliesByID.vue`),
        meta: superAdminEnumRoutes.fontFamily.meta,
      } /** >>>>>>>>>>>>>>> Terminology <<<<<<<<<<<<<<< **/,
      {
        path: superAdminEnumRoutes.terminology.path,
        name: superAdminEnumRoutes.terminology.name,
        meta: superAdminEnumRoutes.terminology.meta,
        component: () =>
          import(
            `@/modules/super-admin/pages/terminology/TerminologyCategories.vue`
          ),
      },
      {
        path: superAdminEnumRoutes.terms.path,
        name: superAdminEnumRoutes.terms.name,
        meta: superAdminEnumRoutes.terms.meta,
        component: () =>
          import(`@/modules/super-admin/pages/terminology/TermsPage.vue`),
      },
      {
        path: superAdminEnumRoutes.singleTerm.path,
        name: superAdminEnumRoutes.singleTerm.name,
        meta: superAdminEnumRoutes.singleTerm.meta,
        component: () =>
          import(
            `@/modules/super-admin/pages/terminology/layout/BaseTermLayout.vue`
          ),
        children: [
          {
            path: superAdminEnumRoutes.termDetail.path,
            name: superAdminEnumRoutes.termDetail.name,
            meta: superAdminEnumRoutes.termDetail.meta,
            component: () =>
              import(`@/modules/super-admin/pages/terminology/TermDetail.vue`),
          },
          {
            path: superAdminEnumRoutes.createTerm.path,
            name: superAdminEnumRoutes.createTerm.name,
            meta: superAdminEnumRoutes.createTerm.meta,
            component: () =>
              import(`@/modules/super-admin/pages/terminology/TermDetail.vue`),
          },
        ],
      },
    ],
  } /* -------------- Organization Mini Dashboard base ---------------- */,
  {
    path: superAdminEnumRoutes.organization.path,
    name: superAdminEnumRoutes.organization.name,
    component: () =>
      import(
        `@/modules/super-admin/pages/organizations/layout/OrganizationInnerLayout.vue`
      ),
    redirect: superAdminEnumRoutes.organizationOverview.path,
    children: [
      {
        path: superAdminEnumRoutes.organizationOverview.path,
        name: superAdminEnumRoutes.organizationOverview.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/settings/OrganizationOverview.vue`
          ),
        meta: superAdminEnumRoutes.organizationOverview.meta,
      },
      {
        path: superAdminEnumRoutes.organizationConfiguration.path,
        name: superAdminEnumRoutes.organizationConfiguration.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/settings/OrganizationConfigurationList.vue`
          ),
        meta: superAdminEnumRoutes.organizationConfiguration.meta,
      },
      {
        path: superAdminEnumRoutes.organizationColors.path,
        name: superAdminEnumRoutes.organizationColors.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/colors/OrganizationColorsList.vue`
          ),
        meta: superAdminEnumRoutes.organizationColors.meta,
      },
      /* -------------- Organization Register ---------------- */ {
        path: superAdminEnumRoutes.organizationRegisterFromList.path,
        name: superAdminEnumRoutes.organizationRegisterFromList.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/registration/RegisterFormFieldsList.vue`
          ),
        meta: superAdminEnumRoutes.organizationRegisterFromList.meta,
      },
      {
        path: superAdminEnumRoutes.organizationRegisterFromCreate.path,
        name: superAdminEnumRoutes.organizationRegisterFromCreate.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/registration/RegisterFormFieldCreate.vue`
          ),
        meta: superAdminEnumRoutes.organizationRegisterFromCreate.meta,
      },
      {
        path: superAdminEnumRoutes.createOrganizationDynamicPagesSuperAdmin
          .path,
        name: superAdminEnumRoutes.createOrganizationDynamicPagesSuperAdmin
          .name,
        component: () =>
          import(
            '@/modules/super-admin/pages/organizations/dynamicPage/CustomConfigDetailsPage.vue'
          ),
      },
      {
        path: superAdminEnumRoutes.viewOrganizationDynamicPagesSuperAdmin.path,
        name: superAdminEnumRoutes.viewOrganizationDynamicPagesSuperAdmin.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/dynamicPage/CustomConfigDetailsPage.vue`
          ),
        meta: superAdminEnumRoutes.createOrganization.meta,
      },
      {
        path: superAdminEnumRoutes.organizationRegisterFromEdit.path,
        name: superAdminEnumRoutes.organizationRegisterFromEdit.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/registration/RegisterFormFieldCreate.vue`
          ),
        meta: superAdminEnumRoutes.organizationRegisterFromEdit.meta,
      },
      /* -------------- Organization Fonts Families ---------------- */ {
        path: superAdminEnumRoutes.organizationFonts.path,
        name: superAdminEnumRoutes.organizationFonts.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/fonts/OrganizationFontsList.vue`
          ),
        meta: superAdminEnumRoutes.organizationFonts.meta,
      },
      {
        path: superAdminEnumRoutes.organizationCertificate.path,
        name: superAdminEnumRoutes.organizationCertificate.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/certificate/OrganzationCertificate.vue`
          ),
        meta: superAdminEnumRoutes.organizationCourse.meta,
      },
      /* -------------- Organization  Courses ---------------- */ {
        path: superAdminEnumRoutes.organizationCourses.path,
        name: superAdminEnumRoutes.organizationCourses.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/courses/SuperAdminOrganizationCoursesFiltered.vue`
          ),
        meta: superAdminEnumRoutes.organizationCourses.meta,
      },
      {
        path: superAdminEnumRoutes.organizationCourse.path,
        name: superAdminEnumRoutes.organizationCourse.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/courses/SuperAdminCourseLayout.vue`
          ),
        meta: superAdminEnumRoutes.organizationCourse.meta,
        redirect: superAdminEnumRoutes.organizationCourseDetails.path,
        children: [
          {
            path: superAdminEnumRoutes.organizationCourseDetails.path,
            name: superAdminEnumRoutes.organizationCourseDetails.name,
            component: () =>
              import(
                `@/modules/super-admin/pages/courses/SuperAdminOrganizationCourseDetails.vue`
              ),
            meta: superAdminEnumRoutes.organizationCourseDetails.meta,
          },
          {
            path: superAdminEnumRoutes.organizationCourseOrganizationList.path,
            name: superAdminEnumRoutes.organizationCourseOrganizationList.name,
            component: () =>
              import(
                `@/modules/super-admin/pages/courses/SuperAdminCourseOrganizationList.vue`
              ),
            meta: superAdminEnumRoutes.organizationCourseOrganizationList.meta,
          },
          {
            path: superAdminEnumRoutes.organizationCourseCertificate.path,
            name: superAdminEnumRoutes.organizationCourseCertificate.name,
            component: () =>
              import(
                `@/modules/super-admin/pages/courses/SuperAdminCourseCertificate.vue`
              ),
            meta: superAdminEnumRoutes.organizationCourseCertificate.meta,
          },
        ],
      },
      /* -------------- Organization External Courses ---------------- */
      {
        path: superAdminEnumRoutes.organizationExternalCourses.path,
        name: superAdminEnumRoutes.organizationExternalCourses.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/courses/SuperAdminOrganizationExternalCourses.vue`
          ),
        meta: superAdminEnumRoutes.organizationExternalCourses.meta,
      },
      {
        path: superAdminEnumRoutes.organizationExternalCourse.path,
        name: superAdminEnumRoutes.organizationExternalCourse.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/external-courses/organization/SuperAdminOrganizationExternalCourseLayout.vue`
          ),
        meta: superAdminEnumRoutes.organizationExternalCourse.meta,
      },

      /* -------------- Organization  Terms and Media ---------------- */ {
        path: superAdminEnumRoutes.organizationTermsSuperAdmin.path,
        name: superAdminEnumRoutes.organizationTermsSuperAdmin.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/terminology/SuperAdminOrganizationTerminology.vue`
          ),
        meta: superAdminEnumRoutes.organizationTermsSuperAdmin.meta,
      },
      {
        path: superAdminEnumRoutes.organizationMediaSuperAdmin.path,
        name: superAdminEnumRoutes.organizationMediaSuperAdmin.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/configuration/terminology/SuperAdminOrganizationMediaConfig.vue`
          ),
        meta: superAdminEnumRoutes.organizationMediaSuperAdmin.meta,
      },
      {
        path: superAdminEnumRoutes.organizationDynamicPagesSuperAdmin.path,
        name: superAdminEnumRoutes.organizationDynamicPagesSuperAdmin.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/dynamicPage/SuperAdminOrganizationDynamicPagesPage.vue`
          ),
        meta: superAdminEnumRoutes.organizationDynamicPagesSuperAdmin.meta,
      },
      {
        path: superAdminEnumRoutes.organizationPassingCriteria.path,
        name: superAdminEnumRoutes.organizationPassingCriteria.name,
        component: () =>
          import(
            `@/modules/super-admin/pages/organizations/passing-criteria/OrganizationPassingCriteria.vue`
          ),
        meta: superAdminEnumRoutes.organizationPassingCriteria.meta,
      },
    ],
  },
];
export default superAdminRoutes;
