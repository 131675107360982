import { type IRoute } from '@/helpers/interfaces/auth/routes.enum.interface.ts';

const InstructorEnumOnlineLectureRoutes: IRoute = {
  createOnlineLecture: {
    path: '/instructor/courses/:courseId/online-lectures/create',
    name: 'Create Online Lectures',
    meta: {
      title: 'onlineLectures.createOnlineLectures',
    },
  },
  editOnlineLecture: {
    path: '/instructor/courses/:courseId/online-lectures/:onlineLectureId/edit',
    name: 'Edit Online Lectures',
    meta: {
      title: 'onlineLectures.editOnlineLectures',
    },
  },
};

const InstructorEnumOnCampusLectureRoutes: IRoute = {
  createOnCampusLecture: {
    path: '/instructor/courses/:courseId/on-campus-lectures/create',
    name: 'Create OnCampus Lectures',
    meta: {
      title: 'onCampusLecture.createOnCampusLectures',
    },
  },
  editOnCampusLecture: {
    path: '/instructor/courses/:courseId/on-campus-lectures/:onCampusLectureId/edit',
    name: 'Edit OnCampus Lectures',
    meta: {
      title: 'onCampusLecture.editOnCampusLectures',
    },
  },
};

const InstructorEnumPollsRoutes: IRoute = {
  createPoll: {
    path: '/instructor/courses/:courseId/polls/create',
    name: 'Create Poll',
    meta: {
      title: 'pollSurvey.createPoll',
    },
  },
  pollView: {
    path: '/instructor/courses/:courseId/polls',
    name: 'Poll View',
    meta: {
      title: 'pollSurvey.pollView',
    },
  },
  pollDetails: {
    path: '/instructor/courses/:courseId/polls/:surveyId/details',
    name: 'Poll Details',
    meta: {
      title: 'pollSurvey.pollDetails',
    },
  },
  pollQuestions: {
    path: '/instructor/courses/:courseId/polls/:surveyId/questions',
    name: 'Poll Questions',
    meta: {
      title: 'pollSurvey.pollQuestions',
    },
  },
  pollConfigurations: {
    path: '/instructor/courses/:courseId/polls/:surveyId/configurations',
    name: 'Poll Configurations',
    meta: {
      title: 'pollSurvey.pollConfigurations',
    },
  },
  pollSubmissions: {
    path: '/instructor/courses/:courseId/polls/:surveyId/submissions',
    name: 'Poll Submissions',
    meta: {
      title: 'pollSurvey.pollSubmissions',
    },
  },
};

const InstructorEnumSurveysRoutes: IRoute = {
  createSurvey: {
    path: '/instructor/courses/:courseId/surveys/create',
    name: 'Create Survey',
    meta: {
      title: 'pollSurvey.createSurvey',
    },
  },
  surveyView: {
    path: '/instructor/courses/:courseId/surveys',
    name: 'Survey View',
    meta: {
      title: 'pollSurvey.surveyView',
    },
  },
  surveyDetails: {
    path: '/instructor/courses/:courseId/surveys/:surveyId/details',
    name: 'Survey Details',
    meta: {
      title: 'pollSurvey.surveyDetails',
    },
  },
  surveyQuestions: {
    path: '/instructor/courses/:courseId/surveys/:surveyId/questions',
    name: 'Survey Questions',
    meta: {
      title: 'pollSurvey.surveyQuestions',
    },
  },
  surveyConfigurations: {
    path: '/instructor/courses/:courseId/surveys/:surveyId/configurations',
    name: 'Survey Configurations',
    meta: {
      title: 'pollSurvey.surveyConfigurations',
    },
  },
  surveySubmissions: {
    path: '/instructor/courses/:courseId/surveys/:surveyId/submissions',
    name: 'Survey Submissions',
    meta: {
      title: 'pollSurvey.surveySubmissions',
    },
  },
};

const InstructorEnumQuizzesRoutes: IRoute = {
  quizView: {
    path: '/instructor/courses/:courseId/quizzes',
    name: 'Quiz View',
    meta: {
      title: 'quiz.quizView',
    },
  },
  quizDetails: {
    path: '/instructor/courses/:courseId/quizzes/:subsectionExamId/details',
    name: 'Quiz Details',
    meta: {
      title: 'quiz.quizDetails',
    },
  },
  quizQuestions: {
    path: '/instructor/courses/:courseId/quizzes/:subsectionExamId/questions',
    name: 'Quiz Questions',
    meta: {
      title: 'quiz.quizQuestions',
    },
  },
  quizConfigurations: {
    path: '/instructor/courses/:courseId/quizzes/:subsectionExamId/configurations',
    name: 'Quiz Configurations',
    meta: {
      title: 'quiz.quizConfigurations',
    },
  },
  quizSubmissions: {
    path: '/instructor/courses/:courseId/quizzes/:subsectionExamId/submissions',
    name: 'Quiz Submissions',
    meta: {
      title: 'quiz.quizSubmissions',
    },
  },
  createQuizzes: {
    path: '/instructor/courses/:courseId/quizzes/create',
    name: 'Create quizzes',
    meta: {
      title: 'quiz.createQuizzes',
    },
  },
};

const InstructorEnumAssignmentRoutes: IRoute = {
  assignmentView: {
    path: '/instructor/courses/:courseId/assignments',
    name: 'Assignment View',
    meta: {
      title: 'assignment.assignmentView',
    },
  },
  createAssignment: {
    path: '/instructor/courses/:courseId/assignments/create',
    name: 'Create Assignment',
    meta: {
      title: 'assignment.createAssignment',
    },
  },
  assignmentDetails: {
    path: '/instructor/courses/:courseId/assignments/:assignmentId/details',
    name: 'Assignments Details',
    meta: {
      title: 'assignment.assignmentDetails',
    },
  },
  assignmentConfiguration: {
    path: '/instructor/courses/:courseId/assignments/:assignmentId/configuration',
    name: 'Assignment Configuration',
    meta: {
      title: 'assignment.assignmentConfiguration',
    },
  },
  assignmentSubmissions: {
    path: '/instructor/courses/:courseId/assignments/:assignmentId/submissions',
    name: 'Assignment Submissions',
    meta: {
      title: 'assignment.assignmentSubmissions',
    },
  },
};

const InstructorEnumDiscussionBoardsRoutes: IRoute = {
  discussionBoardView: {
    path: '/instructor/courses/:courseId/discussions-boards',
    name: 'Discussion Board View',
    meta: {
      title: 'discussion.discussionBoardView',
    },
  },
  createDiscussionBoard: {
    path: '/instructor/courses/:courseId/discussions-boards/create',
    name: 'Create Discussions Boards',
    meta: {
      title: 'discussion.createDiscussionsBoards',
    },
  },
  discussionBoardDetails: {
    path: '/instructor/courses/:courseId/discussions-boards/:discussionId/details',
    name: 'Discussion Board Details',
    meta: {
      title: 'discussion.discussionBoardDetails',
    },
  },
  discussionBoardConfiguration: {
    path: '/instructor/courses/:courseId/discussions-boards/:discussionId/configuration',
    name: 'Discussion Board Configuration',
    meta: {
      title: 'discussion.discussionBoardConfiguration',
    },
  },
  discussionBoardComments: {
    path: '/instructor/courses/:courseId/discussions-boards/:discussionId/comments',
    name: 'Discussion Board Comments',
    meta: {
      title: 'discussion.discussionBoardComments',
    },
  },
};

const InstructorEnumGradesRoutes: IRoute = {
  createGradeItem: {
    path: '/instructor/courses/:courseId/grades/create',
    name: 'Create Grade Item',
    meta: {
      title: 'grades.createGrades',
    },
  },
  editGradeItem: {
    path: '/instructor/courses/:courseId/grades/:gradeItemId/edit',
    name: 'Edit Grade Item',
    meta: {
      title: 'grades.editGrades',
    },
  },
  viewSubmissionGrades: {
    path: '/instructor/courses/:courseId/grades/:gradeItemId/students',
    name: 'Student Grades',
    meta: {
      title: 'grades.studentGrades',
    },
  },
  viewStudentSubmission: {
    path: '/instructor/courses/:courseId/grades/:gradeItemId/student/:studentId',
    name: 'View Student Submission',
    meta: {
      title: 'grades.viewStudentSubmission',
    },
  },
  viewGroupSubmission: {
    path: '/instructor/courses/:courseId/grades/:gradeItemId/student/:studentId/group/:groupId',
    name: 'View Group Submission',
    meta: {
      title: 'grades.viewGroupSubmission',
    },
  },
};

const InstructorEnumAttendanceRoutes: IRoute = {
  attendanceView: {
    path: '/instructor/courses/:courseId/attendance',
    name: 'attendance View',
    meta: {
      title: 'attendance.attendanceView',
    },
  },
  createAttendance: {
    path: '/instructor/courses/:courseId/attendance/create',
    name: 'Create Attendance',
    meta: {
      title: 'attendanceInstructor.createAttendance',
    },
  },
  editAttendance: {
    path: '/instructor/courses/:courseId/attendance/:attendanceId/edit',
    name: 'Edit Attendance',
    meta: {
      title: 'attendanceInstructor.editAttendance',
    },
  },
};
const InstructorEnumRubricsRoutes: IRoute = {
  createRubric: {
    path: '/instructor/courses/:courseId/rubrics/create',
    name: 'Create rubrics',
    meta: {
      title: 'rubricsInstructor.createRubrics',
    },
  },
  editRubric: {
    path: '/instructor/courses/:courseId/rubrics/:rubricId/edit',
    name: 'Edit rubrics',
    meta: {
      title: 'rubricsInstructor.editRubrics',
    },
  },
};

const InstructorEnumAnnouncementsRoutes: IRoute = {
  createAnnouncements: {
    path: '/instructor/courses/:courseId/announcements/create',
    name: 'Create Announcements',
    meta: {
      title: 'announcements.createAnnouncements',
    },
  },
  editAnnouncements: {
    path: '/instructor/courses/:courseId/announcements/:announcementId/edit',
    name: 'Edit Announcements',
    meta: {
      title: 'announcements.editAnnouncements',
    },
  },
};

const InstructorEnumReportsRoutes: IRoute = {
  gradesReport: {
    path: '/instructor/courses/:courseId/reports/grades',
    name: 'Grades Report',
    meta: {
      title: 'reports.gradesReport',
    },
  },
  attendanceReport: {
    path: '/instructor/courses/:courseId/reports/attendance',
    name: 'Attendance Report',
    meta: {
      title: 'reports.attendanceReport',
    },
  },
  certificateReport: {
    path: '/instructor/courses/:courseId/reports/certificate',
    name: 'Certificate Report',
    meta: {
      title: 'reports.certificateReport',
    },
  },
};

const InstructorEnumCreateSubsectionRoutes: IRoute = {
  editSubsection: {
    path: '/instructor/courses/:courseId/content/section/:sectionId/subsection/:subsectionId/edit',
    name: 'Edit Subsection',
    meta: {
      title: 'content.editSubsection',
    },
  },
  createSubsection: {
    path: '/instructor/courses/:courseId/content/section/:sectionId/subsection/create',
    name: 'Create Subsection',
    meta: {
      title: 'content.createSubsection',
    },
  },
  scormView: {
    path: '/instructor/courses/:courseId/content/section/:sectionId/subsection/:subsectionId/scorm',
    name: 'Scorm View',
    meta: {
      title: 'scorm.scormView',
    },
  },
  createScorm: {
    path: '/instructor/courses/:courseId/content/section/:sectionId/subsection/:subsectionId/scorm/create',
    name: 'Create Scorm',
    meta: {
      title: 'create.createScorm',
    },
  },
  scormDetails: {
    path: '/instructor/courses/:courseId/content/section/:sectionId/subsection/:subsectionId/scorm/details',
    name: 'Scorm details',
    meta: {
      title: 'scorm.scormDetails',
    },
  },
  scormUserDetails: {
    path: '/instructor/courses/:courseId/content/section/:sectionId/subsection/:subsectionId/scorm/users',
    name: 'Users Progress',
    meta: {
      title: 'sorm.usersProgress',
    },
  },
};
export const instructorEnumRoutes: IRoute = {
  root: {
    path: '/instructor',
    name: 'Instructor',
    meta: {
      title: 'instructor.instructorTabTitle',
      requiresAuth: true,
      isInstructor: true,
    },
  },
  instructorAllCourses: {
    path: '/instructor/courses',
    name: 'Instructor All Courses',
    meta: {
      title: 'instructor.instructorTabAllCourses',
      requiresAuth: true,
      icon: 'mdi-home',
      isInstructor: true,
    },
  },
  baseCourseLayoutInstructor: {
    path: '/instructor/courses/:courseId',
    name: 'Base Course Layout Instructor',
    meta: {
      title: 'instructor.courses',
      requiresAuth: true,
      isInstructor: true,
    },
  },

  InstructorCourseOverview: {
    path: '/instructor/courses/:courseId/overview',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/overview`,
    name: 'Instructor Course Overview',
    meta: {
      title: 'sidebar.courseOverview',
      icon: 'mdi-information-outline',
    },
  },
  InstructorSyllabus: {
    path: '/instructor/courses/:courseId/syllabus',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/syllabus`,
    name: 'Instructor Syllabus',
    meta: {
      title: 'sidebar.syllabus',
      icon: 'mdi-text-box-edit-outline',
    },
  },
  InstructorCourseContent: {
    path: '/instructor/courses/:courseId/content',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/content`,
    name: 'Instructor Course Content',
    meta: {
      title: 'sidebar.courseContent',
      icon: 'mdi-book-open-blank-variant',
    },
  },
  InstructorOnlineLectures: {
    path: '/instructor/courses/:courseId/online-lectures',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/online-lectures`,
    name: 'Instructor Online Lectures',
    meta: {
      title: 'sidebar.onlineLectures',
      icon: 'mdi-video-outline',
    },
  },
  InstructorOnCampusLectures: {
    path: '/instructor/courses/:courseId/on-campus-lectures',
    pathFn: ({ courseId }) =>
      `/instructor/courses/${courseId}/on-campus-lectures`,
    name: 'Instructor On Campus Lectures',
    meta: {
      title: 'sidebar.onCampusLectures',
      icon: 'mdi-human-male-board',
    },
  },
  InstructorPolls: {
    path: '/instructor/courses/:courseId/polls',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/polls`,
    name: 'Instructor Polls',
    meta: {
      title: 'sidebar.polls',
      icon: 'mdi-poll',
    },
  },

  InstructorSurveys: {
    path: '/instructor/courses/:courseId/surveys',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/surveys`,
    name: 'Instructor Surveys',
    meta: {
      title: 'sidebar.surveys',
      icon: 'mdi-comment-question',
    },
  },

  InstructorQuizzes: {
    path: '/instructor/courses/:courseId/quizzes',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/quizzes`,

    name: 'Instructor Quizzes',
    meta: {
      title: 'sidebar.quizzes',
      icon: 'mdi-help-box-multiple-outline',
    },
  },
  InstructorAssignments: {
    path: '/instructor/courses/:courseId/assignments',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/assignments`,
    name: 'Instructor Assignments',
    meta: {
      title: 'sidebar.assignments',
      icon: 'mdi-clipboard-check',
    },
  },

  InstructorDiscussionsBoards: {
    path: '/instructor/courses/:courseId/discussions-boards',
    pathFn: ({ courseId }) =>
      `/instructor/courses/${courseId}/discussions-boards`,
    name: 'Instructor Discussions Board',
    meta: {
      title: 'sidebar.discussionsBoard',
      icon: 'mdi-forum-outline',
    },
  },
  InstructorGrades: {
    path: '/instructor/courses/:courseId/grades',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/grades`,
    name: 'Instructor Grade Book',
    meta: {
      title: 'sidebar.grade',
      icon: 'mdi-book-open-page-variant-outline',
    },
  },

  InstructorAttendance: {
    path: '/instructor/courses/:courseId/attendance',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/attendance`,
    name: 'Instructor Attendance',
    meta: {
      title: 'sidebar.attendance',
      icon: 'mdi-account-check-outline',
    },
  },
  InstructorAnnouncements: {
    path: '/instructor/courses/:courseId/announcements',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/announcements`,
    name: 'Instructor Announcements',
    meta: {
      title: 'sidebar.announcements',
      icon: 'mdi-bullhorn-outline',
    },
  },
  InstructorRubrics: {
    path: '/instructor/courses/:courseId/rubrics',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/rubrics`,
    name: 'Instructor Rubrics',
    meta: {
      title: 'sidebar.rubrics',
      icon: 'mdi-format-float-left',
    },
  },
  InstructorReports: {
    path: '/instructor/courses/:courseId/reports',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/reports`,
    name: 'Instructor Reports',
    meta: {
      title: 'sidebar.reports',
      icon: 'mdi-chart-bar',
    },
  },
  InstructorGroups: {
    path: '/instructor/courses/:courseId/groups',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/groups`,
    name: 'Instructor Groups',
    meta: {
      title: 'sidebar.groups',
      icon: 'mdi-account-group-outline',
    },
  },
  InstructorStudents: {
    path: '/instructor/courses/:courseId/students',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/students`,
    name: 'Instructor Students',
    meta: {
      title: 'sidebar.students',
      icon: 'mdi-account-multiple',
    },
  },
  InstructorDropBox: {
    path: '/instructor/courses/:courseId/dropbox',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/dropbox`,
    name: 'Instructor Storage',
    meta: {
      title: 'sidebar.dropbox',
      icon: 'mdi-inbox',
    },
  },

  InstructorStudentView: {
    path: '/instructor/courses/:courseId/student-view',
    pathFn: ({ courseId }) => `/instructor/courses/${courseId}/student-view`,
    name: 'Instructor Student View',
    meta: {
      title: 'sidebar.studentView',
      icon: 'mdi-glasses',
      requiresAuth: true,
    },
  },

  ...InstructorEnumOnlineLectureRoutes,
  ...InstructorEnumOnCampusLectureRoutes,
  ...InstructorEnumPollsRoutes,
  ...InstructorEnumSurveysRoutes,
  ...InstructorEnumQuizzesRoutes,
  ...InstructorEnumAssignmentRoutes,
  ...InstructorEnumDiscussionBoardsRoutes,
  ...InstructorEnumGradesRoutes,
  ...InstructorEnumAttendanceRoutes,
  ...InstructorEnumAnnouncementsRoutes,
  ...InstructorEnumRubricsRoutes,
  ...InstructorEnumReportsRoutes,
  ...InstructorEnumCreateSubsectionRoutes,
};
