import type { ThemeDefinition } from 'vuetify';

export const mainTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#006a68',
    secondary: '#4a6362',
    tertiary: '#4a607b',
    error: '#ba1a1a',
    danger: '#ba1a1a',
    gray: '#7d7d7d',
    light: '#dfdfdf',
    hint: '#ececec',
    main: '#1F2F49',
    white: '#ffffff',
    black: '#000000',
    background: '#fafdfc',
    outline: '#6f7978',
    filedBorder: '#3F72AF',
    'dark-gray': '#323639',
    'light-purple': '#9747FF',
    'light-pink': '#F5EDFF',
    'gray-border': '#7d7d7d',
  },
};
