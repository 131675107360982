<script lang="ts" setup>
  import BasicChangePassword from '@/components/base/change-password/BasicChangePassword.vue';
  import { useAuthStore } from '@/stores/auth/auth.pinia';
  import { useLocaleStore } from '@/stores/shared/locale.pinia.ts';
  import { storeToRefs } from 'pinia';
  import { defineAsyncComponent, watch } from 'vue';

  const { locale } = storeToRefs(useLocaleStore());
  const BaseToaster = defineAsyncComponent(
    () => import('@/components/base/BaseToaster.vue')
  );

  watch(
    locale,
    (newLocale) => {
      document.documentElement.lang = newLocale;
      const body = document.querySelector('body');
      if (body) {
        body.dir = newLocale === 'ar' ? 'rtl' : 'ltr';
      }
      // add class ar to body if locale is ar or en if locale is en
      body?.classList.add(newLocale);
      body?.classList.remove(newLocale === 'ar' ? 'en' : 'ar');
    },
    { immediate: true }
  );
  const authStore = useAuthStore();
  const { forceResetPassword } = storeToRefs(authStore);
</script>

<template>
  <v-app>
    <div
      v-if="forceResetPassword"
      class="pa-4 my-auto"
    >
      <BasicChangePassword></BasicChangePassword>
    </div>
    <router-view v-else />
    <BaseToaster />
  </v-app>
</template>
