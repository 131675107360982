export const creditUnitsApiHandler = {
  getAllCreditUnits: {
    method: "GET",
    path: () => `org-credit-units-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number}
  },
  createCreditUnit: {
    method: "POST",
    path: () => `org-credit-units-management`
  },
  updateCreditUnit: {
    method: "PATCH",
    path: (payload: { creditUnitId: string | number }) => `org-credit-units-management/${payload.creditUnitId}`
  },
  deleteCreditUnit: {
    method: "DELETE",
    path: (payload: { creditUnitId: string | number }) => `org-credit-units-management/${payload.creditUnitId}`
  }
};