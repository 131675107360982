export const organizationsApiHandler = {
  createOrganization: {
    method: "POST",
    path: () => `organizations`
  },
  getOrganizationCertificatePreviews: {
    method: "GET",
    path: (payload: { id: string | number }) => `organizations/${payload.id}/certificates-preview`
  },
  generateOrganizationCertificatePreviews: {
    method: "POST",
    path: (payload: { id: string | number }) => `organizations/${payload.id}/certificates-preview`
  },
  getAllOrganizations: {
    method: "GET",
    path: () => `organizations`,
    params: {} as {'skip'?: string | number, 'take'?: string | number}
  }
};