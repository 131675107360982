import {formFieldsApiHandler} from './formFields';
import {organizationsApiHandler} from './organizations';
import {configurationsApiHandler} from './configurations';
import {connectivityHubApiHandler} from './connectivityHub';

export const ApiHandler = {
    formFields: formFieldsApiHandler,
    organizations: organizationsApiHandler,
    configurations: configurationsApiHandler,
    connectivityHub: connectivityHubApiHandler,
}