// import type { AxiosError } from 'axios';
import { AuthErrorsEnum } from '@/helpers/enums/auth-errors.enum.ts';
import { authEnumRoutes } from '@/routes/auth/auth.enum.routes.ts';
import { useAuthStore } from '@/stores/auth/auth.pinia.ts';
import { useToasterStore } from '@/stores/shared/toaster.pinia.ts';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';

const TIMEOUT = 4000;

export const handleAxiosError = async (
  error: AxiosError<Record<string, unknown>>
) => {
  const errorCode = error?.response?.data?.errorCode as AuthErrorsEnum;
  if (errorCode) {
    await handleAuthError(errorCode);
  }
  return Promise.reject<AxiosError>(error);
};

export const handleAuthError = async (errorCode: AuthErrorsEnum) => {
  const authStore = useAuthStore();
  const router = useRouter();

  if (errorCode) {
    switch (errorCode) {
      case AuthErrorsEnum.USER_IS_DISABLED:
        /*
         * User is disabled
         * Clear the user data from the local storage
         * Show the error message
         * Redirect to the login page
         */
        if (authStore.user) {
          await authStore.clearDataFromLocalStorage();
          useToasterStore().addNotificationWithKey({
            text: 'error.disabledUser',
            type: 'error',
            color: 'danger',
            timeout: TIMEOUT,
            show: true,
          });
          router.push({
            name: authEnumRoutes.login.name,
          });
        }
        break;
      case AuthErrorsEnum.USER_SHOULD_RESET_PASSWORD:
        /*
         * User should reset password to continue
         */
        await authStore.setUser();
        useToasterStore().addNotificationWithKey({
          text: 'error.userShouldResetPassword',
          timeout: TIMEOUT,
          type: 'error',
          color: 'danger',
          show: true,
        });
        break;
      case AuthErrorsEnum.INVALID_ACCESS_TOKEN:
      case AuthErrorsEnum.INVALID_REFRESH_TOKEN:
        /*
         *  Invalid Refresh Token
         *  Invalid Access Token
         *  clear the user data from the local storage
         *  redirect to the login page
         */
        await authStore.removeAuthentication();
        break;
    }
  }
};
