import { createApp, type App } from 'vue';
import { i18nInstance, pinia, vuetify, hotjarOptions } from '@/plugins';
import router from '@/routes/index.route.ts';
import I18NextVue from 'i18next-vue';
import '@/styles/all.scss';
import VueHotjar from 'vue-hotjar-next';
import MainApp from './App.vue';
import { useEnvComposable } from '@/composables';
import * as Sentry from '@sentry/vue';

const app: App = createApp(MainApp);

i18nInstance().then((i18n) => {
  Sentry.init({
    app,
    dsn: 'https://76a1c44b32696050349d57ede3f65c1e@o4507191879860224.ingest.de.sentry.io/4508157377249360',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/.*\.himam\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: import.meta.env.PROD, // only enable in production
  });

  app.use(vuetify);
  app.use(router);
  app.use(pinia);
  app.use(I18NextVue, {
    i18next: i18n, // By default, interpolation is done via {{...}} but this conflicts with Vue.js
    // so we use [[...]] instead
    rerenderOn: ['languageChanged', 'loaded', 'added', 'removed'],
  });
  if (hotjarOptions.isProduction) app.use(VueHotjar, hotjarOptions); // Hotjar used for live environments.
  app.mount('#app');
});

const { env } = useEnvComposable();
/*
 * Global error handler
 * We don't want global error handler to run in development and staging environments because it will
 * prevent Vue from showing the error in the console and will show the error in the browser instead.
 * This is because we are using the global error handler to log the error to the server.
 * local storage to enable logging errors to the server on live environments only for developer purposes only
 */
if (env !== 'development' && env !== 'staging') {
  // Global error handler
  app.config.errorHandler = async (err, vm, info) => {
    // local storage to enable logging errors to the server
    if (localStorage.getItem('enableErrorLogging') === 'true') {
      console.error('Error:', err);
      console.error('Info:', info);
      console.error('VM:', vm);
    }
  };
}
