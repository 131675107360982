import { UserRoleEnum } from './../../helpers/enums/portals.enum';
import { useEnvComposable, useJWTTokenComposable } from '@/composables';
import { axiosInstance } from '@/plugins';
import { authEnumRoutes } from '@/routes/auth/auth.enum.routes';
import { ApiHandler } from '@/submodules/instructorApi/apiHandler';

import type {
  AuthSecrets,
  UserWithExtraFields,
} from '@/helpers/interfaces/auth/user.interface.ts';
import { adminEnumRoutes } from '@/routes/admin/admin.enum.routes';
import { coachEnumRoutes } from '@/routes/coach/coach.enum.routes';
import router from '@/routes/index.route';
import { instructorEnumRoutes } from '@/routes/instructor/instructor.enum.routes';
import { profileEnumRoutes } from '@/routes/profile/profile.enum.routes';
import { usePermissionStore } from '@/stores/shared/permission.pinia';
import { ApiHandler as adminApi } from '@/submodules/adminApi/apiHandler';
import { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { type ComputedRef, type Ref, computed, ref } from 'vue';
import { useNotificationsStore } from '../shared/notification.pinia';
import { useToasterStore } from '../shared/toaster.pinia';
import { AdminMenuItems } from '@/helpers/enums/admin-menu-items.enum';

type IPortals = {
  lastVisited: string;
  isAdministrator: boolean;
  isInstructor: boolean;
  isCoach: boolean;
  isUser: boolean;
  prefix: string;
};
export const useAuthStore = defineStore('auth', () => {
  const { checkConfigKeys } = usePermissionStore();

  const user: Ref<UserWithExtraFields | null> = ref(null);
  const resetPasswordToken: Ref<string | null> = ref(null);
  const isDisabledUser: Ref<boolean> = ref(false);
  const forceResetPassword: Ref<boolean> = ref(false);
  const isLogin: ComputedRef<boolean> = computed(
    () => !!localStorage.getItem('access_token')
  );
  const eventSource: Ref<EventSource | null> = ref(null);
  // this is temporary until we recieved it in the auth API
  const portals: Ref<IPortals | null> = ref(null);

  const expiredIn: Ref<number | null> = ref(null);
  async function login(form: { password: string; email: string }) {
    try {
      const { path, method } = ApiHandler.auth.login.login;
      const { data } = await axiosInstance(path(), {
        method,
        data: form,
      });
      if (data?.twoFactorToken)
        await router.replace({
          name: authEnumRoutes.twoFactorVerification.name,
          query: { token: data?.twoFactorToken },
        });
      else {
        await storeDataInLocalStorage(data);
        await initializeAuth();
        const name = await redirectAfterLogin();
        router.push(name);
      }
      return data;
    } catch (error) {
      return error;
    }
  }

  async function verificationCode(token: string, code: string) {
    try {
      const { path, method } = ApiHandler.auth.login.twoFactorAuthentication;
      const { data, status } = await axiosInstance(path(), {
        method,
        params: {
          token: token,
          code: code,
        },
      });
      await storeDataInLocalStorage(data);
      await initializeAuth();
      const name = await redirectAfterLogin();
      router.push(name);
      return status;
    } catch (error) {
      return error;
    }
  }

  async function redirectAfterLogin() {
    const { lastVisited, isAdministrator, isInstructor, isCoach } =
      portals.value || {};

    const portalTypes = {
      ADMIN: adminEnumRoutes.root.name,
      INSTRUCTOR: instructorEnumRoutes.root.name,
      COACH: coachEnumRoutes.root.name,
      LMS: profileEnumRoutes.root.name,
    } as { [key: string]: string };

    let portalName = '';

    if (lastVisited && lastVisited != 'LMS') {
      if (lastVisited == 'ADMIN')
        portalName = (await checkAdminPermission()) || '';
      else portalName = portalTypes[lastVisited];
    } else {
      if (isAdministrator) {
        portalName = (await checkAdminPermission()) || '';
      } else
        portalName = isInstructor
          ? (instructorEnumRoutes.root.name as string)
          : isCoach
            ? (coachEnumRoutes.root.name as string)
            : '';
    }

    return { name: portalName };
  }

  async function checkAdminPermission() {
    let name = '';
    AdminMenuItems.forEach(async (item) => {
      if (
        usePermissionStore().checkPermission(item.meta?.memberPermissions || [])
      )
        if (checkConfigKeys(item?.meta))
          if (!name.length) {
            name = item.name as string;
          }
    });

    return name;
  }

  async function resendCode(token: string) {
    try {
      const { path, method } = ApiHandler.auth.login.resendCode;
      const { status } = await axiosInstance(path(), {
        method,
        params: {
          token: token,
        },
      });
      if (status == 200 || status == 201) {
        useToasterStore().addNotificationWithKey({
          text: 'auth.successResendCode',
          type: 'success',
        });
      }
    } catch (e) {
      useToasterStore().error();
      return e;
    }
  }
  let timeoutHandle: ReturnType<typeof setTimeout>;
  /* 
    this function will check if the token is expired or not 
    if it is expired it will refresh it 
    if it is not expired it will check if the token is about to expire in 20 seconds or less
    if it is about to expire it will refresh it
    if it is not about to expire it will do nothing
    if the user loads the page and the token is expired it logs him out
    if the user loads the page and the token is not expired it will refresh it
   */
  async function handleExpiredAccessToken(isInitializeAuth = false) {
    const token = useJWTTokenComposable();
    timeoutHandle = setTimeout(handleExpiredAccessToken, 10000);
    if (!token) return;
    expiredIn.value = token.exp;
    if (useEnvComposable().env !== 'production')
      console.log(expiredIn.value - Date.now() / 1000);
    if (expiredIn.value - Date.now() / 1000 > 20) {
      // not expired
      if (isInitializeAuth) {
        refreshAccessToken();
      }
    } else {
      // expired
      if (isInitializeAuth) removeAuthentication();
      else await refreshAccessToken();
    }
  }
  async function initializeAuth(isInitializeAuth = false) {
    if (localStorage.getItem('portals'))
      portals.value = JSON.parse(localStorage.getItem('portals') || '{}');
    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('refresh_token')
    ) {
      await handleExpiredAccessToken(isInitializeAuth);
      if (expiredIn.value && expiredIn.value - Date.now() / 1000 > 20)
        await Promise.all([
          setUser(),
          useNotificationsStore().getUnreadCount(),
          usePermissionStore().initializePermissions(),
        ]);
    }
  }

  async function setUser() {
    try {
      const { path, method } = ApiHandler.auth.verifyToken;

      const { data } = await axiosInstance(path(), { method });
      if (data?.id) {
        user.value = data;
        if (data.forceResetPassword) forceResetPassword.value = true;
        else forceResetPassword.value = false;
      }
      portals.value = data.portals;
      localStorage.setItem('portals', JSON.stringify(data.portals ?? {}));
      return user;
    } catch (e) {
      return e;
    }
  }

  async function storeDataInLocalStorage(data: {
    access: string;
    refresh: string;
    portals: IPortals;
  }) {
    if (data.refresh) {
      localStorage.setItem('refresh_token', data.refresh);
    }
    if (data.access) {
      localStorage.setItem('access_token', data.access);
      closeSse();
      subscribeToSSE();
    }
    if (data.portals) {
      portals.value = data.portals;
      localStorage.setItem('portals', JSON.stringify(data.portals));
    }
    return;
  }

  async function logout() {
    try {
      const { path, method } = ApiHandler.auth.logout;
      await axiosInstance(path(), { method });
      await removeAuthentication();
    } catch (e) {
      return e;
    }
  }

  async function removeAuthentication() {
    await clearDataFromLocalStorage();
    closeSse();
    location.reload();
  }

  async function clearDataFromLocalStorage() {
    clearTimeout(timeoutHandle);
    await Promise.all([
      localStorage.removeItem('access_token'),
      localStorage.removeItem('refresh_token'),
      localStorage.removeItem('expires_at'),
      localStorage.removeItem('portals'),
      localStorage.removeItem('guide-line'),
    ]);
    user.value = null;
    resetPasswordToken.value = null;
  }

  async function forgetPassword(email: string) {
    try {
      const { path, method } =
        ApiHandler.auth.resetPassword.requestResetPassword;
      const prefix =
        window.location.origin.split('://')?.[1].split('.himam.com')?.[0] || '';
      await axiosInstance(path({ prefix }), {
        method,
        data: { email },
      });
    } catch (error) {
      return error;
    }
  }

  async function resetPassword(body: { password: string; token: string }) {
    const { path, method } = ApiHandler.auth.resetPassword.resetPassword;
    try {
      const { status } = await axiosInstance(path(), {
        method,
        data: body,
      });
      useToasterStore().addNotificationWithKey({
        text: 'auth.successResetPassword',
        type: 'success',
      });
      router.push({ name: authEnumRoutes.login.name });
      return status;
    } catch (error) {
      return error;
    }
  }

  async function requestSSO(
    params?: {
      courseId?: number;
      portal?: UserRoleEnum;
    },
    resetUser: boolean = false
  ) {
    try {
      const { path, method } = ApiHandler.auth.sSO.generateSSOToken;
      const { data } = await axiosInstance(path(), {
        method,
        params,
      });
      if (resetUser) await setUser();
      return data;
    } catch (error) {
      return error;
    }
  }

  async function loginSSO(superToken: string) {
    const { path, method } = ApiHandler.auth.sSO.getTokensUsingSSO;
    try {
      const { data, status } = await axiosInstance<AuthSecrets>(path(), {
        method,
        params: {
          token: superToken,
        },
        headers: {
          Authorization: `Bearer ${superToken}`,
        },
      });
      if (data?.access && data?.refresh) {
        await storeDataInLocalStorage({
          access: data.access,
          refresh: data.refresh,
          portals: portals.value || ({} as IPortals),
        });
      }
      return { data, status };
    } catch (error) {
      return error;
    }
  }

  async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    const { path, method } = ApiHandler.auth.refresh_New;
    try {
      const { data, status } = await axiosInstance<AuthSecrets>(path(), {
        method,
        data: {
          token: refreshToken,
        },
      });
      if (data?.access && data?.refresh) {
        await storeDataInLocalStorage({
          access: data.access,
          refresh: data.refresh,
          portals: portals.value || ({} as IPortals),
        });
      }
      return { data, status };
    } catch (error) {
      return error;
    }
  }

  async function changePassword(body: {
    oldPassword: string;
    newPassword: string;
  }) {
    const { path, method } = ApiHandler.social.users.changePassword;
    try {
      const data = await axiosInstance(path(), {
        method,
        data: body,
      });
      if (forceResetPassword.value) {
        await Promise.all([
          usePermissionStore().initializePermissions(),
          setUser().catch(async (error) => {
            if (error.response.data.errorCode == 401) {
              await refreshAccessToken().catch(() => {
                clearDataFromLocalStorage();
              });
            }
          }),
        ]);
      }
      return { data };
    } catch (error) {
      return {
        error: error as AxiosError<{
          time: Date;
          statusCode: number;
          message: string;
          error: string;
        }>,
      };
    }
  }
  const closeSse = () => {
    if (eventSource.value) {
      eventSource.value.close();
      eventSource.value = null;
    }
  };
  async function subscribeToSSE() {
    try {
      const baseURL: string = import.meta.env.VITE_BASE_URL ?? '';

      const { path, params } = adminApi.subscribeToSSE;
      const paramsType = {
        token: params.token ?? localStorage.getItem('access_token'),
      };
      const url = baseURL + path() + `?token=${paramsType.token}`;
      eventSource.value = new EventSource(url);

      eventSource.value.onmessage = () => {
        useNotificationsStore().getNotifications(1);
      };
    } catch (error) {
      return {
        error: error as AxiosError<{
          time: Date;
          statusCode: number;
          message: string;
          error: string;
        }>,
      };
    }
  }

  return {
    user,
    logout,
    forgetPassword,
    login,
    verificationCode,
    resendCode,
    initializeAuth,
    setUser,
    redirectAfterLogin,
    removeAuthentication,
    clearDataFromLocalStorage,
    resetPassword,
    resetPasswordToken,
    isDisabledUser,
    forceResetPassword,
    isLogin,
    portals,
    requestSSO,
    loginSSO,
    changePassword,
    refreshAccessToken,
  };
});
