export const articlesApiHandler = {
  addArticle: {
    method: "POST",
    path: () => `org-articles-management`
  },
  getArticles: {
    method: "GET",
    path: () => `org-articles-management`
  },
  getSingleArticle: {
    method: "GET",
    path: (payload: { articleId: string | number }) => `org-articles-management/${payload.articleId}`
  },
  deleteArticle: {
    method: "DELETE",
    path: (payload: { articleId: string | number }) => `org-articles-management/${payload.articleId}`
  },
  editArticle: {
    method: "PATCH",
    path: (payload: { articleId: string | number }) => `org-articles-management/${payload.articleId}`
  }
};