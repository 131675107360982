export const passwordValidator = (password: string) => {
  const errorMessage: string[] = [];

  if (!/\d/.test(password)) {
    errorMessage.push('number');
  }

  if (!/[a-z]/.test(password)) {
    errorMessage.push('lowercase');
  }

  if (!/[A-Z]/.test(password)) {
    errorMessage.push('uppercase');
  }

  if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)) {
    errorMessage.push('special');
  }
  return errorMessage;
};
