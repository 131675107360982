import { EnumOrganizationMemberPermissions } from '@/helpers/enums/permission.enum.ts';

const organization = {
  organizations: {
    name: 'organizations list',
    path: '/control-base/organizations',
    meta: {
      title: 'superAdmin.organizationsTabTitle',
      icon: 'mdi-account-group',
      requiresAuth: true,
    },
  },
  createOrganization: {
    name: 'organizations create',
    path: '/control-base/organizations/create',
    meta: {
      title: 'superAdmin.organizationsCreateTabTitle',
      icon: 'mdi-home',
      requiresAuth: true,
    },
  },
  home: {
    name: 'organizations list',
    path: '/control-base/organizations',
    meta: {
      title: 'superAdmin.backToOrganizations',
      icon: 'mdi-home',
      requiresAuth: true,
    },
  },
  organization: {
    name: 'organization',
    path: '/control-base/organizations/:id',
  },
  organizationOverview: {
    name: 'organization overview',
    path: '/control-base/organizations/:id/overview',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/overview`,
    meta: {
      title: 'superAdmin.organizationOverviewTabTitle',
      icon: 'mdi-card-account-details-outline',
      requiresAuth: true,
    },
  },
  organizationConfiguration: {
    name: 'organization configuration',
    path: '/control-base/organizations/:id/configuration',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/configuration`,
    meta: {
      title: 'superAdmin.configurationTabTitle',
      icon: 'mdi-cog',
      requiresAuth: true,
    },
  },
  organizationCertificate: {
    name: 'organization certificate',
    path: '/control-base/organizations/:id/certificate',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/certificate`,
    meta: {
      title: 'superAdmin.certificateTabTitle',
      icon: 'mdi-certificate-outline',
      requiresAuth: true,
    },
  },
  organizationColors: {
    name: 'organization colors',
    path: '/control-base/organizations/:id/colors',
    pathFn: (id: number | string) => `/control-base/organizations/${id}/colors`,
    meta: {
      title: 'superAdmin.organizationColorsTabTitle',
      icon: 'mdi-palette',
      requiresAuth: true,
    },
  },
  organizationRegisterFromList: {
    name: 'organization register from list',
    path: '/control-base/organizations/:id/register',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/register`,
    meta: {
      title: 'superAdmin.registerListTabTitle',
      icon: 'mdi-account-plus',
      requiresAuth: true,
    },
  },
  organizationRegisterFromCreate: {
    name: 'organization register from create',
    path: '/control-base/organizations/:id/register/create',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/register/create`,
    meta: {
      title: 'superAdmin.registerCreateTabTitle',
      icon: 'mdi-account-plus',
      requiresAuth: true,
    },
  },
  organizationRegisterFromEdit: {
    name: 'organization register from edit',
    path: '/control-base/organizations/:id/register/:formFieldId/edit',
    pathFn: (id: number | string, formFieldId: number | string) =>
      `/control-base/organizations/${id}/register/${formFieldId}/edit`,
    meta: {
      title: 'superAdmin.registerEditTabTitle',
      icon: 'mdi-account-plus',
      requiresAuth: true,
    },
  },
  organizationFonts: {
    name: 'organization fonts',
    path: '/control-base/organizations/:id/fonts',
    pathFn: (id: number | string) => `/control-base/organizations/${id}/fonts`,
    meta: {
      title: 'superAdmin.organizationFontsTabTitle',
      icon: 'mdi-format-font',
      requiresAuth: true,
    },
  },
  organizationCourses: {
    name: 'organization courses',
    path: '/control-base/organizations/:id/courses',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/courses`,
    meta: {
      title: 'superAdmin.organizationCoursesTabTitle',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },
  organizationCourse: {
    name: 'organization course',
    path: '/control-base/organizations/:id/courses/:courseId',
    pathFn: (id: number | string, courseId: number | string) =>
      `/control-base/organizations/${id}/courses/${courseId}`,
    meta: {
      title: 'superAdmin.organizationCourseTabTitle',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },
  organizationCourseDetails: {
    name: 'organization course Details',
    path: '/control-base/organizations/:id/courses/:courseId/details',
    pathFn: (id: number | string, courseId: number | string) =>
      `/control-base/organizations/${id}/courses/${courseId}/details`,
    meta: {
      title: 'superAdmin.organizationCourseDetails',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },
  organizationCourseOrganizationList: {
    name: 'organization course Organization',
    path: '/control-base/organizations/:id/courses/:courseId/organization',
    pathFn: (id: number | string, courseId: number | string) =>
      `/control-base/organizations/${id}/courses/${courseId}/organization`,
    meta: {
      title: 'superAdmin.organizationCoursesTabTitle',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },
  organizationCourseCertificate: {
    name: 'organization course Certificate',
    path: '/control-base/organizations/:id/courses/:courseId/Certificate',
    pathFn: (id: number | string, courseId: number | string) =>
      `/control-base/organizations/${id}/courses/${courseId}/Certificate`,
    meta: {
      title: 'superAdmin.organizationCourseCertificate',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },

  organizationExternalCourses: {
    name: 'organization external courses',
    path: '/control-base/organizations/:id/external-courses',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/external-courses`,
    meta: {
      title: 'superAdmin.organizationExternalCoursesTabTitle',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },
  organizationExternalCourse: {
    name: 'organization external course',
    path: '/control-base/organizations/:id/external-courses/:externalCourseId',
    pathFn: (id: number | string, externalCourseId: number | string) =>
      `/control-base/organizations/${id}/external-courses/${externalCourseId}`,
    meta: {
      title: 'superAdmin.organizationExternalCourseTabTitle',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },

  organizationTermsSuperAdmin: {
    name: 'terms super admin',
    path: '/control-base/organizations/:id/terms',
    pathFn: (id: number | string) => `/control-base/organizations/${id}/terms`,
    meta: {
      title: 'superAdmin.termsTabTitle',
      icon: 'mdi-book',
      requiresAuth: true,
    },
  },
  organizationMediaSuperAdmin: {
    name: 'media super admin',
    path: '/control-base/organizations/:id/media',
    pathFn: (id: number | string) => `/control-base/organizations/${id}/media`,
    meta: {
      title: 'superAdmin.mediaTabTitle',
      icon: 'mdi-image',
      requiresAuth: true,
    },
  },
  organizationDynamicPagesSuperAdmin: {
    name: 'dynamic pages super admin',
    path: '/control-base/organizations/:id/dynamic-pages',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/dynamic-pages`,
    meta: {
      title: 'superAdmin.dynamicPages',
      icon: 'mdi-notebook-edit-outline',
      requiresAuth: true,
    },
  },
  organizationDynamicPagesSuperAdminLayout: {
    name: 'dynamic pages super admin layout',
    path: '/control-base/organizations/:id/dynamic-pages',
    pathFn: (id: number | string, keyId: number | string) =>
      `/control-base/organizations/${id}/dynamic-pages/${keyId}`,
    meta: {
      title: 'superAdmin.dynamicPages',
      icon: 'mdi-notebook-edit-outline',
      requiresAuth: true,
    },
  },
  viewOrganizationDynamicPagesSuperAdmin: {
    name: 'view custom config super admin',
    path: '/control-base/organizations/:id/dynamic-pages/:key',
    pathFn: (id: number | string, key: string) =>
      `/control-base/organizations/${id}/dynamic-pages/${key}`,
    meta: {
      title: 'superAdmin.createDynamicPages',
      icon: 'mdi-notebook-edit-outline',
      requiresAuth: true,
    },
  },
  createOrganizationDynamicPagesSuperAdmin: {
    name: 'create custom config super admin',
    path: '/control-base/organizations/:id/dynamic-pages/create',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/dynamic-pages/create`,
    meta: {
      title: 'superAdmin.createDynamicPages',
      icon: 'mdi-notebook-edit-outline',
      requiresAuth: true,
    },
  },
  organizationPassingCriteria: {
    name: 'passing criteria',
    path: '/control-base/organizations/:id/passing-criteria',
    pathFn: (id: number | string) =>
      `/control-base/organizations/${id}/passing-criteria`,
    meta: {
      title: 'superAdmin.passingCriteriaTabTitle',
      icon: 'mdi-check-circle',
      requiresAuth: true,
    },
  },
};

const fontFamilies = {
  fontFamilies: {
    name: 'font families',
    path: '/control-base/font-families',
    meta: {
      title: 'superAdmin.fontFamiliesTabTitle',
      icon: 'mdi-format-font',
      requiresAuth: true,
    },
  },
  fontFamily: {
    name: 'font family',
    path: '/control-base/font-families/:id',
    meta: {
      title: 'superAdmin.fontFamilyTabTitle',
      icon: 'mdi-format-font',
      requiresAuth: true,
    },
    pathFn: (id: number | string) => `/control-base/font-families/${id}`,
  },
  createFontFamily: {
    name: 'create font family',
    path: '/control-base/font-families/create',
    meta: {
      title: 'superAdmin.createFontFamilyTabTitle',
      icon: 'mdi-format-font',
      requiresAuth: true,
    },
  },
  editFontFamily: {
    name: 'edit font family',
    path: '/control-base/font-families/:id/edit',
    meta: {
      title: 'superAdmin.editFontFamilyTabTitle',
      icon: 'mdi-format-font',
      requiresAuth: true,
    },
    pathFn: (id: number | string) => `/control-base/font-families/${id}/edit`,
  },
};

const terminologyEnumRoutes = {
  terminology: {
    path: '/control-base/terminology',
    name: 'Terminology',
    meta: {
      title: 'superAdmin.terminologyTabTitle',
      icon: 'mdi-book-multiple',
      requiresAuth: true,
    },
  },
  terms: {
    path: '/control-base/terminology/terms',
    name: 'Terms',
    meta: {
      title: 'superAdmin.termsTabTitle',
      icon: 'mdi-book',
      requiresAuth: true,
    },
  },
  singleTerm: {
    path: '/control-base/terminology/terms',
    name: 'Single Term',
    meta: {
      title: 'superAdmin.singleTermTabTitle',
      requiresAuth: true,
    },
  },
  createTerm: {
    path: '/control-base/terminology/terms/create',
    name: 'Create Term',
    meta: {
      title: 'superAdmin.createTermTabTitle',
      requiresAuth: true,
    },
  },
  termDetail: {
    path: '/control-base/terminology/terms/:terminologyId/detail',
    name: 'Term Detail',
    meta: {
      title: 'superAdmin.termDetailTabTitle',
      requiresAuth: true,
    },
  },
};
const externalCourses = {
  externalCourseLayout: {
    name: 'external courses layout',
    path: '/control-base/external-courses',
    meta: {
      title: 'superAdmin.externalCoursesTabTitle',
      icon: 'mdi-book-plus-multiple',
      requiresAuth: true,
    },
  },
  allExternalCourses: {
    name: 'all external courses',
    meta: {
      title: 'superAdmin.externalCoursesTabTitle',
      requiresAuth: true,
    },
  },
  pendingEnrollmentExternalCourses: {
    name: 'pending enrollment external courses',
    path: '/control-base/external-courses/pending-enrollment',
    meta: {
      title: 'superAdmin.pendingEnrollmentExternalCoursesTabTitle',
      requiresAuth: true,
    },
  },
  singleExternalCourseLayout: {
    name: 'single external course layout',
    path: '/control-base/external-courses/:externalCourseId',
    meta: {
      title: 'superAdmin.singleExternalCourseTabTitle',
      requiresAuth: true,
    },
  },
  singleExternalCourseOverview: {
    name: 'single external course overview',
    path: '/control-base/external-courses/:externalCourseId',
    meta: {
      title: 'superAdmin.singleExternalCourseTabTitle',
      requiresAuth: true,
    },
  },
  singleExternalCourseDetails: {
    name: 'single external course details',
    meta: {
      title: 'superAdmin.singleExternalCourseDetailsTabTitle',
      requiresAuth: true,
    },
  },
  singleExternalCourseOrganizations: {
    name: 'single external course organizations',
    path: '/control-base/external-courses/:externalCourseId/organizations',
    pathFn: (externalCourseId: number | string) =>
      `/control-base/external-courses/${externalCourseId}/organizations`,

    meta: {
      title: 'superAdmin.singleExternalCourseOrganizationsTabTitle',
      requiresAuth: true,
    },
  },
  singleExternalCourseEnrollee: {
    name: 'single external course enrollee',
    path: '/control-base/external-courses/:externalCourseId/enrollee',
    pathFn: (externalCourseId: number | string) =>
      `/control-base/external-courses/${externalCourseId}/enrollee`,

    meta: {
      title: 'superAdmin.externalCourseEnrollee',
      requiresAuth: true,
    },
  },
  createOrganizationInExternalCourse: {
    name: 'create organization in external course',
    path: '/control-base/external-courses/:externalCourseId/organizations/create',
    meta: {
      title: 'superAdmin.createOrganizationInExternalCourseTabTitle',
      requiresAuth: true,
    },
  },
  editOrganizationInExternalCourse: {
    name: 'update organization in external course',
    path: '/control-base/external-courses/:externalCourseId/organizations/:organizationId',
    meta: {
      title: 'superAdmin.editOrganizationInExternalCourseTabTitle',
      requiresAuth: true,
    },
  },
};

export const superAdminEnumRoutes = {
  root: {
    name: 'control-base',
    path: '/control-base',
    meta: {
      memberPermissions: [
        EnumOrganizationMemberPermissions.UserAccessControlBase,
      ],
      requiresAuth: true,
      title: 'superAdmin.controlBaseTabTitle',
    },
  },

  formFields: {
    name: 'form fields',
    path: '/control-base/form-fields',
    meta: {
      title: 'superAdmin.formFieldsTabTitle',
      icon: 'mdi-file-document-edit-outline',
      requiresAuth: true,
    },
  },
  formField: {
    name: 'form field',
    path: '/control-base/form-fields/:id',
  },
  createFormField: {
    name: 'create form field',
    path: '/control-base/form-fields/create',
  } /*
   * Courses and Connectivity Hub
   */,
  courses: {
    name: 'courses page',
    path: '/control-base/courses',
    meta: {
      title: 'superAdmin.coursesTabTitle',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },
  course: {
    name: 'course',
    path: '/control-base/courses/:courseId',
    pathFn: (id: number | string) => `/control-base/courses/${id}`,
    meta: {
      title: 'superAdmin.courseTabTitle',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
      allowHeader: true,
    },
  },
  addCourseToOrganization: {
    name: 'add course to organization',
    path: '/control-base/courses/:id/add-course-to-organization',
    meta: {
      title: 'courseSuperAdmin.titlePageForAddCourseToOrganization',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },
  editCourseOrganization: {
    name: 'edit course organization',
    path: '/control-base/courses/:id/edit/:organizationId',
    meta: {
      title: 'superAdmin.editCourseOrganizationTabTitle',
      icon: 'mdi-book-open-page-variant',
      requiresAuth: true,
    },
  },
  ...organization,
  ...fontFamilies,
  ...terminologyEnumRoutes,
  ...externalCourses,
};
