import { mainTheme } from '@/plugins/vuetify/themes.vuetify.ts';

export const ThemeConfigVuetify = {
  defaultTheme: 'mainTheme',
  variations: {
    colors: ['primary', 'secondary'],
    lighten: 6,
    darken: 2,
  },
  themes: {
    mainTheme,
  },
};
