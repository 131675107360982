import type { i18n } from 'i18next';
import { useLocaleStore } from '@/stores/shared/locale.pinia.ts';
import { pinia } from '@/plugins';
import { useOrganizationStore } from '@/stores/admin/organzation.pinia.ts';
import { useVuetifyStore } from '@/stores/shared/vuetify.pinia.ts';
import { storeToRefs } from 'pinia';
import { getInitialLanguage } from '@/plugins/i18n/init.i18n.ts';

export const configureStores = async (i18nextInstance: i18n) => {
  const localeStore = useLocaleStore(pinia);
  const organizationsStore = useOrganizationStore(pinia);
  const vuetifyStore = useVuetifyStore(pinia);

  // get url
  const url = window.location.href;
  //split after dashboard.[].himam.com
  const organizationPrefix =
    url?.split('dashboard.')[1]?.split('.himam.com')[0] ?? 'default';
  await organizationsStore.getOrganizationUsingPrefix(organizationPrefix);
  await Promise.all([
    localeStore.changeLocale(getInitialLanguage(), i18nextInstance, false),
    organizationsStore.callOrganization(),
    organizationsStore.getOrganizationTerminology(),
  ]);

  const { organization } = storeToRefs(organizationsStore);
  if (organization?.value) {
    const { colors, fontFamilies, icon } = organization.value;
    vuetifyStore.changeThemeMainColors(colors?.[0]?.color, colors?.[1]?.color);
    vuetifyStore.changeFontFamily(fontFamilies);
    vuetifyStore.changeFavoriteIcon(icon?.url || '');
  }
};
