import { pinia, vuetify } from '@/plugins';
import type { i18n } from 'i18next';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocaleEnum } from '@/helpers/enums/locale.enum.ts';
import i18next from 'i18next';
import axios from 'axios';
import { useConfigurationStore } from '../admin/configuration.pinia';

export const useLocaleStore = defineStore('localeStore', () => {
  /*
   * -----------------------------------------------------------------------
   * State
   */

  const locale = ref<string>(LocaleEnum.ENGLISH);
  const isArabic = ref<boolean>(false);

  /*
   * -----------------------------------------------------------------------
   * Actions
   */

  const changeLocale = async (
    language: string,
    i18next: i18n,
    setTerms: boolean = true
  ) => {
    localStorage.setItem(LocaleEnum.LOCALE_STORAGE_KEY, language);
    locale.value = language;
    isArabic.value = language === LocaleEnum.ARABIC;
    try {
      await i18next.changeLanguage(language);
      await getMessagesFromCMS();
      vuetify.locale.current.value = language;
      if (setTerms) useConfigurationStore(pinia).setOrganizationTerms();
      return i18next;
    } catch (e) {
      console.log(e);
    }
  };

  const initLocale = async (i18next: i18n) => {
    const language = localStorage.getItem(LocaleEnum.LOCALE_STORAGE_KEY);
    if (language) {
      await changeLocale(language, i18next);
    } else {
      await changeLocale(LocaleEnum.ENGLISH, i18next);
    }
  };

  const getMessagesFromCMS = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${import.meta.env.VITE_I18N_TOKEN}`,
      };
      const messages = await axios.get(
        `${import.meta.env.VITE_APP_CMS_BASE_URL}-${locale.value}`,
        {
          headers,
        }
      );
      i18next.addResourceBundle(
        locale.value,
        'translation',
        messages.data.data.attributes.json,
        true,
        true
      );
    } catch (e) {
      console.log(e);
    }
  };

  return {
    changeLocale,
    locale,
    isArabic,
    initLocale,
  };
});
