interface useEnvComposable {
  env: string;
  projectName: string;
  prefix: string | number;
  accessOptional: string;
}

/*
 * -----------------------------------------------------------------------
 * Composable
 * -----------------------------------------------------------------------
 * This composable is used to get the environment of the application
 * -----------------------------------------------------------------------
 * @returns {useEnvComposable}
 * -----------------------------------------------------------------------
 * @example
 * import { useEnvComposable } from '@/composables/useEnv.composable';
 * const { env } = useEnvComposable();
 * -----------------------------------------------------------------------
 */
const useEnvComposable = (): useEnvComposable => {
  // don't use nullable type because it will be used in template and will return undefined
  const env: string = import.meta.env.MODE;
  const projectName: string = import.meta.env.VITE_PROJECT_NAME;
  const prefix: string | number = import.meta.env.VITE_PREFIX;
  const accessOptional = import.meta.env.VITE_ACCESS_OPTIONAL;
  return {
    env,
    prefix,
    projectName,
    accessOptional,
  };
};

export default useEnvComposable;
