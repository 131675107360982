import { NotificationPortalEnum } from '@/helpers/enums/notifications.enum';
import type {
  INotification,
  NotificationControlGroup,
  UserNotificationSettings,
} from '@/helpers/interfaces/shared/notification.interface';
import { axiosInstance } from '@/plugins';
import { miscApiHandler } from '@/submodules/instructorApi/apiHandler/misc';
import { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { computed, ref, type Ref } from 'vue';
import { useAuthStore } from '../auth/auth.pinia';
import { useLocaleStore } from './locale.pinia';
import { type Paginated } from '@/components/base/paginated/paginated-type.intefcaces';
interface INotificationsStore {
  unreadCount: Ref<number>;
  notifications: Ref<INotification[]>;
  userNotificationSettings: Ref<UserNotificationSettings>;
  notificationControlGroup: Ref<NotificationControlGroup>;
  getUnreadCount: () => Promise<number | AxiosError>;
  getNotifications: (page: number) => Promise<void>;
  markAllAsRead: () => Promise<unknown>;
  markNotificationAsRead: (
    notificationId: number | string,
    page: number,
    refetch?: boolean
  ) => Promise<unknown>;
  deleteNotification: (
    notificationId: number | string,
    page: number,
    refetch?: boolean
  ) => Promise<unknown>;
  getNotificationControlGroup: () => Promise<
    NotificationControlGroup | unknown
  >;
  getUserNotificationSettings: () => Promise<
    UserNotificationSettings | unknown
  >;
  getAllNotifications: (
    page: number
  ) => Promise<Paginated<INotification> | AxiosError>;
  updateNotificationSettings: (data: {
    notificationControlGroupId: number;
    isDisabled: boolean;
  }) => Promise<unknown>;
}

export const useNotificationsStore = defineStore(
  'NotificationsStore',
  (): INotificationsStore => {
    //======================================================================
    //>>>>>>>>>>>>>>> State Variables <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    //======================================================================
    const authStore = useAuthStore();
    const portals = computed(() => {
      const { isAdministrator, isCoach, isInstructor } =
        authStore.portals || {};
      const portals = [];
      isInstructor
        ? portals.push(NotificationPortalEnum.Instructor)
        : undefined;
      isAdministrator
        ? portals.push(NotificationPortalEnum.Organization)
        : undefined;
      isCoach ? portals.push(NotificationPortalEnum.Coach) : undefined;
      return portals;
    });
    const unreadCount = ref(0);
    const take = 15;
    const notifications = ref<INotification[]>([]);
    const userNotificationSettings = ref<UserNotificationSettings>({
      count: 0,
      data: [],
    });
    const notificationControlGroup = ref<NotificationControlGroup>({
      count: 0,
      data: [],
    });

    //======================================================================
    //>>>>>>>>>>>>>>> Functions <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    //======================================================================
    //________________ Get notifications ________________
    async function getNotifications(page: number) {
      await Promise.all([getUnreadCount(), getFirstNotifications(page)]);
    }

    async function getUnreadCount() {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD.getNotificationCount;
      try {
        const { data } = await axiosInstance<{ count: number }>(path(), {
          method,
          params: { portals: portals.value },
        });
        unreadCount.value = data.count;
        return data.count;
      } catch (e) {
        return e as AxiosError;
      }
    }

    async function getFirstNotifications(page: number) {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD.getNotificationsv2;
      try {
        const data = (
          await axiosInstance<Paginated<INotification>>(path(), {
            method,
            params: {
              skip: (page - 1) * take,
              take,
              portals: portals.value,
              language: useLocaleStore().locale,
            },
          })
        ).data;

        notifications.value = data.data;
      } catch (e) {
        return e;
      }
    }
    async function getAllNotifications(page: number) {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD.getNotificationsv2;
      try {
        const { data } = await axiosInstance<Paginated<INotification>>(path(), {
          method,
          params: {
            skip: (page - 1) * take,
            take,
            portals: portals.value,
            language: useLocaleStore().locale,
          },
        });
        console.log(data);

        return data;
      } catch (e) {
        return e as AxiosError;
      }
    }
    //________________  Mark all as read ________________
    async function markAllAsRead() {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD
          .markAllNotificationsAsRead;
      try {
        await axiosInstance<{ count: number }>(path(), { method });
        unreadCount.value = 0;
        getFirstNotifications(1);
      } catch (e) {
        return e;
      }
    }

    //________________  Mark notification as read ________________
    async function markNotificationAsRead(
      notificationId: number | string,
      page: number,
      refetch: boolean = false
    ) {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD
          .markNotificationAsRead;
      try {
        await axiosInstance<{ count: number }>(
          path({
            notificationId: notificationId,
          }),
          { method }
        );
        if (unreadCount.value > 0) unreadCount.value = unreadCount.value - 1;
        if (refetch) getFirstNotifications(page);
      } catch (e) {
        return e;
      }
    }

    //________________  Delete notification  ________________
    async function deleteNotification(
      notificationId: number | string,
      page: number,
      refetch: boolean = false
    ) {
      const { path, method } =
        miscApiHandler.notifications.himamNotificationCRUD[
          'delete/HideNotification'
        ];
      try {
        await axiosInstance<{}>(
          path({
            notificationId: notificationId,
          }),
          { method }
        );
        if (refetch) getFirstNotifications(page);
      } catch (e) {
        return e;
      }
    }

    //________________  Get User Notification settings  ________________
    async function getUserNotificationSettings() {
      const { path, method } =
        miscApiHandler.notificationsSystem.notificationControlGroups
          .notificationSettings.getUserNotificationSettings;

      try {
        const response = await axiosInstance.get<UserNotificationSettings>(
          path(),
          {
            method,
          }
        );
        userNotificationSettings.value = response.data;
        return response.data;
      } catch (e) {
        return e;
      }
    }
    //________________  Get Notification Control Group  ________________
    async function getNotificationControlGroup() {
      const { path, method } =
        miscApiHandler.notificationsSystem.notificationControlGroups
          .getNotificationControlGroups;

      getUserNotificationSettings();
      try {
        const response = await axiosInstance.get<NotificationControlGroup>(
          path(),
          {
            method,
            params: {
              skip: 0,
              take: 15,
              language: useLocaleStore().locale,
              portals: portals.value,
            },
          }
        );

        notificationControlGroup.value = response.data;

        return response.data;
      } catch (e) {
        return e;
      }
    }

    //________________  update notification settings  ________________

    async function updateNotificationSettings(data: {
      notificationControlGroupId: number;
      isDisabled: boolean;
    }) {
      const patchData = [
        {
          notificationControlGroupId: data.notificationControlGroupId,
          isDisabled: data.isDisabled,
        },
      ];
      const { path, method } =
        miscApiHandler.notificationsSystem.notificationControlGroups
          .notificationSettings.updateUserNotificationSettings;

      try {
        await axiosInstance.patch(path(), {
          method,
          data: patchData,
        });
      } catch (e) {
        return e;
      }
    }

    return {
      unreadCount,
      notifications,
      userNotificationSettings,
      notificationControlGroup,
      getNotifications,
      markAllAsRead,
      markNotificationAsRead,
      deleteNotification,
      getNotificationControlGroup,
      getUnreadCount,
      getUserNotificationSettings,
      updateNotificationSettings,
      getAllNotifications,
    };
  }
);
