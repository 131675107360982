import type { RouteRecordRaw } from 'vue-router';
import { adminEnumRoutes } from './admin.enum.routes';

const articlesRoute: Array<RouteRecordRaw> = [
  {
    path: adminEnumRoutes.articles.path,
    children: [
      {
        path: '',
        name: adminEnumRoutes.articles.name,
        meta: adminEnumRoutes.articles.meta,
        component: () =>
          import('@/modules/admin/pages/articles/ArticlesPage.vue'),
      },

      {
        path: adminEnumRoutes.articleDetails.path,
        name: adminEnumRoutes.articleDetails.name,
        meta: adminEnumRoutes.articleDetails.meta,
        component: () =>
          import('@/modules/admin/pages/articles/ArticlesDetails.vue'),
      },
      {
        path: adminEnumRoutes.createArticle.path,
        name: adminEnumRoutes.createArticle.name,
        meta: adminEnumRoutes.createArticle.meta,
        component: () =>
          import('@/modules/admin/pages/articles/CreateArticles.vue'),
      },
    ],
  },
];
export default articlesRoute;
