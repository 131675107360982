import type { RouteRecordRaw } from 'vue-router';
import { sharedEnumRoutes } from './shared.routes.enum';

const sharedRoutes: Array<RouteRecordRaw> = [
  {
    path: sharedEnumRoutes.root.path,
    name: sharedEnumRoutes.root.name,
    meta: sharedEnumRoutes.root.meta,
    component: () => import('@/App.vue'),
  },
  {
    path: sharedEnumRoutes.notAuth.path,
    name: sharedEnumRoutes.notAuth.name,
    meta: sharedEnumRoutes.notAuth.meta,
    component: () => import('@/modules/shared/pages/NotAuthorized.vue'),
  },
  {
    path: sharedEnumRoutes.notFound.path,
    name: sharedEnumRoutes.notFound.name,
    meta: sharedEnumRoutes.notFound.meta,
    component: () => import('@/modules/shared/pages/NotFound.vue'),
  },
];
export default sharedRoutes;
