export const providersApiHandler = {
  getAllProviders: {
    method: "GET",
    path: () => `org-providers-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'search'?: string | number}
  },
  createProvider: {
    method: "POST",
    path: (payload: { providerId: string | number }) => `org-providers-management/${payload.providerId}`
  },
  updateProvider: {
    method: "PATCH",
    path: (payload: { providerId: string | number }) => `org-providers-management/${payload.providerId}`
  },
  deleteProvider: {
    method: "DELETE",
    path: (payload: { providerId: string | number }) => `org-providers-management/${payload.providerId}`
  }
};