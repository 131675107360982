export const galleryApiHandler = {
  getAllGalleryMedia: {
    method: "GET",
    path: () => `gallery`,
    params: {} as {'type'?: string | number, 'skip'?: string | number, 'take'?: string | number}
  },
  getSingleGalleryMedium: {
    method: "GET",
    path: (payload: { id: string | number }) => `gallery/${payload.id}`
  }
};