export enum EnumOrganizationMemberPermissions {
  Everything = 'organization_member_full_access',
  LMS = 'organization_member_access_courses',
  Coaching = 'organization_member_access_coaching',
  SocialPlatform = 'organization_member_access_communities',
  AccessEvents = 'organization_member_access_events',

  LearningPath = 'organization_member_access_skills_development',
  // Members Permissions //
  CRUDMembers = 'organization_crud_members',
  ReadMembers = 'organization_member_view_users',
  ModifyMembers = 'organization_member_modify_users',
  ReadMemberActivity = 'organization_member_view_users_activity',
  ReadMemberFinancials = 'organization_member_view_member_transactions',

  // Member Roles //
  ReadMemberRoles = 'organization_member_view_member_roles',
  ModifyMemberRoles = 'organization_member_modify_member_roles',
  ViewOrganizationNewsletter = 'organization_member_view_organization_newsletter',

  // Courses Permissions //
  CRUDCourses = 'organization_crud_courses',
  ReadCourses = 'organization_member_view_courses',
  ModifyCourses = 'organization_member_modify_courses',
  ModifyCoursesPrice = 'organization_member_modify_courses_price',
  ModifyCoursesDates = 'organization_member_modify_courses_dates',
  ModifyCoursesInstructors = 'organization_member_modify_courses_instructors',
  ModifyCoursesStudents = 'organization_member_modify_courses_students',

  // External Courses Permissions //
  ReadExternalCourses = 'organization_member_view_external_courses',
  ModifyExternalCourses = 'organization_member_modify_external_courses',

  ReadImportedCourses = 'organization_member_view_imported_courses',
  ModifyImportedCourses = 'organization_member_modify_imported_courses',
  // Specializations Permissions //
  ReadSpecializations = 'organization_member_view_specializations',
  ModifySpecializations = 'organization_member_modify_specializations',
  ModifySpecializationCourses = 'organization_member_modify_specialization_courses',
  // Financials Permissions //
  ReadSales = 'organization_member_view_sales',
  ViewRefunds = 'organization_member_view_refunds',
  IssueRefunds = 'organization_member_issue_refunds',
  // Social Events Permissions //
  ReadSocialEvents = 'organization_member_view_events',
  ModifySocialEvents = 'organization_member_modify_events',
  ModifySocialEventsAttendance = 'organization_member_modify_events_attendance',
  TakeSocialEventsAttendance = 'organization_member_take_social_event_attendance',
  // Social Groups Permissions //
  ReadSocialGroups = 'organization_member_view_communities',
  ModifySocialGroups = 'organization_member_modify_communities',
  ModifySocialGroupsMembers = 'organization_member_modify_community_members',

  // Learning Path Permissions //
  OrganizationLearningPath = 'organization_crud_learning_paths',
  ReadLearningPaths = 'organization_member_read_skills_development',
  ModifyLearningPaths = 'organization_member_modify_skills_development',
  ModifyLearningPathsMembers = 'organization_member_modify_skills_development_members',
  // Support Team Permissions //
  ReadSupportTeams = 'organization_member_view_support_teams',
  ModifySupportTeams = 'organization_member_modify_support_teams',
  // Organization Configuration Permissions //
  ReadOrganizationConfiguration = 'organization_member_view_organization_configuration',
  ModifyOrganizationConfiguration = 'organization_member_modify_organization_configuration',
  // Providers Permissions //
  ReadProviders = 'organization_member_view_coaches',
  ModifyProviders = 'organization_member_modify_coaches',
  // Coaching Permissions //
  CoachingAbility = 'organization_member_access_coaching_ability',
  CoachPricingUpdateAuthorization = 'organization_member_coach_pricing_update_authorization',

  CreateCourses = 'organization_member_create_courses',
  ImportCourses = 'organization_member_import_courses',

  // Control Base Permissions //
  UserAccessControlBase = 'user_access_control_base',
}
export enum EnumOrgPermission {
  Default = 'organization_member_default_11',
  Admin = 'organization_member_admin_11',
  Coach = 'organization_member_coach_11',
  Instructor = 'organization_member_instructor_11',
}

export enum EnumOrganizationPermissions {
  Everything = 'organization_everything',
  Enabled = 'organization_enabled',
  RegistrationEnabled = 'organization_registration_enabled',
  LMS = 'organization_lms',
  Coaching = 'organization_coaching',
  SocialPlatform = 'organization_social_platform',
  SocialEvents = 'organization_social_events',
  LearningPath = 'organization_learning_path',
  CRUDCourses = 'organization_crud_courses',
  CRUDMembers = 'organization_crud_members',
  CRUDSpecializations = 'organization_crud_specializations',
  CRUDSocialGroups = 'organization_crud_social_groups',
  CRUDSocialEvents = 'organization_crud_social_events',
  CRUDLearningPaths = 'organization_crud_learning_paths',
  CRUDChecklists = 'organization_crud_checklists',
  CRUDSupportTeams = 'organization_crud_support_teams',
}

export enum EnumPermissionContextIds {
  Global = 1,
  User = 2,
  Organization = 3,
  OrganizationMember = 4,
  CourseInstructor = 5,
}
export enum EnumPermissionContexts {
  Global = 'global',
  User = 'user',
  Organization = 'organization',
  OrganizationMember = 'organization_member',
  CourseInstructor = 'course_instructor',
}
