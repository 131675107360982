export const galleryApiHandler = {
  addGalleryMedia: {
    method: "POST",
    path: () => `org-gallery-management`
  },
  getGalleryMedia: {
    method: "GET",
    path: () => `org-gallery-management`,
    params: {} as {'type'?: string | number, 'skip'?: string | number, 'take'?: string | number}
  },
  getSingleGalleryMedium: {
    method: "GET",
    path: (payload: { id: string | number }) => `org-gallery-management/${payload.id}`
  },
  editGalleryMedium: {
    method: "PATCH",
    path: (payload: { id: string | number }) => `org-gallery-management/${payload.id}`
  },
  deleteGalleryMedium: {
    method: "DELETE",
    path: (payload: { id: string | number }) => `org-gallery-management/${payload.id}`
  }
};