export const announcementsApiHandler = {
  getAnnouncements: {
    method: "GET",
    path: () => `org-announcements-management`,
    params: {} as {'filterDismissed'?: string | number, 'filterExpired'?: string | number, 'skip'?: string | number, 'take'?: string | number}
  },
  getAnnouncement: {
    method: "GET",
    path: (payload: { announcementId: string | number }) => `org-announcements-management/${payload.announcementId}`
  },
  createAnnouncement: {
    method: "POST",
    path: () => `org-announcements-management`
  },
  updateAnnouncement: {
    method: "PATCH",
    path: (payload: { announcementId: string | number }) => `org-announcements-management/${payload.announcementId}`
  },
  deleteAnnouncement: {
    method: "DELETE",
    path: (payload: { announcementId: string | number }) => `org-announcements-management/${payload.announcementId}`
  },
  dismissAnnouncement: {
    method: "POST",
    path: (payload: { announcementId: string | number }) => `org-announcements-management/${payload.announcementId}/dismiss`
  }
};