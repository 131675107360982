export const gradingSchemasApiHandler = {
  gradeMappings: {
    addGradeMapping: {
      method: "POST",
      path: (payload: { gradingSchemaId: string | number }) => `grading-schema/${payload.gradingSchemaId}/grade`
    },
    getGradeMapping: {
      method: "GET",
      path: (payload: { gradingSchemaId: string | number }) => `grading-schema/${payload.gradingSchemaId}/grade`
    },
    updateGradeMapping: {
      method: "PATCH",
      path: (payload: { gradingSchemaId: string | number , gradeMappingId: string | number }) => `grading-schema/${payload.gradingSchemaId}/grade/${payload.gradeMappingId}`
    },
    deleteGradeMapping: {
      method: "DELETE",
      path: (payload: { gradingSchemaId: string | number , gradeMappingId: string | number }) => `grading-schema/${payload.gradingSchemaId}/grade/${payload.gradeMappingId}`
    }
  },
  createGradingSchema: {
    method: "POST",
    path: () => `org-grading-schema-management`
  },
  updateGradingSchema: {
    method: "PUT",
    path: (payload: { gradingSchemaId: string | number }) => `org-grading-schema-management/${payload.gradingSchemaId}`
  },
  getSpGradingSchemas: {
    method: "GET",
    path: () => `org-grading-schema-management`,
    params: {} as {'skip'?: string | number, 'take'?: string | number}
  },
  deleteGradingSchema: {
    method: "DELETE",
    path: (payload: { gradingSchemaId: string | number }) => `org-grading-schema-management/${payload.gradingSchemaId}`
  },
  getSingleSchema: {
    method: "GET",
    path: (payload: { gradingSchemaId: string | number }) => `org-grading-schema-management/${payload.gradingSchemaId}`
  },
  getSchemaCourses: {
    method: "GET",
    path: (payload: { gradingSchemaId: string | number }) => `org-grading-schema-management/${payload.gradingSchemaId}/courses`,
    params: {} as {'search'?: string | number, 'skip'?: string | number, 'take'?: string | number}
  }
};