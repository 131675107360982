// store.ts
import { pinia } from '@/plugins';
import { useConfigurationStore } from '@/stores/admin/configuration.pinia';
import i18next from 'i18next';
import { defineStore, storeToRefs } from 'pinia';
import { ref, type Ref } from 'vue';

declare const block: readonly ['top', 'bottom'];
declare const inline: readonly ['start', 'end', 'left', 'right'];
type Tblock = (typeof block)[number];
type Tinline = (typeof inline)[number];

/*
 * @param {string} text - The text to display in the notification
 * @param {string} color - The color of the notification
 * @param {string} type - The type of the notification
 * @param {number} id - The id of the notification
 * @param {number} timeout - The timeout of the notification
 * @param {boolean} show - The show of the notification
 * @param {string} location - The location of the notification
 * @returns {Notification} - The notification object
 */
interface Notification {
  text: string;
  color?: string;
  type?: string;
  id?: number;
  timeout?: number;
  show?: boolean;
  location?:
    | Tblock
    | Tinline
    | 'center'
    | 'center center'
    | `${Tblock} ${Tinline | 'center'}`
    | `${Tinline} ${Tblock | 'center'}`;
}

/*
 * @param {array} notifications - The array of notifications
 * @param {function} addNotification - The function to add a notification
 * @param {function} removeNotification - The function to remove a notification
 * @param {function} success - The function to add a success notification
 * @param {function} error - The function to add an error notification
 * @param {function} updated - The function to add an updated notification
 * @param {function} deleted - The function to add a deleted notification
 * @returns {object} - The toaster store object
 * @returns {array} - The array of notifications
 * @returns {function} - The function to add a notification
 * @returns {function} - The function to remove a notification
 * @returns {function} - The function to add a success notification
 * @returns {function} - The function to add an error notification
 * @returns {function} - The function to add an updated notification
 * @returns {function} - The function to add a deleted notification
 */

export const useToasterStore = defineStore('toaster', () => {
  const notifications: Ref<Notification[]> = ref([]);

  const { t } = i18next;
  const addNotification = (notification: Notification) => {
    notifications.value.push({
      ...notification,
      show: true,
    });
  };

  const success = () => {
    addNotification({ text: t('messages.savedSuccessfully'), type: 'success' });
  };

  const error = (text?: string) => {
    addNotification({
      text: text ? t(`messages.${text}`) : t('messages.errorOccurred'),
      type: 'error',
      color: 'danger',
    });
  };

  const updated = () => {
    addNotification({
      text: t('messages.updatedSuccessfully'),
      type: 'success',
    });
  };
  const created = () => {
    addNotification({
      text: t('messages.createdSuccessfully'),
      type: 'success',
    });
  };

  const deleted = () => {
    addNotification({
      text: t('messages.deletedSuccessfully'),
      type: 'success',
    });
  };
  const addNotificationWithKey = (notification: Notification) => {
    const { studentText, instructorText, dashboardText, courseText } =
      storeToRefs(useConfigurationStore(pinia));

    addNotification({
      ...notification,
      text: t(notification.text, {
        studentText: studentText.value,
        instructorText: instructorText.value,
        dashboardText: dashboardText.value,
        courseText: courseText.value,
      }),
    });
  };

  const forbidden = () => {
    addNotification({
      text: t('messages.forbidden'),
      type: 'error',
      color: 'danger',
      location: 'bottom center',
    });
  };
  const removeNotification = (index: number) => {
    notifications.value.splice(index, 1);
  };

  return {
    notifications,
    addNotification,
    removeNotification,
    success,
    error,
    updated,
    deleted,
    addNotificationWithKey,
    forbidden,
    created,
  };
});
