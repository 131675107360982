export const integrationsApiHandler = {
  zoom: {
    "oAuth2.0": {
      getAuthorizationURL: {
        method: "GET",
        path: () => `zoom/auth`,
        params: {} as {'prefix'?: string | number, 'suffix'?: string | number}
      },
      sendAuthorizationCode: {
        method: "POST",
        path: () => `zoom/auth`
      },
      isAuthenticated: {
        method: "GET",
        path: () => `zoom/auth/is-authenticated`
      },
      deleteZoomIntegration: {
        method: "DELETE",
        path: () => `zoom/auth`
      }
    }
  },
  webhooks: {
    checkHealthExternalServices: {
      method: "GET",
      path: () => `health/external-services`
    }
  },
  copyLeaks: {
    dontUse: {
      pgsqlHealth: {
        method: "GET",
        path: () => `copyleaks/pgsql`
      },
      changeSandboxSettings: {
        method: "DELETE",
        path: () => `copyleaks/sandbox/true`
      },
      testText: {
        method: "POST",
        path: () => `copyleaks/test`
      },
      testFilesystem: {
        method: "POST",
        path: () => `copyleaks/test`
      },
      requestPdf: {
        method: "GET",
        path: (payload: { scanId: string | number }) => `copyleaks/pdf/${payload.scanId}`
      },
      allCopyleaksWebhooks: {
        method: "GET",
        path: () => `copyleaks/webhooks/all`
      },
      webhookByScanId: {
        method: "GET",
        path: (payload: { scanId: string | number }) => `copyleaks/webhooks/${payload.scanId}`
      }
    },
    checkAssignment: {
      method: "POST",
      path: () => `copyleaks/check/assignment`
    },
    checkEssay: {
      method: "POST",
      path: () => `copyleaks/check/essay`
    },
    checkEssayCopy: {
      method: "POST",
      path: () => `copyleaks/check/essay`
    },
    getReportById_1: {
      method: "GET",
      path: (payload: { plagiarismReportId: string | number }) => `copyleaks/report/${payload.plagiarismReportId}`
    }
  }
};