import type { RouteRecordRaw } from 'vue-router';
import adminCoursesRoutes from './admin-courses.route';
import { adminEnumRoutes } from './admin.enum.routes';
import eventsRoutes from './events.route';
import socialPlatformRoute from './soical-platform.route';
import userAdminRoute from './users.route';
import announcementsRoute from './announcements.route';
import articlesRoute from './articles.route';
import journeysRoutes from './admin.journey.routes';
const adminRoutes: Array<RouteRecordRaw> = [
  {
    name: adminEnumRoutes.AdminStudentView.name,
    path: adminEnumRoutes.AdminStudentView.path,
    meta: adminEnumRoutes.AdminStudentView.meta,
    component: () =>
      import('@/modules/instructor/pages/course/StudentViewPage.vue'),
  },

  {
    path: adminEnumRoutes.root.path,
    name: adminEnumRoutes.root.name,
    meta: adminEnumRoutes.root.meta,
    component: () => import('@/layouts/admin/BaseAdminLayout.vue'),
    children: [
      {
        path: adminEnumRoutes.dashboard.path,
        name: adminEnumRoutes.dashboard.name,
        meta: adminEnumRoutes.dashboard.meta,
        component: () =>
          import('@/modules/admin/pages/dashboard/DashboardPage.vue'),
      },
      {
        path: adminEnumRoutes.teams.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.teams.name,
            meta: adminEnumRoutes.teams.meta,
            component: () =>
              import('@/modules/admin/pages/teams/TeamsPage.vue'),
          },
          {
            path: adminEnumRoutes.teamsOverview.path,
            children: [
              {
                path: adminEnumRoutes.teamsOverview.path,
                name: adminEnumRoutes.teamsOverview.name,
                meta: adminEnumRoutes.teamsOverview.meta,
                redirect: adminEnumRoutes.teamMembers.path,

                component: () =>
                  import('@/modules/admin/components/teams/TeamLayout.vue'),
                children: [
                  {
                    path: adminEnumRoutes.teamMembers.path,
                    name: adminEnumRoutes.teamMembers.name,
                    meta: adminEnumRoutes.teamMembers.meta,
                    component: () =>
                      import(
                        '@/modules/admin/components/teams/TeamMembers.vue'
                      ),
                  },
                  {
                    path: adminEnumRoutes.teamCourses.path,
                    name: adminEnumRoutes.teamCourses.name,
                    meta: adminEnumRoutes.teamCourses.meta,
                    component: () =>
                      import(
                        '@/modules/admin/components/teams/TeamCourses.vue'
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      ...journeysRoutes,
      ...adminCoursesRoutes,
      {
        path: adminEnumRoutes.approvals.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.approvals.name,
            meta: adminEnumRoutes.approvals.meta,
            component: () =>
              import('@/modules/admin/pages/approvals/ApprovalsPage.vue'),
          },
        ],
      },
      {
        path: adminEnumRoutes.grades.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.grades.name,
            meta: adminEnumRoutes.grades.meta,
            component: () =>
              import('@/modules/admin/pages/grades/GradesSchemaPage.vue'),
          },
          {
            path: adminEnumRoutes.gradesOverview.path,
            children: [
              {
                path: adminEnumRoutes.gradesOverview.path,
                name: adminEnumRoutes.gradesOverview.name,
                meta: adminEnumRoutes.gradesOverview.meta,
                redirect: adminEnumRoutes.gradesDetails.path,
                component: () =>
                  import(
                    '@/modules/admin/components/garde-schema/gradeSchemaLayout.vue'
                  ),
                children: [
                  {
                    path: adminEnumRoutes.gradesDetails.path,
                    name: adminEnumRoutes.gradesDetails.name,
                    meta: adminEnumRoutes.gradesDetails.meta,
                    component: () =>
                      import(
                        '@/modules/admin/components/garde-schema/gradeSchemaDetails.vue'
                      ),
                  },
                  {
                    path: adminEnumRoutes.gradesLetters.path,
                    name: adminEnumRoutes.gradesLetters.name,
                    meta: adminEnumRoutes.gradesLetters.meta,
                    component: () =>
                      import(
                        '@/modules/admin/components/garde-schema/gradeSchemaLetter.vue'
                      ),
                  },
                  {
                    path: adminEnumRoutes.gradesCourses.path,
                    name: adminEnumRoutes.gradesCourses.name,
                    meta: adminEnumRoutes.gradesCourses.meta,
                    component: () =>
                      import(
                        '@/modules/admin/components/garde-schema/gardeSchemaCourses.vue'
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: adminEnumRoutes.createGradeSchema.path,
            name: adminEnumRoutes.createGradeSchema.name,
            meta: adminEnumRoutes.createGradeSchema.meta,
            component: () =>
              import(
                '@/modules/admin/components/garde-schema/gradeSchemaDetails.vue'
              ),
          },
        ],
      },
      {
        path: adminEnumRoutes.socialPlatform.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.socialPlatform.name,
            meta: adminEnumRoutes.socialPlatform.meta,
            component: () =>
              import(
                '@/modules/admin/pages/social-platform/SocialPlatformPage.vue'
              ),
          },
        ],
      },
      ...eventsRoutes,
      ...userAdminRoute,
      ...socialPlatformRoute,
      ...announcementsRoute,
      ...articlesRoute,
      {
        path: adminEnumRoutes.transactions.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.transactions.name,
            meta: adminEnumRoutes.transactions.meta,
            redirect: adminEnumRoutes.allTransactions.path,
            component: () =>
              import('@/modules/admin/pages/transactions/TransactionsPage.vue'),
            children: [
              {
                path: adminEnumRoutes.allTransactions.path,
                name: adminEnumRoutes.allTransactions.name,
                meta: adminEnumRoutes.transactions.meta,
                component: () =>
                  import(
                    '@/modules/admin/components/transactions/AllTransactions.vue'
                  ),
              },
              {
                path: adminEnumRoutes.canceledTransactions.path,
                name: adminEnumRoutes.canceledTransactions.name,
                meta: adminEnumRoutes.canceledTransactions.meta,
                component: () =>
                  import(
                    '@/modules/admin/components/transactions/CanceledTransactions.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: adminEnumRoutes.refunds.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.refunds.name,
            meta: adminEnumRoutes.refunds.meta,
            component: () =>
              import('@/modules/admin/pages/transactions/RefundsPage.vue'),
          },
        ],
      },
      {
        path: adminEnumRoutes.permissions.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.permissions.name,
            meta: adminEnumRoutes.permissions.meta,
            component: () =>
              import('@/modules/admin/pages/permissions/PermissionsPage.vue'),
          },
        ],
      },
      {
        name: adminEnumRoutes.issuedCertificatesLayout.name,
        path: adminEnumRoutes.issuedCertificatesLayout.path,
        meta: adminEnumRoutes.issuedCertificatesLayout.meta,
        component: () =>
          import(
            '@/modules/admin/pages/issued-certificate/IssuedCertificateLayout.vue'
          ),
        children: [
          {
            path: adminEnumRoutes.issuedCertificates.path,
            name: adminEnumRoutes.issuedCertificates.name,
            meta: adminEnumRoutes.issuedCertificates.meta,
            component: () =>
              import(
                '@/modules/admin/pages/issued-certificate/IssuedCertificatePage.vue'
              ),
          },
          {
            name: adminEnumRoutes.viewIssuedCertificates.name,
            path: adminEnumRoutes.viewIssuedCertificates.path,
            meta: adminEnumRoutes.viewIssuedCertificates.meta,
            component: () =>
              import(
                '@/modules/admin/pages/issued-certificate/ViewCertificate.vue'
              ),
          },
        ],
      },
      {
        path: adminEnumRoutes.organizationOverview.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.organizationOverview.name,
            meta: adminEnumRoutes.organizationOverview.meta,
            component: () =>
              import(
                '@/modules/admin/pages/organization-overview/OrganizationOverviewPage.vue'
              ),
            children: [
              {
                path: adminEnumRoutes.organizationStyle.path,
                name: adminEnumRoutes.organizationStyle.name,
                meta: adminEnumRoutes.organizationStyle.meta,
                component: () =>
                  import(
                    '@/modules/admin/components/organization-overview/StylingConfiguration.vue'
                  ),
              },
              {
                path: adminEnumRoutes.organizationCoursesConfig.path,
                name: adminEnumRoutes.organizationCoursesConfig.name,
                meta: adminEnumRoutes.organizationCoursesConfig.meta,
                component: () =>
                  import(
                    '@/modules/admin/components/organization-overview/CoursesConfiguration.vue'
                  ),
              },
              {
                path: adminEnumRoutes.organizationTerminology.path,
                name: adminEnumRoutes.organizationTerminology.name,
                meta: adminEnumRoutes.organizationTerminology.meta,
                redirect: adminEnumRoutes.organizationShortTerm.path,
                component: () =>
                  import(
                    '@/modules/admin/components/organization-overview/OrganizationTerminology.vue'
                  ),
                children: [
                  {
                    path: adminEnumRoutes.organizationShortTerm.path,
                    name: adminEnumRoutes.organizationShortTerm.name,
                    meta: adminEnumRoutes.organizationShortTerm.meta,
                    component: () =>
                      import(
                        '@/modules/admin/components/organization-overview/organizationTerminologyComponents/ShortTermKeys.vue'
                      ),
                  },
                  {
                    path: adminEnumRoutes.organizationLongTerm.path,
                    name: adminEnumRoutes.organizationLongTerm.name,
                    meta: adminEnumRoutes.organizationLongTerm.meta,
                    component: () =>
                      import(
                        '@/modules/admin/components/organization-overview/organizationTerminologyComponents/LongTermKey.vue'
                      ),
                  },
                  {
                    path: adminEnumRoutes.organizationMediaConfig.path,
                    name: adminEnumRoutes.organizationMediaConfig.name,
                    meta: adminEnumRoutes.organizationMediaConfig.meta,
                    component: () =>
                      import(
                        '@/modules/admin/components/organization-overview/OrganizationMediaConfig.vue'
                      ),
                  },
                ],
              },

              {
                path: adminEnumRoutes.organizationCertificateConfig.path,
                name: adminEnumRoutes.organizationCertificateConfig.name,
                meta: adminEnumRoutes.organizationCertificateConfig.meta,
                component: () =>
                  import(
                    '@/modules/admin/components/organization-overview/OrganizationCertificateConfig.vue'
                  ),
              },
              {
                path: adminEnumRoutes.supportTeam.path,
                name: adminEnumRoutes.supportTeam.name,
                meta: adminEnumRoutes.supportTeam.meta,
                component: () =>
                  import(
                    '@/modules/admin/pages/support-team/SupportTeamPage.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: adminEnumRoutes.newsLetters.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.newsLetters.name,
            meta: adminEnumRoutes.newsLetters.meta,
            component: () =>
              import('@/modules/admin/pages/newsletter/NewsletterPage.vue'),
          },
        ],
      },
      {
        path: adminEnumRoutes.registrationRequest.path,
        children: [
          {
            path: '',
            name: adminEnumRoutes.registrationRequest.name,
            meta: adminEnumRoutes.registrationRequest.meta,
            component: () =>
              import(
                '@/modules/admin/pages/users-registration/UserRegistrationRequestPage.vue'
              ),
          },
        ],
      },
    ],
  },
];
export default adminRoutes;
