export const terminologyApiHandler = {
  getOrganizationsTerminologyDashboard: {
    method: "GET",
    path: () => `organizations-dashboard/terminology/dashboard`,
    params: {} as {'skip'?: string | number, 'take'?: string | number, 'type'?: string | number, 'termKeyCategoryId'?: string | number}
  },
  getOrganizationsTerminology: {
    method: "GET",
    path: () => `organizations-dashboard/terminology`
  },
  getOrganizationsTerm: {
    method: "GET",
    path: (payload: { termId: string | number }) => `organizations-dashboard/terminology/${payload.termId}`
  },
  createOrganizationsTerminology: {
    method: "POST",
    path: () => `organizations-dashboard/terminology`
  },
  updateOrganizationsTerminology: {
    method: "PATCH",
    path: (payload: { terminologyId: string | number }) => `organizations-dashboard/terminology/${payload.terminologyId}`
  },
  deleteOrganizationsTerminology: {
    method: "DELETE",
    path: (payload: { terminologyId: string | number }) => `organizations-dashboard/terminology/${payload.terminologyId}`
  }
};