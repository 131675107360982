import {
  ConfigKeysEnum,
  EnumOrganizationTerminology,
} from '@/helpers/enums/conif-keys.enum';
import type { Terminology } from '@/helpers/interfaces/super-admin/org.terminology.interface';
import { pinia } from '@/plugins';
import type { Media } from '@/submodules/generated_types/types/media';
import { OrganizationConfiguration } from '@/submodules/generated_types/types/organization_configuration';
import { AxiosError } from 'axios';
import { defineStore, storeToRefs } from 'pinia';
import { ref, type Ref } from 'vue';
import { useLocaleStore } from '../shared/locale.pinia';
import { useOrganizationStore } from './organzation.pinia';

export const useConfigurationStore = defineStore('configurationStore', () => {
  const { organization, organizationTerminology } = storeToRefs(
    useOrganizationStore()
  );

  const marketPlaceEnabled: Ref<boolean> = ref(false);
  const registrationRequiresApproval: Ref<{
    domain: string;
    strategy: string;
  } | null> = ref(null);
  const selfEnrollmentEnabled: Ref<boolean> = ref(false);
  const twoFactorEnabled: Ref<boolean> = ref(false);
  const allowAnonymousCourseView: Ref<boolean> = ref(false);
  const allowAnonymousEventView: Ref<boolean> = ref(false);
  const selfRegistrationEnabled: Ref<boolean> = ref(false);
  const certificatesEnabled: Ref<boolean> = ref(false);
  const passingCriteriaConfigurationEnabledForAdmins: Ref<boolean> = ref(false);
  const passingCriteriaConfigurationEnabledForInstructors: Ref<boolean> =
    ref(false);
  const loginPageImage: Ref<string> = ref('');
  const studentText: Ref<string> = ref('');
  const studentsText: Ref<string> = ref('');
  const moduleText: Ref<string> = ref('');
  const modulesText: Ref<string> = ref('');
  const instructorText: Ref<string> = ref('');
  const instructorsText: Ref<string> = ref('');
  const courseText: Ref<string> = ref('');
  const coursesText: Ref<string> = ref('');
  const blendedText: Ref<string> = ref('');
  const dashboardText: Ref<string> = ref('');
  const loginOrganizationLogo: Ref<string> = ref('');
  const setOrganizationConfigKeys = async () => {
    try {
      if (organization.value && organization.value?.config.length > 0) {
        organization.value?.config.forEach(
          (item: OrganizationConfiguration) => {
            switch (item.configurationKey.key) {
              case ConfigKeysEnum.TWO_FACTOR_ENABLED:
                twoFactorEnabled.value = !!item.value;
                break;
              case ConfigKeysEnum.REGISTRATION_REQUIRES_APPROVAL:
                registrationRequiresApproval.value = {
                  domain:
                    (
                      item.value as {
                        domain: string;
                        strategy: string;
                      }
                    )?.domain || '',
                  strategy:
                    (
                      item.value as {
                        domain: string;
                        strategy: string;
                      }
                    )?.strategy || '',
                };
                break;
              case ConfigKeysEnum.MARKETPLACE_ENABLED:
                marketPlaceEnabled.value = !!item.value;
                break;
              case ConfigKeysEnum.SELF_REGISTRATION_ENABLED:
                selfRegistrationEnabled.value = !!item.value;
                break;
              case ConfigKeysEnum.SELF_ENROLLMENT_ENABLED:
                selfEnrollmentEnabled.value = !!item.value;
                break;
              case ConfigKeysEnum.ALLOW_ANONYMOUS_COURSE_VIEW:
                allowAnonymousCourseView.value = !!item.value;
                break;
              case ConfigKeysEnum.ALLOW_ANONYMOUS_EVENT_VIEW:
                allowAnonymousEventView.value = !!item.value;
                break;
              case ConfigKeysEnum.CERTIFICATES_ENABLED:
                certificatesEnabled.value = !!item.value;
                break;
              case ConfigKeysEnum.PASSING_CRITERIA_CONFIGURATION_ENABLED_FOR_ADMINS:
                passingCriteriaConfigurationEnabledForAdmins.value =
                  !!item.value;
                break;
              case ConfigKeysEnum.PASSING_CRITERIA_CONFIGURATION_ENABLED_FOR_INSTRUCTORS:
                passingCriteriaConfigurationEnabledForInstructors.value =
                  !!item.value;
                break;
              default:
            }
          }
        );
      }
    } catch (error) {
      return error as AxiosError;
    }
  };
  const getMediaValue: (
    term: Terminology,
    localeKey?: 'ar' | 'en'
  ) => Media | undefined | null = (
    term: Terminology,
    localeKey?: 'ar' | 'en'
  ) => {
    const locale = localeKey || (useLocaleStore(pinia).locale as 'ar' | 'en');
    if (term?.defaultValue?.isRequired) {
      let value: Media | undefined | null = undefined;
      term.organizationTerms?.forEach((termKey) => {
        if (termKey.language == locale) {
          value = termKey.medium || term.defaultValue.media || undefined;
        }
      });
      return value || undefined;
    } else {
      if (term.organizationTerms?.length) {
        let value: Media | undefined | null = undefined;
        term.organizationTerms?.forEach((termKey) => {
          if (termKey.language == locale) {
            value = termKey.medium || undefined;
          }
        });
        return value || undefined;
      }
      // special case for login organization logo
      else if (
        term.slug === EnumOrganizationTerminology.LOGIN_ORGANIZATION_LOGO
      )
        return organization.value?.logo;
      else return term.defaultValue.media || undefined;
    }
  };
  const getValue: (
    term: Terminology,
    isSingular: boolean,
    localeKey?: 'ar' | 'en'
  ) => string = (
    term: Terminology,
    isSingular: boolean,
    localeKey?: 'ar' | 'en'
  ) => {
    const locale = localeKey || (useLocaleStore(pinia).locale as 'ar' | 'en');
    if (term?.defaultValue?.isRequired) {
      if (!term.organizationTerms?.length) {
        return (
          (isSingular
            ? term.defaultValue[locale].singular
            : term.defaultValue[locale].plural) || ''
        );
      } else {
        let value = '';
        term.organizationTerms?.forEach((termKey) => {
          if (termKey.language == locale) {
            value =
              (isSingular ? termKey.value.singular : termKey.value.plural) ||
              (isSingular
                ? term.defaultValue[locale].singular
                : term.defaultValue[locale].plural) ||
              '';
          }
        });
        return value;
      }
    } else {
      if (term.organizationTerms?.length) {
        let value = '';
        term.organizationTerms?.forEach((termKey) => {
          if (termKey.language == locale)
            value =
              (isSingular ? termKey.value.singular : termKey.value.plural) ||
              '';
        });
        return value;
      } else
        return (
          (isSingular
            ? term.defaultValue[locale].singular
            : term.defaultValue[locale].plural) || ''
        );
    }
  };
  const setOrganizationTerms = () => {
    /** >>>>>>>>>>>>>>> Login Page Image <<<<<<<<<<<<<<< **/
    loginPageImage.value =
      getMediaValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.AUTH_IMAGE
        ]?.[0]
      )?.url || '';

    /** >>>>>>>>>>>>>>> Text Keys <<<<<<<<<<<<<<< **/
    //>>>>>>> Blended
    blendedText.value =
      getValue(
        organizationTerminology.value[EnumOrganizationTerminology.BLENDED]?.[0],
        true
      ) || '';
    //>>>>>>> Course
    courseText.value =
      getValue(
        organizationTerminology.value[EnumOrganizationTerminology.COURSE]?.[0],
        true
      ) || '';
    coursesText.value =
      getValue(
        organizationTerminology.value[EnumOrganizationTerminology.COURSE]?.[0],
        false
      ) || '';
    //>>>>>>> Dashboard
    dashboardText.value =
      getValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.DASHBOARD
        ]?.[0],
        true
      ) || '';
    //>>>>>>> Instructor
    instructorText.value =
      getValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.INSTRUCTOR
        ]?.[0],
        true
      ) || '';
    instructorsText.value =
      getValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.INSTRUCTOR
        ]?.[0],
        false
      ) || '';
    //>>>>>>> Module
    moduleText.value =
      getValue(
        organizationTerminology.value[EnumOrganizationTerminology.MODULE]?.[0],
        true
      ) || '';
    modulesText.value =
      getValue(
        organizationTerminology.value[EnumOrganizationTerminology.MODULE]?.[0],
        false
      ) || '';
    //>>>>>>> Student
    studentText.value =
      getValue(
        organizationTerminology.value[EnumOrganizationTerminology.STUDENT]?.[0],
        true
      ) || '';
    studentsText.value =
      getValue(
        organizationTerminology.value[EnumOrganizationTerminology.STUDENT]?.[0],
        false
      ) || '';
    loginOrganizationLogo.value =
      getMediaValue(
        organizationTerminology.value[
          EnumOrganizationTerminology.LOGIN_ORGANIZATION_LOGO
        ]?.[0]
      )?.url || '';
  };

  return {
    setOrganizationConfigKeys,
    marketPlaceEnabled,
    registrationRequiresApproval,
    selfEnrollmentEnabled,
    twoFactorEnabled,
    allowAnonymousCourseView,
    allowAnonymousEventView,
    certificatesEnabled,
    passingCriteriaConfigurationEnabledForAdmins,
    passingCriteriaConfigurationEnabledForInstructors,
    setOrganizationTerms,
    loginPageImage,
    studentText,
    studentsText,
    moduleText,
    modulesText,
    instructorText,
    instructorsText,
    courseText,
    coursesText,
    blendedText,
    selfRegistrationEnabled,
    dashboardText,
    loginOrganizationLogo,
    getMediaValue,
    getValue,
  };
});
